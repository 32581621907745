<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { type Ownership, ownershipsSchema } from './../../../db/schema';
  import UsersSelector from './../../widgets/users/users-selector.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import { deleteOwnership, saveOwnership } from '$lib/api/mutations';
  import { Trash } from 'lucide-svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  export let assignment;
  export let accountId;
  export let onChanges = () => {};

  const form = superForm<Ownership>(assignment, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(ownershipsSchema),
    onSubmit: async () => {
      console.log('submit', $formData);
      const ownership = await saveOwnership($formData, accountId);
      onChanges();
    },
  });

  const { form: formData, enhance } = form;

  const remove = () => {
    assignment.id && deleteOwnership(assignment.id);
    onChanges();
  };
</script>

<form
  method="POST"
  use:enhance
  class="grid grid-cols-4 gap-6 items-baseline p-4 max-w-4xl rounded-lg shadow-sm transition-colors duration-200 bg-primary-foreground hover:bg-gray-50"
>
  <UsersSelector
    bind:value={$formData.sales}
    label="Assigned to"
    multiple={false}
  />

  <HantaInputDate {form} name="validFrom" label="From" />

  <HantaInputDate {form} name="validTo" label="Till" />
  <Button variant="ghost" class="self-end w-12 h-full" on:click={remove}>
    <Trash class="size-4" />
  </Button>
  <FormActions {form} />
</form>
