<script lang="ts">
  import AccountOwnership from './account-ownership.svelte';
  import { getItems } from '$lib/api/queries';
  import { PlusCircle } from 'lucide-svelte';
  import { Button } from '$lib/components/ui/button';

  export let accountId;
  export let assignments = [];

  let refreshId = 1;
  $: if (refreshId && accountId) {
    getItems({
      collection: 'ownerships',
      from: 0,
      to: 100,
      filters: [
        {
          field: 'accountId',
          operator: 'eq',
          value: accountId,
          active: true,
        },
      ],
      select: '*, sales:users(id, name, photo)',
    }).then(result => {
      assignments = result.data;
    });
  }

  function addAssignment() {
    assignments = [...assignments, {}];
  }
</script>

<div class="p-16 bg-primary-foreground">
  <h2 class="mb-4 text-xl font-bold">Sales ownership</h2>
  <Button variant="default" on:click={addAssignment} class="mt-2 mb-8">
    <PlusCircle class="mr-2" size={20} />
    New Ownership
  </Button>

  {#key refreshId}
    {#each assignments as assignment, index}
      <AccountOwnership
        bind:assignment
        {accountId}
        onChanges={() => {
          setTimeout(() => {
            refreshId++;
          }, 500);
        }}
      />
    {/each}
  {/key}
</div>
