<script lang="ts">
  import Card from './../../../components/ui/card/card.svelte';
  import { createQuery } from '@tanstack/svelte-query';
  import { invoiceStatistics } from '$lib/api/invoice-api';
  import InvoicesChart from './invoices-chart.svelte';
  import InvoicesResults from './invoices-results.svelte';

  const INVOICE_STATUS_COLORS = {
    Draft: 'hsl(270, 60%, 60%)', // Purple
    Open: 'hsl(210, 100%, 50%)', // Blue
    Paid: 'hsl(120, 60%, 50%)', // Green
    Uncollectible: 'hsl(30, 100%, 50%)', // Orange
    Void: 'hsl(0, 100%, 50%)', // Red
    Overdue: 'hsl(0, 100%, 50%)', // Red
    Cancelled: 'hsl(0, 0%, 50%)', // Gray
  };

  const invoiceStatisticsQuery = createQuery({
    queryKey: ['invoices', 'statistics'],
    queryFn: async () => {
      const data = await invoiceStatistics();
      return processInvoiceData(data);
    },
  });

  $: ({ data, isLoading, isError, error } = $invoiceStatisticsQuery);

  function processInvoiceData(data) {
    return Object.keys(INVOICE_STATUS_COLORS).map(status => {
      const found = data?.find(el => el.status === status);
      return {
        status,
        count: found?.count || 0,
        amount: found?.amount || 0,
        color: INVOICE_STATUS_COLORS[status],
      };
    });
  }

  function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  }
</script>

<Card class="mb-4 max-w-lg">
  <div class="flex p-2">
    <div>
      <h2 class="mb-2 text-lg font-semibold">Invoices</h2>
      {#if isLoading}
        <p>Loading invoice data...</p>
      {:else if isError}
        <p>Error loading invoice data: {error.message}</p>
      {:else if data}
        <InvoicesChart {data} statusColors={INVOICE_STATUS_COLORS} />
        <InvoicesResults {data} />
      {/if}
    </div>
  </div>
</Card>
