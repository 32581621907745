import {
  pgTable,
  timestamp,
  varchar,
  numeric,
  jsonb,
  date,
  boolean,
  integer,
  uuid,
  primaryKey,
  text,
  time,
  customType,
  index,
  serial,
  AnyPgColumn,
  Index,
  PgTableWithColumns,
} from 'drizzle-orm/pg-core';
import { SQL, sql } from 'drizzle-orm';
import { z } from 'zod';

import { pgEnum } from 'drizzle-orm/pg-core';

import { createInsertSchema, createSelectSchema } from 'drizzle-zod';

const tsVector = customType<{ data: string }>({
  dataType() {
    return 'tsvector';
  },
});

export const equalityOp = pgEnum('equality_op', <const>[
  'eq',
  'neq',
  'lt',
  'lte',
  'gt',
  'gte',
  'in',
]);
export const action = pgEnum('action', <const>[
  'INSERT',
  'UPDATE',
  'DELETE',
  'TRUNCATE',
  'ERROR',
]);

export const dealPriorityEnum = pgEnum('prioritydeal', <const>['A', 'B', 'C']);
export const dealPhaseEnum = pgEnum('dealPhase', <const>[
  'In Vorbereitung',
  'Kontaktiert',
  'Telefontermin',
  'Im Austausch',
  'Persönlicher Termin',
  'VideoCall',
  'Präsentation in Vorbereitung',
  'Präsentiert',
  'Telefoninterview Kunde',
  'Vorstellungstermin 1 Kunde',
  'Vorstellungstermin 2 Kunde',
  'Vertragsverhandlung',
  'Vertrag',
  'Absage',
]);

export const accountStatusEnum = pgEnum('accountstatus', <const>[
  'Uninteressant',
  'Akquisition',
  'Bestandskunde',
  'Neukunde',
  'Nicht interessiert',
]);

export const moduleNamesEnum = pgEnum('moduleNames', <const>[
  'accounts',
  'deals',
  'activities',
  'files',
  'contacts',
]);
export const filterTypesEnum = pgEnum('filterTypes', <const>[
  'private',
  'public',
  'shared',
  'system',
]);

export const activityType = pgEnum('activityType', <const>[
  'Note',
  'Todo',
  'Email',
  'LinkedIn',
  'Phone',
  'Assignment',
  'Letter',
  'Meeting',
]);

export const contactRolesEnum = pgEnum('contactRoles', <const>[
  'Contact',
  'Candidate',
  'ContactAndCandidate',
  'Employee',
]);

export const priorityEnum = pgEnum('priorityEnums', <const>[
  'Low',
  'Medium',
  'High',
]);

export const paymentCollectionEnum = pgEnum('paymentCollection', <const>[
  'Request payment',
  'Autocharge customer',
]);

export const ContactRoleType = z.enum(contactRolesEnum.enumValues);

const createTimestampColumns = () => ({
  createdAt: timestamp('createdAt', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  modifiedAt: timestamp('modifiedAt', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  refId: text('refId'),
  refIds: varchar('refIds').array(),
  orgId: varchar('orgId').default(sql`requesting_tenant_id()`),
  userId: varchar('userId').default(sql`requesting_user_id()`),
  customFields: jsonb('customFields'),
  tags: varchar('tags').array(),
});

const createTimestampIndexes = (
  table: { createdAt: AnyPgColumn; modifiedAt: AnyPgColumn },
  tableName: string,
  indexes?: (table: any) => Record<string, Index>,
) => ({
  createdAtIdx: index(`idx_${tableName}_created_at`).on(table.createdAt),
  modifiedAtIdx: index(`idx_${tableName}_modified_at`).on(table.modifiedAt),
  ...(indexes ? indexes(table) : {}),
});

const createTableWithTimestamps = (
  tableName: string,
  additionalColumns = {},
  indexes?: (table: any) => Record<string, Index>,
) =>
  pgTable(
    tableName,
    {
      ...createTimestampColumns(),
      ...additionalColumns,
    },
    table => createTimestampIndexes(table, tableName, indexes),
  );

export const attachments = createTableWithTimestamps('attachments', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),
  key: varchar('key'),
  name: varchar('name'),
  content: varchar('content'),
  mimetype: varchar('mimetype'),
  size: numeric('size'),
});

// generic table for old CRM data
export const generic = createTableWithTimestamps('generic', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  _id: varchar('_id'), // mongodb id
  name: varchar('name'),
  type: varchar('type').notNull(),
  suggest: varchar('suggest'),
  payload: jsonb('payload'),
});

export const timelogs = createTableWithTimestamps('timelogs', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  user: uuid('user').references(() => users.id),
  date: date('date').notNull(),
  starttime: time('starttime').notNull(),
  endtime: time('endtime').notNull(),
  pause: numeric('pause').notNull(),
  duration: text('duration').generatedAlwaysAs(
    sql`TIME '00:00:00' + ((endtime - starttime) - pause * INTERVAL ' 1 MINUTE')`,
  ),
  project: varchar('project'),
  description: varchar('description'),
  account: uuid('account').references(() => accounts.id),
  deal: uuid('deal').references(() => deals.id),
  billable: boolean('billable').default(true),
  task: varchar('task'),
});

export const search = createTableWithTimestamps(
  'search',
  {
    id: uuid('id').defaultRandom().primaryKey().notNull(),

    name: varchar('name').notNull(),
    module: varchar('module').notNull(),
    suggest: varchar('suggest').notNull(),
    fts: tsVector('fts', {
      dimensions: 3,
    }).generatedAlwaysAs(
      (): SQL => sql`to_tsvector('english', ${search.suggest})`,
    ),
  },
  t => ({
    idx: index('search_idx').using('gin', t.fts),
  }),
);

export const sequences = createTableWithTimestamps('sequences', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  shortName: varchar('shortName'),
  value: numeric('value').notNull(),
  expression: varchar('expression'),
  initValue: numeric('initValue'),
  lastResetDate: timestamp('lastResetDate', {
    withTimezone: true,
    mode: 'string',
  }),
  nextResetDate: timestamp('nextResetDate', {
    withTimezone: true,
    mode: 'string',
  }),
  resetInterval: varchar('resetInterval'),
  description: varchar('description'),
});

export const lovs = createTableWithTimestamps('lovs', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  description: varchar('description'),
  values: jsonb('values'),
});

export const functionLogs = createTableWithTimestamps('function_logs', {
  id: serial('id').primaryKey().notNull(),
  functionName: varchar('function_name').notNull(),
  logMessage: varchar('log_message').notNull(),
  createdAt: timestamp('created_at', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
});

export const accounts = createTableWithTimestamps(
  'accounts',
  {
    id: uuid('id').defaultRandom().primaryKey().notNull(),

    name: varchar('name'),
    description: varchar('description'),
    logo: varchar('logo'),
    linkedin: varchar('linkedin'),
    url: varchar('url'),
    phone: varchar('phone'),
    websiteUrl: varchar('websiteUrl'),
    accountStatus: varchar('accountStatus'),
    industry: varchar('industry'), // or branch

    salesId: uuid('salesId').references(() => users.id),

    employees: numeric('employees'),
    revenue: numeric('revenue'),
    address: jsonb('address'),
    communication: jsonb('communication'),
    companyProfile: varchar('companyProfile'),
    philosophy: varchar('philosophy'),
    universalName: varchar('universalName'),
    employeeCount: numeric('employeeCount'),
    employeeCountRange: varchar('employeeCountRange'),
    officialName: varchar('officialName'),
    officialAddress: jsonb('officialAddress'),
    fiscalCode: varchar('fiscalCode'),

    tagline: varchar('tagline'),
    foundedOn: integer('foundedOn'),
    specialities: varchar('specialities').array(),
    crunchbaseFundingData: jsonb('crunchbaseFundingData'),
    company: jsonb('company'),

    creditRating: varchar('creditRating'),
    debtReason: varchar('debtReason'),
    currentBalanceDate: timestamp('currentBalanceDate', {
      withTimezone: true,
      mode: 'string',
    }),

    currentBalance: numeric('currentBalance'),
    maxDebt: numeric('maxDebt'),
    currentCreditLimit: numeric('currentCreditLimit'),

    paymentDelayInDays: integer('paymentDelayInDays').default(0),

    fedExICPCAccount: varchar('fedExICPCAccount'),
    tntICPCAccount: varchar('tntICPCAccount'),

    baseTariff: uuid('baseTariff').references(() => tariffs.id),
    customerTariff: uuid('customerTariff').references(() => tariffs.id),
    supplierTariff: uuid('supplierTariff').references(() => tariffs.id),

    // Bank data
    companyName: varchar('companyName'), // Company name for banking
    iban: varchar('iban'), // IBAN
    bic: varchar('bic'), // BIC
    bank: varchar('bank'), // Bank name
    tva: varchar('tva'), // TVA (tax code)

    contractId: uuid('contractId').references(() => contracts.id),
    contractAttachments: jsonb('contractAttachments'),
    contractComments: varchar('contractComments').array(),
    contractNumber: varchar('contractNumber'), // Contract number
    contractValidFrom: date('contractValidFrom'), // Contract valid from
    contractValidTo: date('contractValidTo'), // Contract valid to

    salesAgent: varchar('salesAgent'),
    region: varchar('region'),
    potentialCustomer: boolean('potentialCustomer'),
    customerType: varchar('customerType'),
    phase: varchar('phase'),
    operator: varchar('operator').array(),

    // Analysis of work
    status: varchar('status'),
    statusComment: varchar('statusComment'),
    satisfaction: varchar('satisfaction'),
    reasonOfUnsatisfaction: varchar('reasonOfUnsatisfaction'),
    competitors: varchar('competitors').array(),
    lostToCompetitor: varchar('lostToCompetitor').default(false),
    lostReason: varchar('lostReason'),
    commentToDecision: varchar('commentToDecision'),

    interestZone: varchar('interestZone').array(),
    direction: varchar('direction').array(),
    products: varchar('products').array(),
    serviceUsageFrequency: varchar('serviceUsageFrequency'),
    expectedVolume: varchar('expectedVolume'),
    expectedCategory: varchar('expectedCategory'),

    volumeCurrentQ: numeric('volumeCurrentQ'),
    volumeCountCurrentQ: numeric('volumeCountCurrentQ'),
    volumeLastQ: numeric('volumeLastQ'),
    volumeCountLastQ: numeric('volumeCountLastQ'),
    volumeDiff: numeric('volumeDiff'),
    categoryCurrentQ: varchar('categoryCurrentQ'),
    categoryLastQ: varchar('categoryLastQ'),
    categoryDiffToLastQ: varchar('categoryDiffToLastQ'),

    ourCustomer: boolean('ourCustomer'),
    customerSince: date('customerSince'),
    lastPayment: date('lastPayment'),
    lastInvoice: date('lastInvoice'),
    lastVisit: date('lastVisit'),
    lastContact: date('lastContact'),

    fts: tsVector('fts', {
      dimensions: 3,
    }).generatedAlwaysAs(
      (): SQL =>
        sql`to_tsvector('english', ${accounts.name} || ' ' || ${accounts.description} || ' ' || ${accounts.linkedin} || ' ' || ${accounts.url} || ' ' || ${accounts.phone} || ' ' || ${accounts.websiteUrl} || ' ' || ${accounts.companyProfile} || ' ' || ${accounts.philosophy} || ' ' || ${accounts.officialName})`,
    ),
  },
  t => ({
    idx: index('accounts_fts').using('gin', t.fts),
  }),
);

export const contracts = createTableWithTimestamps('contracts', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name'),
  description: varchar('description'),
  ponumber: varchar('ponumber'),
  contractNumber: varchar('contractNumber'), // Contract number
  issuedOn: timestamp('issuedOn', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  salesId: uuid('salesId').references(() => users.id),

  accountId: uuid('accountId')
    .references(() => accounts.id, { onDelete: 'cascade' })
    .notNull(), // Foreign key to the accounts table

  contactId: uuid('contactId').references(() => contacts.id, {
    onDelete: 'cascade',
  }),

  status: varchar('status').notNull(),

  baseTariffId: uuid('baseTariffId').references(() => tariffs.id), // Base tariff field
  customerTariffId: uuid('customerTariffId').references(() => tariffs.id), // Customer tariff field
  supplierTariffId: uuid('supplierTariffId').references(() => tariffs.id), // Customer tariff field

  validFrom: date('validFrom'), // Validity start date
  validTo: date('validTo'), // Validity end date

  paymentDelayInDays: integer('paymentDelayInDays').default(0), // Payment delay in days
  creditRating: varchar('creditRating'), // Credit rating field
  maxDebt: numeric('maxDebt'), // Maximum debt allowed

  // Bank details
  companyName: varchar('companyName'), // Company name for banking
  iban: varchar('iban'), // IBAN
  bic: varchar('bic'), // BIC
  bank: varchar('bank'), // Bank name
  tva: varchar('tva'), // TVA (tax code)
});

export const ownerships = createTableWithTimestamps('ownerships', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name'),
  issuedOn: timestamp('issuedOn', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  accountId: uuid('accountId')
    .references(() => accounts.id, { onDelete: 'cascade' })
    .notNull(), // Foreign key to accounts table

  salesId: uuid('salesId')
    .references(() => users.id)
    .notNull(),

  validFrom: date('validFrom').notNull(), // Validity start date
  validTo: date('validTo'), // Validity end date
});

export const analysis = createTableWithTimestamps('analysis', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  issuedOn: timestamp('issuedOn', { withTimezone: true, mode: 'string' })
    .defaultNow()
    .notNull(),
  accountId: uuid('accountId')
    .references(() => accounts.id, { onDelete: 'cascade' })
    .notNull(), // Foreign key to accounts table

  // Sales and potential data
  salesId: uuid('salesId').references(() => users.id),
  region: varchar('region'), // Region field
  potentialCustomer: boolean('potentialCustomer').default(false), // Potential customer boolean
  customerType: varchar('customerType'), // Customer type
  phase: varchar('phase'), // Phase of the deal
  operator: varchar('operator').array(), // Operator (e.g., FedEx, etc.)

  // Potential fields
  interestZone: varchar('interestZone').array(), // Zones of interest (e.g., France)
  direction: varchar('direction').array(), // Direction (e.g., Export)
  products: varchar('products').array(), // Products involved
  serviceUsageFrequency: varchar('serviceUsageFrequency'), // Service usage frequency
  expectedVolume: varchar('expectedVolume'), // Expected volume in range (e.g., 300-500 €/month)
  expectedCategory: varchar('expectedCategory'), // Expected category (e.g., B)

  // Work analysis fields
  status: varchar('status'), // Status (e.g., Development)
  statusComment: varchar('statusComment'), // Comment on status
  satisfaction: varchar('satisfaction'), // Satisfaction level
  reasonOfUnsatisfaction: varchar('reasonOfUnsatisfaction'), // Reason for unsatisfaction
  lostToCompetitor: boolean('lostToCompetitor').default(false), // Lost to competitor boolean
  competitors: varchar('competitors').array(), // Competitors
  lostReason: varchar('lostReason'), // Reason for lost deal
  commentToDecision: varchar('commentToDecision'), // Comment on decision

  // Volume fields
  volumeCurrentQ: numeric('volumeCurrentQ'), // Volume for current quarter
  volumeLastQ: numeric('volumeLastQ'), // Volume for last quarter
  volumeDiff: numeric('volumeDiff'), // Difference in volume
  currentCategory: varchar('currentCategory'), // Current category (e.g., Z)
  categoryLastQ: varchar('categoryLastQ'), // Category in the last quarter (e.g., Z)
  categoryDiffToLastQ: varchar('categoryDiffToLastQ'), // Difference to last quarter category (e.g., No change)
});

// Subsidiary accounts table
export const subsidiaryAccounts = createTableWithTimestamps('subsidiaries', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  parentAccountId: uuid('parentAccountId')
    .notNull()
    .references(() => accounts.id, { onDelete: 'cascade' }), // Reference to parent account
  name: varchar('name').notNull(),
  description: varchar('description'),
});

export const deals = createTableWithTimestamps('deals', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  dealOwner: uuid('consultant').references(() => users.id),
  name: varchar('name').notNull(),
  position: varchar('position'),
  start: date('start'),
  end: date('end'),
  salaryNotes: varchar('salaryNotes'),
  customer: uuid('customer').references(() => accounts.id),
  lastActivity: timestamp('lastActivity', {
    // letzte Aktivität
    withTimezone: true,
    mode: 'string',
  }),
  role: varchar('role'), // stellung

  form: varchar('form'), // form
  alternativeForm: varchar('alternativeForm'), // alternative Form
  state: varchar('state'), // status
  dealPhase: varchar('dealPhase'), // mandatsstatus
  annualGrossSalary: numeric('annualGrossSalary'), // jahresbruttogehalt
  dailyRate: numeric('dailyEate'), // tagessatz
  hourlyRate: numeric('hourlyRate'), // stundesatz
  tasks: varchar('tasks'), // aufgaben
  requirements: varchar('requirements'), // anforderungen
  languageSkills: varchar('languageSkills').array(), // sprachkenntnisse
  industryKnowledge: varchar('industryKnowledge').array(), // branchenkenntnisse
  skills: varchar('skills').array(), // fähigkeiten
  comment: varchar('comment'), // kommentar
  fee: numeric('fee'), // honorar
  totalFee: numeric('totalFee'), // gesamthonorar
  feeInstallments: numeric('feeInstallments'), // honorarraten
  url: varchar('url'),
});

export const dealsToContacts = createTableWithTimestamps(
  'dealsToContacts',
  {
    dealId: uuid('dealId')
      .notNull()
      .references(() => deals.id, {
        onDelete: 'cascade',
      }),
    contactId: uuid('contactId')
      .notNull()
      .references(() => contacts.id, {
        onDelete: 'cascade',
      }),
  },
  table => {
    return {
      pk: primaryKey({ columns: [table.dealId, table.contactId] }),
    };
  },
);

export const users = createTableWithTimestamps('users', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  externalId: varchar('externalId'),
  name: varchar('name'),
  photo: varchar('photo'),
  position: varchar('position'),
  communicationPrivate: jsonb('communicationPrivate'), // kommunikationPrivat
  communicationWork: jsonb('communicationWork'), // kommunikationArbeit
  addressPrivate: jsonb('addressPrivate'), // adressePrivat

  email: varchar('email').notNull(),
  username: varchar('username').notNull(),
  roles: varchar('roles').array(),
});

export const activities = createTableWithTimestamps(
  'activities',
  {
    id: uuid('id').defaultRandom().primaryKey().notNull(),

    name: varchar('name'),
    type: activityType('type').default('Email'),
    description: varchar('description'),
    // You can use { mode: "bigint" } if numbers are exceeding js number limitations
    consultant: uuid('consultant').references(() => users.id),
    startDate: timestamp('startDate', { withTimezone: true, mode: 'string' })
      .defaultNow()
      .notNull(),
    dueDate: timestamp('dueDate', { withTimezone: true, mode: 'string' }),
    messageId: varchar('messageId'),
    date: date('date'),
    contactedVia: varchar('contactedVia'),
    status: varchar('status'),
    phase: varchar('phase'),
    flagged: boolean('flagged').default(false),
    priority: priorityEnum('priorityEnums'),
    done: boolean('done').default(false),

    candidate: uuid('candidate').references(() => contacts.id),
    account: uuid('account').references(() => accounts.id),
    deal: uuid('deal').references(() => deals.id),

    success: boolean('success'),
    rejectedBy: varchar('rejectedBy'),
    rejectedReason: varchar('rejectedReason'),
    history: jsonb('history'),

    accounts: jsonb('accounts'),
    candidates: jsonb('candidates'),
    contacts: jsonb('contacts'),
    deals: jsonb('deals'),
    issues: jsonb('issues'),

    incommingMessage: boolean('incommingMessage'),
    hasAttachment: boolean('hasAttachment'),
    attachments: jsonb('attachments'),

    refs: uuid('refs').array(),

    fts: tsVector('fts', {
      dimensions: 3,
    }).generatedAlwaysAs(
      (): SQL =>
        sql`to_tsvector('english', ${activities.name} || ' ' || ${activities.description})`,
    ),
  },
  t => ({
    idx: index('activities_fts').using('gin', t.fts),
  }),
);

export const files = createTableWithTimestamps('files', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  filename: varchar('filename'),
  mimetype: varchar('mimetype'),
  size: numeric('size'),
  fileRefId: varchar('fileRefId'),
});

export const contacts = createTableWithTimestamps(
  'contacts',
  {
    id: uuid('id').defaultRandom().primaryKey().notNull(),

    role: contactRolesEnum('role')
      .notNull()
      .default(ContactRoleType.enum.Contact),
    name: varchar('name').notNull(),
    lastname: varchar('lastname'), // nachname
    firstname: varchar('firstname'), // vorname
    customer: uuid('customer').references(() => accounts.id),
    linkedin: varchar('linkedin'), // linkedin
    consent: boolean('consent'), // einwilligung
    email: varchar('email'),
    phone: varchar('phone'),
    photo: varchar('photo'), // foto
    linkedInPhoto: varchar('linkedInPhoto'), // foto
    salutation: varchar('salutation'), // anrede
    title: varchar('title'), // titel
    isCandidate: boolean('isCandidate'), // istKandidat
    cluster: varchar('cluster').array(), // cluster
    blockingNotice: varchar('blockingNotice'), // sperrvermerk
    status: varchar('status').array(), // status
    birthday: timestamp('birthday', { withTimezone: true, mode: 'string' }), // geburtstag
    nationality: varchar('nationality'), // staatsangehörigkeit
    personalImpression: varchar('personalImpression'), // persönlicherEindruck
    professionalImpression: varchar('professionalImpression'), // fachlicherEindruck
    department: varchar('department'), // abteilung
    position: varchar('position'), // position
    candidateCompany: varchar('candidateCompany'), // kandidatenFirma
    communicationPrivate: jsonb('communicationPrivate'), // kommunikationPrivat
    communicationWork: jsonb('communicationWork'), // kommunikationArbeit
    urlPrivate: varchar('urlPrivate'), // urlPrivat
    url: varchar('url'), // url
    addressPrivate: jsonb('addressPrivate'), // adressePrivat
    addressWork: jsonb('addressWork'), // adresseArbeit
    skills: varchar('skills').array(), // fähigkeiten
    focus: varchar('focus'), // schwerpunkt
    impression: varchar('impression'), // eindruck
    availableFrom: date('availableFrom'), // verfügbarAb
    targetRegion: varchar('targetRegion').array(), // zielregion
    annualGrossTarget: numeric('annualGrossTarget'), // jahresbruttoziel
    hourlyRate: numeric('hourlyRate'), // stundensatz
    dailyRate: numeric('dailyRate'), // tagessatz
    industry: varchar('industry').array(), // branche
    salaryNotes: varchar('salaryNotes'), // notizenZumGehalt
    jobTitle: varchar('jobTitle'), // jobbezeichnung
    positionInCompany: varchar('positionInCompany'), // stellungImUnternehmen
    languageSkills: varchar('languageSkills').array(), // sprachkenntnisse
    industryKnowledge: varchar('industryKnowledge').array(), // branchenkenntnisse
    description: varchar('description'), // beschreibung
    employment: jsonb('employment'), // beschäftigung
    projects: jsonb('projects'), // projekte
    education: jsonb('education'), // bildung
    other: jsonb('other'), // sonstiges
    furtherEducation: jsonb('furtherEducation'), // weiterbildung
    vocationalTraining: jsonb('vocationalTraining'), // berufsausbildung
    schoolEducation: jsonb('schoolEducation'), // schulbildung
    itQualifications: jsonb('itQualifications'), // itQualifikationen
    militaryCivilService: jsonb('militaryCivilService'), // wehrZivildienst
    summary: varchar('summary'), // zusammenfassung
    consultant: uuid('consultant').references(() => users.id),
    internalNotes: varchar('internalNotes'), // interneNotizen
    resume: varchar('resume'), // lebenslauf
    resumeLinkedIn: jsonb('resumeLinkedIn'), // lebenslauf
    resumeDate: timestamp('resumeDate', { withTimezone: true, mode: 'string' }), // lebenslaufDatum
    references: varchar('references'), // referenzen
    noticePeriod: varchar('noticePeriod'), // kündigungsfrist
    contact: jsonb('contact'),
    contactInfos: jsonb('contactInfos'),

    fts: tsVector('fts', {
      dimensions: 3,
    }).generatedAlwaysAs(
      (): SQL =>
        sql`to_tsvector('english', ${contacts.name} || ' ' || ${contacts.description} || ' ' || ${contacts.summary} || ' ' || ${contacts.internalNotes} || ' ' || ${contacts.resume} || ' ' || ${contacts.references} || ' ' || ${contacts.linkedin} || ' ' || ${contacts.email} || ' ' || ${contacts.phone} || ' ' || ${contacts.blockingNotice} || ' ' || ${contacts.position} || ' ' || ${contacts.candidateCompany} || ' ' || ${contacts.urlPrivate} || ' ' || ${contacts.url})`,
    ),
  },
  t => ({
    idx: index('contacts_fts').using('gin', t.fts),
  }),
);

export const invoices = createTableWithTimestamps('invoices', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  // invoiceId: text('invoiceId').default(sql`get_seq_id('invoices')`),
  invoiceId: text('invoiceId').default('DRAFT'),

  currency: varchar('currency').default('EUR'),
  billingMethod: varchar('billingMethod').default(''),
  memo: varchar('memo'),
  language: varchar('language').default('de'),
  paymentCollection: paymentCollectionEnum('paymentCollection'),
  dueInDays: integer('dueInDays').default(30),
  taxId: varchar('taxId'),
  taxIdName: varchar('taxIdName'),
  template: varchar('template'),

  invoiceDate: timestamp('invoiceDate', { withTimezone: true, mode: 'string' }),
  status: varchar('status'),
  subject: varchar('subject'),
  delivery: varchar('delivery'),
  customer: uuid('customer')
    .notNull()
    .references(() => accounts.id),
  recipient: uuid('recipient').references(() => contacts.id),
  net: numeric('net'),
  vatInPercent: numeric('vatInPercent'),
  vat: numeric('vat'),
  paymentDeadline: timestamp('paymentDeadline', {
    withTimezone: true,
    mode: 'string',
  }),
  gross: numeric('gross'),
  debit: numeric('debit'),
  positions: jsonb('positions'),
  orders: jsonb('orders'),
  name: varchar('name'),
  introduction: varchar('introduction'),
  conclusion: varchar('conclusion'),
  withQrCode: boolean('withQrCode'),
  withVat: boolean('withVat'),
  invoiceCancellation: varchar('invoiceCancellation'),
  fixedOn: timestamp('fixedOn', { withTimezone: true, mode: 'string' }),
  paidOn: timestamp('paidOn', { withTimezone: true, mode: 'string' }),
});

export const pipelines = createTableWithTimestamps('pipelines', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  modifySource: boolean('modifySource').notNull().default(false),
  sourceColumn: varchar('sourceColumn'),
  module: moduleNamesEnum('moduleName').notNull(),
});

export const filters = createTableWithTimestamps('filters', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  module: varchar('module').notNull(),
  filter: jsonb('filter').notNull(),
  type: filterTypesEnum('type').notNull(),
  search: varchar('search'),
  fulltextsearch: boolean('fulltextsearch'),
  view: varchar('view'),
  columns: jsonb('columns'),
  dealId: uuid('dealId')
    .references(() => deals.id)
    .unique(),
});

export const pipelinePhases = createTableWithTimestamps('pipelinePhases', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  phaseIndex: integer('phaseIndex').notNull(),
  pipelineId: uuid('pipelineId')
    .notNull()
    .references(() => pipelines.id),
  endPhase: boolean('endPhase').notNull().default(false),
  isPositive: boolean('isPositive'),
});

export const pipelineItems = createTableWithTimestamps('pipelineItems', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  phaseId: uuid('phaseId')
    .notNull()
    .references(() => pipelinePhases.id),
  accountId: uuid('accountId').references(() => accounts.id),
  contactId: uuid('contactId').references(() => contacts.id),
  dealId: uuid('dealId').references(() => deals.id),
  activityId: uuid('activityId').references(() => activities.id),
  filedId: uuid('fileId').references(() => files.id),
  data: jsonb('data'),
});

export const pipelineItemsHistory = createTableWithTimestamps(
  'pipelineItemsHistory',
  {
    id: uuid('id').defaultRandom().primaryKey().notNull(),

    pipelineItemId: uuid('pipelineItemId')
      .notNull()
      .references(() => pipelineItems.id),
    fromPhaseId: uuid('fromPhaseId')
      .notNull()
      .references(() => pipelinePhases.id),
    toPhaseId: uuid('toPhaseId')
      .notNull()
      .references(() => pipelinePhases.id),
    data: jsonb('data'),
  },
);

export const insertOwnershipSchema = createInsertSchema(ownerships);

export const insertContractsSchema = createInsertSchema(contracts);
export const contractsWithObjectsSchema = createInsertSchema(contracts, {
  sales: z.object({
    id: z.string().uuid(),
    name: z.string(),
    photo: z.string().nullable(),
  }),
  account: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
  contact: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  baseTariffObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  customerTariffObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
});

export const insertDealsSchema = createInsertSchema(deals, {
  feeInstallments: z.number().nullable().default(null),
  annualGrossSalary: z.number().nullable().default(null),
  totalFee: z.number().nullable().default(null),
  start: z.string().nullable().default(null),
  end: z.string().nullable().default(null),
  fee: z.number().nullable().default(null),
}).extend({
  customerObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
});

export type Contract = z.infer<typeof insertContractsSchema>;
export type ContractWithRefs = z.infer<typeof contractsWithObjectsSchema>;
export type Deal = z.infer<typeof insertDealsSchema>;
const communication = z.object({
  email: z.string().nullable().default(null),
  phone: z.string().nullable().default(null),
  mobile: z.string().nullable().default(null),
  fax: z.string().nullable().default(null),
  linkedin: z.string().nullable().default(null),
  whatsapp: z.string().nullable().default(null),
  telegram: z.string().nullable().default(null),
  xing: z.string().nullable().default(null),
  twitter: z.string().nullable().default(null),
  facebook: z.string().nullable().default(null),
  skype: z.string().nullable().default(null),
  other: z.string().nullable().default(null),
});

const address = z.object({
  street: z.string().nullable().default(null),
  city: z.string().nullable().default(null),
  province: z.string().nullable().default(null),
  country: z.string().nullable().default(null),
  postalCode: z.string().nullable().default(null),
});

// Schema for inserting contactWithRefsSchema account - can be used to validate API requests

export const insertAccountsSchema = createInsertSchema(accounts, {
  volumeCurrentQ: z.number().nullable().default(null),
  volumeLastQ: z.number().nullable().default(null),
  volumeCountCurrentQ: z.number().nullable().default(null),
  volumeCountLastQ: z.number().nullable().default(null),
  volumeDiff: z.number().nullable().default(null),
  revenue: z.number().nullable().default(null),
  employeeCount: z.number().nullable().default(null),
  employees: z.number().nullable().default(null),
  currentBalance: z.number().nullable().default(null),
  maxDebt: z.number().nullable().default(null),
  currentCreditLimit: z.number().nullable().default(null),
  address,
  hourlyRate: z.number().nullable().default(null),
  dailyRate: z.number().nullable().default(null),
  salaryNotes: z.string().nullable().default(null),
  tasks: z.string().nullable().default(null),
  requirements: z.string().nullable().default(null),
  comment: z.string().nullable().default(null),
});

export const accountsSchemaWithSales = insertAccountsSchema.extend({
  baseTariffObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  customerTariffObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  supplierTariffObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
});

export const insertTimelogSchema = createInsertSchema(timelogs, {
  date: z.string().nullable().default(null),
  pause: z.number().nullable().default(null),
});

export const insertContactsSchema = createInsertSchema(contacts, {
  id: z.string().uuid(),
  customer: z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    logo: z.string().nullable().default(null),
  }),
  annualGrossTarget: z.number().nullable().default(null),
  hourlyRate: z.number().nullable().default(null),
  dailyRate: z.number().nullable().default(null),
  birthday: z.string().nullable().default(null),
  addressPrivate: address,
  addressWork: address,
});

export const insertActivitiesSchema = createInsertSchema(activities, {
  consultant: z.string().uuid().optional(),
  startDate: z.string().nullable().default(null),
  dueDate: z.string().nullable().default(null),
  date: z.string().nullable().default(null),
  success: z.boolean().nullable().default(null),
});

export const insertFilesSchema = createInsertSchema(files);
export const insertInvoiceSchema = createInsertSchema(invoices, {
  gross: z.number().nullable().default(null),
  debit: z.number().nullable().default(null),
  vat: z.number().nullable().default(null),
  net: z.number().nullable().default(null),
  vatInPercent: z.number().nullable().default(null),
  delivery: z.enum(<const>['E-Mail', 'Letter']).default('E-Mail'),
  paymentDeadline: z.string().nullable().default(null),
  invoiceDate: z.string().nullable().default(null),
  fixedOn: z.string().nullable().default(null),
  withQrCode: z.boolean().nullable().default(null),
  withVat: z.boolean().nullable().default(null),
  invoiceId: z.string().nullable().default(null),
  subject: z.string().nullable().default(undefined),
  status: z
    .enum(<const>[
      'Draft',
      'Open',
      'Overdue',
      'Paid',
      'Cancelled',
      'Cancellation invoice',
    ])
    .default('Draft'),

  positions: z
    .array(
      z.object({
        id: z.string().min(5),
        text: z.string().nullable().default(''),
        unit: z.enum(<const>['Stunden', 'pauschal', 'Stück']),
        quantity: z.number(),
        price: z.number(),
        tax: z.number(),
        sum: z.number(),
      }),
    )
    .default([]),
});

export const invoiceWithRefsSchema = insertInvoiceSchema.extend({
  customerObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string(),
      address,
    })
    .required(),
  recipientObj: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  ordersRefs: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        shipment: z.object({
          id: z.string().uuid(),
          rate: z.object({
            id: z.string().uuid(),
            totalEUR: z.number(),
          }),
        }),
      }),
    )
    .nullable()
    .default([]),
});

export type InvoiceWithRefs = z.infer<typeof invoiceWithRefsSchema>;

export const insertLovsSchema = createInsertSchema(lovs, {
  id: z.string().uuid().nullable().default(undefined),
  createdAt: z.string().nullable().default(undefined),
  modifiedAt: z.string().nullable().default(undefined),
  name: z.string(),
  description: z.string().nullable(),
  values: z
    .array(
      z.object({
        icon: z.string().nullable().default(null),
        name: z.string(),
        color: z.string().nullable().default(null),
        code: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  orgId: z.string().nullable().default(undefined),
  userId: z.string().nullable().default(undefined),
});

export type Timelog = z.infer<typeof insertTimelogSchema>;
export type Account = z.infer<typeof insertAccountsSchema>;
export type AccountWithSales = z.infer<typeof accountsSchemaWithSales>;
export type Contact = z.infer<typeof insertContactsSchema>;
export type Activity = z.infer<typeof insertActivitiesSchema>;
export type File = z.infer<typeof insertFilesSchema>;
export type Invoice = z.infer<typeof insertInvoiceSchema>;
export type Lovs = z.infer<typeof insertLovsSchema>;

// Zod schema type is also inferred from the table schema, so you have full type safety
export const selectAccountsSchema = createSelectSchema(accounts);

export const contactWithRefsSchema = insertContactsSchema.extend({
  deals: z.array(insertDealsSchema).nullable().default([]),
  accounts: z.array(insertAccountsSchema).nullable().default([]),
});

export type ContactWithRefs = z.infer<typeof contactWithRefsSchema>;
export type AccountWithRefs = Account & {
  deals?: Deal[];
  contacts?: Contact[];
};

export const activityWithRefsSchema = insertActivitiesSchema.extend({
  issues: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    )
    .nullable()
    .default([]),
  deals: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        customer: z.string().uuid().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  accounts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    )
    .nullable()
    .default([]),
  contacts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  candidates: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  allContacts: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        role: z.string().nullable().default(null),
      }),
    )
    .nullable()
    .default([]),
  accountObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .optional()
    .default(undefined),
  dealObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .optional()
    .default(undefined),
  candidateObj: z
    .object({
      id: z.string(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .optional()
    .nullable()
    .default(undefined),
  consultantObj: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
      photo: z.string().optional(),
    })
    .optional(),
});

export const activityWithRefsInsertSchema = activityWithRefsSchema.extend({});

export type ActivityWithRefs = z.infer<typeof activityWithRefsSchema>;

export const ActivityType = z.enum(activityType.enumValues);
export const PriorityType = z.enum(priorityEnum.enumValues);

export const leads = createTableWithTimestamps('leads', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  status: varchar('status').default('NEW').notNull(),
  customer: uuid('customer').references(() => accounts.id),
  contact: uuid('contact').references(() => contacts.id),

  source: varchar('source'),
  assignedTo: uuid('assignedTo').references(() => users.id),
  estimatedValue: numeric('estimatedValue'),
  probability: numeric('probability'),
  expectedCloseDate: timestamp('expectedCloseDate', {
    withTimezone: true,
    mode: 'string',
  }),
  notes: text('notes'),
});

export const orders = createTableWithTimestamps('orders', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  assignedTo: uuid('assignedTo').references(() => users.id),
  name: varchar('name').notNull(),
  leadId: uuid('leadId').references(() => leads.id, { onDelete: 'set null' }),
  status: varchar('status').default('DRAFT').notNull(),
  customer: uuid('customer').references(() => accounts.id),
  contact: uuid('contact').references(() => contacts.id),
  orderDate: timestamp('orderDate', {
    withTimezone: true,
    mode: 'string',
  }).notNull(),
  totalAmountEUR: numeric('totalAmountEUR'),
  totalAmountNational: numeric('totalAmountNational'),
  currency: varchar('currency').default('EUR'),
  nationalCurrency: varchar('nationalCurrency'),
  exchangeRate: numeric('exchangeRate'),
  exchangeRateDate: timestamp('exchangeRateDate', {
    withTimezone: true,
    mode: 'string',
  }),
  paymentStatus: varchar('paymentStatus').default('PENDING'),
  paymentMethod: varchar('paymentMethod'),
  billingAddress: jsonb('billingAddress'),
  shippingAddress: jsonb('shippingAddress'),
  notes: text('notes'),
  invoiceDate: timestamp('invoiceDate', {
    withTimezone: true,
    mode: 'string',
  }),
  invoiceId: uuid('invoiceId').references(() => invoices.id),
});

export const shipments = createTableWithTimestamps('shipments', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  leadId: uuid('leadId')
    .references(() => leads.id, { onDelete: 'set null' })
    .unique(),
  orderId: uuid('orderId')
    .references(() => orders.id, { onDelete: 'set null' })
    .unique(),

  date: timestamp('date', { withTimezone: true, mode: 'string' }),
  status: varchar('status').default('DRAFT').notNull(),
  type: varchar('type'),
  direction: varchar('direction'),
  operator: varchar('operator'),
  paymentSide: varchar('paymentSide'),
  paymentMethod: varchar('paymentMethod'),
  fuelFee: boolean('fuelFee'),
  securityFee: boolean('securityFee'),
  product: varchar('product'),
  packageType: varchar('packageType'),
  extraService: varchar('extraService'),
  insurance: varchar('insurance'),
  priceInInvoice: numeric('priceInInvoice'),
  currencyInInvoice: varchar('currencyInInvoice'),
  priceInInvoiceEur: numeric('priceInInvoiceEur'),
  weight: numeric('weight'),
  weightPh: numeric('weightPh'),
  weightUnit: varchar('weightUnit').default('kg'),
  volume: numeric('volume'),
  volumeUnit: varchar('volumeUnit').default('m3'),
  volumeM3: numeric('volumeM3'),
  volumeKg: numeric('volumeKg'),
  packages: jsonb('packages'),
  country: varchar('country'),
  trackingNumber: varchar('trackingNumber'),
  estimatedDeliveryDate: timestamp('estimatedDeliveryDate', {
    withTimezone: true,
    mode: 'string',
  }),
  actualDeliveryDate: timestamp('actualDeliveryDate', {
    withTimezone: true,
    mode: 'string',
  }),
  totalEUR: numeric('totalEUR'),
  totalNational: numeric('totalNational'),
  exchangeRate: numeric('exchangeRate'),
  manualDiscount: numeric('manualDiscount'),
  manualDiscountEur: numeric('manualDiscountEur'),
  priceRequestId: varchar('priceRequestId'),
});

export const rates = createTableWithTimestamps('rates', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  shipmentId: uuid('shipmentId')
    .references(() => shipments.id)
    .unique()
    .notNull(),
  priceInInvoice: numeric('priceInInvoice'),
  currencyInInvoice: varchar('currencyInInvoice'),
  priceInInvoiceEur: numeric('priceInInvoiceEur'),
  fuelFee: boolean('fuelFee'),
  securityFee: boolean('securityFee'),
  totalEUR: numeric('totalEUR'),
  totalNational: numeric('totalNational'),
  customerPrice: numeric('customerPrice'),
  exchangeRate: numeric('exchangeRate'),
  price: numeric('price'),
  services: jsonb('services'),
  manualPrice: numeric('manualPrice'),
  discountEur: numeric('discountEur'),
  discountPercents: numeric('discountPercents'),
  manualDiscountEur: numeric('manualDiscountEur'),
  manualDiscount: numeric('manualDiscount'),
  manualDiscountOnTop: boolean('manualDiscountOnTop'),
  discountTotalEur: numeric('discountTotalEur'),
  discountTotalPercents: numeric('discountTotalPercents'),
  supplierEur: numeric('supplierEur'),
  supplierPercents: numeric('supplierPercents'),
  revenueEur: numeric('revenueEur'),
  revenuePercents: numeric('revenuePercents'),
  revenueTotalEur: numeric('revenueTotalEur'),
  supplierTotalEur: numeric('supplierTotalEur'),
  baseTarif: jsonb('baseTarif'),
  customerTarif: jsonb('customerTarif'),
  supplierTarif: jsonb('supplierTarif'),
});

// Insert schemas for each table
export const insertLeadSchema = createInsertSchema(leads);

export const leadSchemaWithRefs = insertLeadSchema.extend({
  account: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
  contactObj: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  shipment: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    )
    .nullable()
    .default([]),

  user: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
});

export const insertOrderSchema = createInsertSchema(orders);
export const insertRateSchema = createInsertSchema(rates).extend({
  customerPrice: z.number().nullable().default(0),
  manualPrice: z.number().nullable().default(0),
  manualDiscountEur: z.number().nullable().default(0),
  manualDiscount: z.number().nullable().default(0),
  manualDiscountOnTop: z.boolean().nullable().default(false),
  discountEur: z.number().nullable().default(0),
  discountPercents: z.number().nullable().default(0),
  discountTotalEur: z.number().nullable().default(0),
  discountTotalPercents: z.number().nullable().default(0),
  exchangeRate: z.number().nullable().default(0),
  price: z.number().nullable().default(0),
  priceInInvoiceEur: z.number().nullable().default(0),
  revenueEur: z.number().nullable().default(0),
  revenuePercents: z.number().nullable().default(0),
  revenueTotalEur: z.number().nullable().default(0),
  supplierEur: z.number().nullable().default(0),
  supplierPercents: z.number().nullable().default(0),
  supplierTotalEur: z.number().nullable().default(0),
  totalEUR: z.number().nullable().default(0),
  totalNational: z.number().nullable().default(0),
  priceInInvoice: z.number().nullable().default(0),
});

export const insertShipmentSchema = createInsertSchema(shipments).extend({
  weight: z.number().nullable().default(0),
  weightPh: z.number().nullable().default(0),
  volumeM3: z.number().nullable().default(0),
  volumeKg: z.number().nullable().default(0),
  totalEUR: z.number().nullable().default(0),
  totalNational: z.number().nullable().default(0),
  exchangeRate: z.number().nullable().default(0),
  volume: z.number().nullable().default(0),
  manualDiscount: z.number().nullable().default(0),
  manualDiscountEur: z.number().nullable().default(0),
  priceInInvoice: z.number().nullable().default(0),
  priceInInvoiceEur: z.number().nullable().default(0),
  securityFee: z.boolean().nullable().default(true),
  fuelFee: z.boolean().nullable().default(true),
});

export const shipmentWithRateSchema = insertShipmentSchema.extend({
  rate: insertRateSchema.omit({ shipmentId: true }).nullable().default(null),
});

// Types based on the insert schemas
export type Lead = z.infer<typeof insertLeadSchema>;
export type Order = z.infer<typeof insertOrderSchema>;
export type Shipment = z.infer<typeof insertShipmentSchema>;
export type Rate = z.infer<typeof insertRateSchema>;
export type ShipmentWithRate = z.infer<typeof shipmentWithRateSchema>;

// Combined schemas for more complex operations
export const leadWithShipmentAndRateSchema = insertLeadSchema.extend({
  shipment: shipmentWithRateSchema.nullable().default(null),
});

export const orderWithShipmentAndRateSchema = insertOrderSchema.extend({
  shipment: shipmentWithRateSchema.nullable().default(null),
});

export type LeadWithShipmentAndRate = z.infer<
  typeof leadWithShipmentAndRateSchema
>;
export type OrderWithShipmentAndRate = z.infer<
  typeof orderWithShipmentAndRateSchema
>;

export const issues = createTableWithTimestamps('issues', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  description: text('description'),
  accountId: uuid('accountId')
    .notNull()
    .references(() => accounts.id),
  issuedOn: timestamp('issuedOn', { withTimezone: true, mode: 'string' }),

  invoiceId: uuid('invoiceId').references(() => invoices.id),
  responsibleId: uuid('responsibleId').references(() => users.id),
  reporterId: uuid('reporterId').references(() => users.id),
  orderId: uuid('orderId').references(() => orders.id),

  dateClosed: timestamp('dateClosed', { withTimezone: true, mode: 'string' }),
  deadline: timestamp('deadline', { withTimezone: true, mode: 'string' }),

  status: varchar('status').notNull(),
  resolution: text('resolution'),
  priority: varchar('priority').notNull().default('medium'),
  category: varchar('category'),
  assigneeIds: uuid('assigneeIds').array(),

  escalated: boolean('escalated').notNull().default(false),
  escalationReason: text('escalationReason'),
  escalationLevel: integer('escalationLevel'),
  complainantName: varchar('complainantName'),
  complainantEmail: varchar('complainantEmail'),
  complainantPhone: varchar('complainantPhone'),
  orderIds: uuid('orderIds').array(),
});

export const comments = createTableWithTimestamps('comments', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  issueId: uuid('issueId')
    .notNull()
    .references(() => issues.id),
  parentCommentId: uuid('parentCommentId').references(() => comments.id),
  authorId: uuid('authorId')
    .notNull()
    .references(() => users.id),
  content: text('content').notNull(),
});

export const payments = createTableWithTimestamps('payments', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').default('Payment for Invoice'),
  paymentDate: timestamp('paymentDate', {
    withTimezone: true,
    mode: 'string',
  }).notNull(),
  amount: numeric('amount').notNull(),
  currency: varchar('currency').notNull().default('EUR'),
  exchange: numeric('exchange'),
  amountEur: numeric('amountEur').notNull(),

  status: varchar('status').notNull(), // e.g., 'Succeeded', 'Failed', 'Pending'
  paymentMethod: varchar('paymentMethod'), // e.g., 'bank_transfer', 'credit_card', 'paypal'

  // New fields based on the screenshot
  riskEvaluation: varchar('riskEvaluation'),
  processingFees: numeric('processingFees'),
  netAmount: numeric('netAmount'),
  description: text('description'),

  // Associations
  accountId: uuid('accountId').references(() => accounts.id),
  invoiceId: uuid('invoiceId').references(() => invoices.id),

  // Timeline events
  paymentStartedAt: timestamp('paymentStartedAt', {
    withTimezone: true,
    mode: 'string',
  }),
  paymentSucceededAt: timestamp('paymentSucceededAt', {
    withTimezone: true,
    mode: 'string',
  }),

  // Metadata
  metadata: text('metadata'),

  // Additional fields from original schema that might still be relevant
  referenceNumber: varchar('referenceNumber'),
  notes: text('notes'),
  createdBy: uuid('createdBy').references(() => users.id),
  updatedBy: uuid('updatedBy').references(() => users.id),
});

// Create insert schema
export const insertPaymentSchema = createInsertSchema(payments, {
  amount: z.number(),
  currency: z.string(),
  paymentDate: z.string(),
  status: z.string(),
  paymentMethod: z.string(),
  riskEvaluation: z.string().optional(),
  processingFees: z.number().optional(),
  netAmount: z.number().optional(),
  description: z.string().optional(),
  paymentStartedAt: z.string().optional(),
  paymentSucceededAt: z.string().optional(),
  metadata: z.string().optional(),
});

export type Payment = z.infer<typeof insertPaymentSchema>;

export const tariffs = createTableWithTimestamps('tariffs', {
  id: uuid('id').defaultRandom().primaryKey().notNull(),

  name: varchar('name').notNull(),
  description: text('description'),
  type: varchar('type').default('Customer'),
  currency: varchar('currency').notNull().default('EUR'),
  startDate: timestamp('startDate', {
    withTimezone: true,
    mode: 'string',
  }).notNull(),
  endDate: timestamp('endDate', { withTimezone: true, mode: 'string' }),
  exceptions: jsonb('exceptions'),
  pricelists: jsonb('pricelists'),
});

export const insertTariffSchema = createInsertSchema(tariffs);
export type Tariff = z.infer<typeof insertTariffSchema>;

export const insertIssueSchema = createInsertSchema(issues).extend({
  account: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      logo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
  order: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  invoice: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable()
    .default(undefined),
  reporter: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
  responsible: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .nullable()
    .default(undefined),
});
export type Issue = z.infer<typeof insertIssueSchema>;

export const ownershipsSchema = createInsertSchema(ownerships).extend({
  sales: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      photo: z.string().nullable(),
    })
    .default(undefined),
});
export type Ownership = z.infer<typeof ownershipsSchema>;
