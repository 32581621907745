<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    ActivityType,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import * as Form from '$lib/components/ui/form';
  import { NotepadText, Phone } from 'lucide-svelte';
  import { Button } from '$lib/components/ui/button/index';
  import { appStore } from '$lib/app-store';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import ActivityRefsPopover from './activity-refs-popover.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async ({ formData }) => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      let parse = activityWithRefsInsertSchema.parse($formData);

      saveActivity(parse);
      appStore.closeActivity();
    },
  });

  let { form: formData, enhance, errors } = form;
</script>

<Popup {form}>
  <svelte:fragment slot="header">
    <NotepadText class="mr-4 w-5 h-5" />
    Note
  </svelte:fragment>
  <div class="flex overflow-scroll flex-col py-0 mt-4 h-96" slot="content">
    <HantaInputTextareaRichtext
      {form}
      variant="ghost"
      name="description"
      placeholder="Start typing to leave a note"
      resizable={false}
    />

    <div
      class="flex flex-row items-center mx-6 space-x-4 border-t border-solid border-t-primary-200/20"
    >
      <ActivityRefsPopover {activity} {form} />
    </div>
  </div>
</Popup>
