<script lang="ts">
  import { cn } from '$lib/utils';
  import PdfIcon from '$lib/components/icons/pdf-icon.svelte';
  import WordIcon from '$lib/components/icons/word-icon.svelte';

  import { toast } from 'svelte-sonner';
  import { FileText, ChevronDown, Image } from 'lucide-svelte';

  import { deleteFile, downloadFile, getFileUrl } from '$lib/api/storage';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import FilePreview from './file-preview.svelte';

  export let file;
  export let folder;
  export let refresh = 0;

  let showPreview = false;
  let previewUrl;
  let blob;

  const onCreatePublicLink = async (expiresIn: number) => {
    const { signedUrl } = await getFileUrl(folder, file?.name, expiresIn);
    try {
      await navigator.clipboard.writeText(signedUrl);
      toast.success('URL copied to clipboard', { duration: 3000 });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const onPreview = async () => {
    if (!previewUrl) {
      if (isOfficeDocument) {
        const { signedUrl } = await getFileUrl(folder, file?.name);
        previewUrl = signedUrl;
      } else {
        if (import.meta.env.MODE === 'plugin') {
          const { signedUrl } = await getFileUrl(folder, file?.name);
          previewUrl = signedUrl;
        } else {
          const blob = await downloadFile(folder, file?.name);
          previewUrl = URL.createObjectURL(blob);
        }
      }
    }
    showPreview = true;
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
  };

  const onDownload = async () => {
    if (!blob) {
      blob = await downloadFile(folder, file?.name);
    }
    downloadBlob(blob, file?.name);
  };

  const onDelete = async () => {
    await deleteFile(folder, file?.name);
    refresh = refresh + 1;
  };

  $: isDocument = file?.name?.toLowerCase().endsWith('.docx');
  $: isPdf = file?.name?.toLowerCase().endsWith('.pdf');
  $: isImage = !!['.png', '.jpg', '.jpeg', '.gif'].find(el =>
    file?.name?.toLowerCase().endsWith(el),
  );

  $: isOfficeDocument =
    file?.name?.endsWith('.docx') ||
    file?.name?.endsWith('.doc') ||
    file?.name?.endsWith('.pptx') ||
    file?.name?.endsWith('.ppt') ||
    file?.name?.endsWith('.xlsx') ||
    file?.name?.endsWith('.xls');
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Button
      builders={[builder]}
      variant="outline"
      class="relative w-full h-full shadow-xs bg-primary-800"
    >
      <div class="flex overflow-hidden flex-1 items-center p-1 mr-7">
        <div class="mr-1 text-primary">
          {#if isDocument}
            <WordIcon />
          {:else if isPdf}
            <PdfIcon />
          {:else if isImage}
            <Image class="text-teal-700 dark:text-teal-300 size-8" />
          {:else}
            <FileText class="size-8 text-primary" />
          {/if}
        </div>
        <div class="ml-1 text-left truncate">
          <p class="text-sm font-medium truncate text-primary">
            {file?.name}
          </p>
          <p class="text-xs text-primary/50">
            {Math.round(file?.metadata?.size / 1024)} KB
          </p>
        </div>
      </div>

      <ChevronDown
        class={cn(
          'absolute right-2 size-4',
          builder['aria-expanded'] && 'translate rotate-180',
        )}
      />
    </Button>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content class="w-64">
    <DropdownMenu.Label class="truncate">{file?.name}</DropdownMenu.Label>
    <DropdownMenu.Separator />
    <DropdownMenu.Item on:click={onPreview}>Show</DropdownMenu.Item>
    <DropdownMenu.Item on:click={onDownload}>Download</DropdownMenu.Item>
    <DropdownMenu.Separator />

    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>Get URL</DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent class="w-48">
        <DropdownMenu.Item on:click={() => onCreatePublicLink(60)}
          >Expire in 1 minute</DropdownMenu.Item
        >
        <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60)}
          >Expire in 1 hour</DropdownMenu.Item
        >
        <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60 * 24)}
          >Expire in 1 day</DropdownMenu.Item
        >
        <DropdownMenu.Item on:click={() => onCreatePublicLink(60 * 60 * 24 * 7)}
          >Expire in 1 week</DropdownMenu.Item
        >
        <DropdownMenu.Item
          on:click={() => onCreatePublicLink(60 * 60 * 24 * 30)}
          >Expire in 1 month</DropdownMenu.Item
        >
        <DropdownMenu.Item
          on:click={() => onCreatePublicLink(60 * 60 * 24 * 365)}
          >Expire in 1 year</DropdownMenu.Item
        >
        <!--Todo:  not in v1 
        <DropdownMenu.Separator />
        <DropdownMenu.Item>Custom expire...</DropdownMenu.Item>
        -->
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>

    <DropdownMenu.Separator />
    <DropdownMenu.Item class="text-red-500" on:click={onDelete}
      >Delete</DropdownMenu.Item
    >
  </DropdownMenu.Content>
</DropdownMenu.Root>

{#if showPreview && previewUrl}
  <FilePreview
    {file}
    bind:open={showPreview}
    {previewUrl}
    officeDocument={isOfficeDocument}
  />
{/if}
