<script>
  import { Mail, FileText, Copy } from 'lucide-svelte';
  import { formatDate } from '$lib/app/utils';

  export let activities = [
    {
      icon: Mail,
      description: 'Invoice was sent to dennis.timotin@t-next.de',
      timestamp: 'Sep 23, 2024, 3:33 PM',
      highlight: false,
    },
    {
      icon: FileText,
      description: 'Invoice was finalized',
      timestamp: 'Sep 23, 2024, 3:33 PM',
      highlight: false,
    },
    {
      icon: FileText,
      description: 'Invoice payment page was created',
      timestamp: 'Sep 23, 2024, 3:32 PM',
      highlight: true,
      action: Copy,
    },
  ];
</script>

<div class="p-6 w-full rounded-lg shadow-sm">
  <h2 class="mb-4 text-xl font-bold">Recent activity</h2>
  <ul class="relative space-y-4">
    {#each activities as activity}
      <li class="flex items-start">
        <div class="mt-1 mr-4">
          <svelte:component
            this={activity.icon ?? Mail}
            size={20}
            class="text-teal-700"
          />
        </div>
        <div class="flex-grow">
          <p class="text-primary/60">
            {#if activity.highlight}
              <span class="text-teal-600"
                >{activity.description?.split(' ').slice(0, -2).join(' ') ??
                  ''}</span
              >
              {activity.description?.split(' ').slice(-2).join(' ') ?? ''}
            {:else}
              {activity.description ?? ''}
            {/if}
            {#if activity.action}
              <svelte:component
                this={activity.action}
                size={16}
                class="inline-block ml-1 text-primary/60"
              />
            {/if}
          </p>
          <p class="text-sm text-primary/80">
            {formatDate(activity.timestamp)}
          </p>
        </div>
      </li>
    {/each}
  </ul>
</div>
