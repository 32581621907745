<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/app-store';
  import type { Route } from '../../app/config';

  export let route: Route;
  export let collapsed = false;

  function handleClick() {
    if ($appStore.currentModule === route.module) {
      appStore.setCurrentModule(null);
    } else {
      appStore.setCurrentModule(route.module);
    }
  }

  $: isActive = route.module === $appStore.currentModule;
</script>

<Button
  on:click={handleClick}
  variant={isActive ? 'default' : 'ghost'}
  size={collapsed ? 'icon' : 'sm'}
  class={cn(
    'w-full ml-0',
    'justify-start my-0.5 hover:bg-primary/30',
    isActive && 'bg-orange-600 text-secondary hover:bg-orange-500',
    collapsed ? 'size-9 pl-2.5' : '',
  )}
>
  <svelte:component
    this={route.icon}
    class={cn('size-4', !collapsed && 'mr-2')}
    aria-hidden="true"
  />
  {#if !collapsed}
    {route.title}
    {#if route.label}
      <span
        class={cn('ml-auto', {
          'text-background dark:text-white': route.variant === 'default',
        })}
      >
        {route.label}
      </span>
    {/if}
  {:else}
    <span class="sr-only">{route.title}</span>
  {/if}
</Button>
