<script lang="ts">
  import { cn } from '$lib/utils';
  export let icon: any;

  let className = undefined;
  export { className as class };
</script>

{#if icon}
  <svelte:component
    this={icon}
    class={cn('inline self-baseline size-3.5', className ?? '')}
  />
{/if}
