interface ExchangesData {
  name: string;
  mdl: number;
  einheit: number;
}

export class PriceService {
  private manualPriceFields = ['price', 'supplierEur', 'customerPrice'];

  private fields = [
    'supplierPercents',
    'discountEur',
    'discountPercents',
    'discountTotalEur',
    'discountTotalPercents',
    'revenueEur',
    'revenuePercents',
    'mdl',
    'eur',
    'totalEUR',
    'totalMDL',
    'revenueTotalEur',
    'supplierTotalEur',
  ];

  /**
   * Calculates prices for the given item, including services.
   * @param request The request to calculate prices for.
   * @param tarifs The tariffs to use for calculations.
   * @param exchangesData Exchange rates data.
   * @param withMdl Indicates if calculations should include MDL currency.
   * @returns The request with updated prices.
   */
  public async calculatePrices(
    request: any,
    tarifs: { baseTarif: any; customerTarif: any; supplierTarif: any },
    exchangesData: ExchangesData[],
    withMdl?: boolean,
  ): Promise<any> {
    const timeStart = performance.now();

    try {
      // Reset item prices
      this.resetPrices(request);

      // Filter out specific services
      this.filterServices(request);

      // Update exchange rates if required
      if (withMdl) {
        this.updateExchanges(request, exchangesData);
        this.setMdl2eurOnServices(request);
      }

      const { baseTarif, customerTarif, supplierTarif } = tarifs;

      // Calculate price for the main item
      await this.calculatePriceForProduct(
        request,
        baseTarif,
        customerTarif,
        supplierTarif,
        exchangesData,
      );

      // Prepare additional services
      this.prepareServices(request);

      // Calculate prices for each service
      await Promise.all(
        request.services.map(service =>
          this.calculatePriceForProduct(
            service,
            baseTarif,
            customerTarif,
            supplierTarif,
            exchangesData,
          ),
        ),
      );

      // Calculate total amounts
      this.calculateTotals(request, withMdl);

      // Round all prices
      this.roundPrices(request);

      request.priceRequestId = crypto.randomUUID();
      return request;
    } finally {
      const timeEnd = performance.now();
      const executionTime = timeEnd - timeStart;
      console.log(
        `PriceService.calculatePrices took ${executionTime.toFixed(2)} milliseconds`,
      );
    }
  }

  /**
   * Filters out specific services from the item's services.
   * @param item The item whose services are to be filtered.
   */
  private filterServices(item: any) {
    item.services =
      item.services?.filter(
        service =>
          service['product'] &&
          !service['product'].includes('Надбавка за безопасность') &&
          !service['product'].includes('Топливная надбавка') &&
          !service['product'].includes('ODA Surcharge') &&
          !service['product'].includes('Страховка '),
      ) ?? [];
  }

  /**
   * Sets the 'mdl2eur' value on each service.
   * @param item The item whose services will be updated.
   */
  private setMdl2eurOnServices(item: any) {
    item.services.forEach(service => {
      service['mdl2eur'] = item['mdl2eur'];
    });
  }

  /**
   * Calculates total amounts for the item.
   * @param item The item to calculate totals for.
   * @param withMdl Indicates if MDL totals should be calculated.
   */
  private calculateTotals(item: any, withMdl: boolean) {
    item['totalEUR'] = item['services'].reduce(
      (total, service) => total + (service['eur'] || 0),
      item['eur'] || 0,
    );

    if (withMdl) {
      item['totalMDL'] = item['services'].reduce(
        (total, service) => total + (service['mdl'] || 0),
        item['mdl'] || 0,
      );
    }

    item['revenueTotalEur'] = this.roundValue(
      (item['revenueEur'] || 0) +
        item['services'].reduce((total, el) => total + (el.revenueEur || 0), 0),
    );

    item['supplierTotalEur'] = this.roundValue(
      (item['supplierEur'] || 0) +
        item['services'].reduce(
          (total, el) => total + (el.supplierEur || 0),
          0,
        ),
    );
  }

  /**
   * Prepares additional services for the item based on operator and fees.
   * @param item The item to prepare services for.
   */
  private prepareServices(item: any) {
    if (item['operator'] === 'TNT' || item['operator'] === 'FedEx') {
      if (item['insurance']) {
        const insuranceProductName = 'Страховка ' + item['insurance'];
        const insurance = this.findOrCreateService(item, insuranceProductName);
        insurance['operator'] = item['operator'];
        insurance['weight'] = this.roundValue(item['priceInInvoiceEur'] || 0);
      }

      if (item['securityFee']) {
        const securityFeeProductName = 'Надбавка за безопасность';
        const securityFee = this.findOrCreateService(
          item,
          securityFeeProductName,
        );
        securityFee['operator'] = item['operator'];
      }

      if (item['odaFee']) {
        const odaFeeProductName =
          'ODA Surcharge' +
          (item['operator'] === 'TNT'
            ? ' ' + item['direction'] + ' ' + item['product']
            : '');
        const odaFee = this.findOrCreateService(item, odaFeeProductName);
        odaFee['weight'] = 1;
        odaFee['operator'] = item['operator'];
      }

      if (item['fuelFee']) {
        const fuelFeeProductName = 'Топливная надбавка';
        const fuelFee = this.findOrCreateService(item, fuelFeeProductName);
        const fuelFeeBackup =
          item['servicesBackup'] &&
          item['servicesBackup'].find(
            service => service['product'] === fuelFeeProductName,
          );
        // TODO: checken
        if (false && fuelFeeBackup && fuelFeeBackup['manualDiscountOnTop']) {
          fuelFee['manualDiscountOnTop'] = fuelFeeBackup['manualDiscountOnTop'];
          fuelFee['manualDiscount'] = fuelFeeBackup['manualDiscount'];
        }
        fuelFee['weight'] = this.roundValue(item['price']);
        fuelFee['weightOrig'] = this.roundValue(item['supplierEur']);
        fuelFee['operator'] = item['operator'];

        const dateStr = item['date'] ? item['date'].substr(0, 10) : '';
        const dateObj = new Date(dateStr);

        // Calculate ISO week number
        const weekNumber = this.getWeekNumber(dateObj);
        const yearNumber = dateObj.getFullYear();
        fuelFee['week'] = weekNumber + ' ' + yearNumber;
      }
    }
  }

  /**
   * Updates exchange rates for the item.
   * @param item The item to update.
   * @param exchangesData The exchange rates data.
   */
  private updateExchanges(item: any, exchangesData: ExchangesData[]) {
    const eur = this.findExchanges(exchangesData);
    item['mdl2eur'] = eur['mdl'] / eur['einheit'];

    item['priceInInvoiceEur'] = 0;
    if (
      item['mdl2eur'] &&
      item['currencyInInvoice'] &&
      item['priceInInvoice']
    ) {
      const currency = this.findExchanges(
        exchangesData,
        item['currencyInInvoice'],
      );

      if (currency) {
        item['priceInInvoiceEur'] = this.roundValue(
          ((item['priceInInvoice'] / currency['einheit']) * currency['mdl']) /
            item['mdl2eur'],
        );
      }
    }
  }

  /**
   * Calculates price for a product (item or service).
   * @param item The item or service to calculate price for.
   * @param tarif The base tariff.
   * @param customerTarif The customer tariff.
   * @param supplierTarif The supplier tariff.
   * @param dimensions The dimensions used in price calculations.
   * @param exchangesData Exchange rates data.
   */
  private async calculatePriceForProduct(
    item: any,
    tarif: any,
    customerTarif: any,
    supplierTarif: any,
    exchangesData: any[],
  ): Promise<void> {
    const standardPrice = await this.findPrice(tarif, item);

    if (!item['manualPrice']) {
      item['price'] = standardPrice;
    }

    if (supplierTarif) {
      const supplierPrice = await this.findPrice(supplierTarif, item, true);
      if (!item['manualPrice']) {
        item['supplierEur'] = supplierPrice;
      }
      if (item['price'] > 0) {
        item['supplierPercents'] = item['supplierEur'] / item['price'];
      }
    }

    if (customerTarif) {
      //TODO:
      /*const dimensionValuesSpecial = await this.applyCustomerTarifExceptions(
        item,
        customerTarif,
        dimensions,
      );*/

      let customerPrice = await this.findPrice(
        customerTarif,
        item,
        false,
        true,
      );

      customerPrice = this.adjustCustomerPriceForCurrency(
        customerPrice,
        customerTarif,
        item,
        exchangesData,
      );

      this.assignCustomerPrice(item, customerPrice);

      // Calculate discounts and revenues
      this.calculateDiscountsAndRevenues(item);
    }
  }

  /**
   * Applies exceptions from the customer tariff to the item.
   * @param item The item to apply exceptions to.
   * @param customerTarif The customer tariff containing exceptions.
   * @param dimensions The dimensions used in price calculations.
   * @returns Updated dimension values after applying exceptions.
   */
  private async applyCustomerTarifExceptions(
    item: any,
    customerTarif: any,
    dimensions: any[],
  ): Promise<any[]> {
    let dimensionValuesSpecial = await this.getDimensionValues(
      item,
      dimensions,
    );

    if (
      item['country'] &&
      item['country']['name'] &&
      customerTarif['exceptions'] &&
      customerTarif['exceptions'].length > 0
    ) {
      const exception = customerTarif['exceptions'].find(
        el => el['country']['name'] === item['country']['name'],
      );
      if (exception) {
        const countryBkp = { ...item['country'] };
        try {
          item['country']['zone'] =
            exception['zone'] || item['country']['zone'];
          item['country']['zoneEco'] =
            exception['zoneEco'] || item['country']['zoneEco'];
          item['country']['zoneFedEx'] =
            exception['zoneFedEx'] || item['country']['zoneFedEx'];
          dimensionValuesSpecial = await this.getDimensionValues(
            item,
            dimensions,
          );
        } finally {
          item['country'] = countryBkp;
        }
      }
    }

    return dimensionValuesSpecial;
  }

  /**
   * Adjusts the customer price based on currency and exchange rates.
   * @param customerPrice The initial customer price.
   * @param customerTarif The customer tariff.
   * @param item The item being priced.
   * @param exchangesData Exchange rates data.
   * @returns Adjusted customer price.
   */
  private adjustCustomerPriceForCurrency(
    customerPrice: number,
    customerTarif: any,
    item: any,
    exchangesData: any[],
  ): number {
    if (!customerTarif['currency']) {
      item['dealCurrency'] = 'EUR';
    }

    if (customerPrice > 0 && customerTarif['currency']) {
      const day = this.findExchanges(exchangesData);
      if (day) {
        if (
          customerTarif['currency'] === 'AZN' &&
          item['dealCurrency'] !== 'EUR'
        ) {
          customerPrice =
            Math.floor((customerPrice / day['mdl2eur']) * 100) / 100;
          item['dealCurrency'] = 'AZN';
        } else {
          item['dealCurrency'] = 'EUR';
        }
      } else {
        customerPrice = NaN;
      }
    }
    return customerPrice;
  }

  /**
   * Assigns the calculated customer price to the item.
   * @param item The item to assign the price to.
   * @param customerPrice The calculated customer price.
   */
  private assignCustomerPrice(item: any, customerPrice: number) {
    if (!item['manualPrice']) {
      if (Number.isNaN(customerPrice)) {
        item['price'] = 0;
        item['customerPrice'] = 0;
      } else {
        if (customerPrice === 0) {
          item['customerPrice'] = item['price'];
          item['dealCurrency'] = 'EUR';
        } else {
          item['customerPrice'] = customerPrice;
        }
      }
    }
  }

  /**
   * Calculates discounts and revenues for the item.
   * @param item The item to calculate discounts and revenues for.
   */
  private calculateDiscountsAndRevenues(item: any) {
    item['discountEur'] = item['price'] - item['customerPrice'];
    if (item['price'] > 0) {
      item['discountPercents'] = (item['discountEur'] / item['price']) * 100;
    }
    item['discountTotalEur'] = item['discountEur'];
    item['discountTotalPercents'] = item['discountPercents'];

    if (!isNaN(item['manualDiscount']) && item['manualDiscount'] !== 0) {
      item['manualDiscountEur'] =
        (item['manualDiscount'] * item['price']) / 100;
      // TODO: immer on top
      if (true || item['manualDiscountOnTop']) {
        item['discountTotalEur'] =
          item['discountEur'] + item['manualDiscountEur'];
        item['discountTotalPercents'] =
          item['manualDiscount'] + item['discountPercents'];
      } else {
        item['discountTotalEur'] = item['manualDiscountEur'];
        item['discountTotalPercents'] = item['manualDiscount'];
      }
    }

    item['eur'] = item['price'] - item['discountTotalEur'];
    item['mdl'] = Math.round(item['eur'] * item['mdl2eur']);

    item['revenueEur'] = item['eur'] - item['supplierEur'];
    if (item['price'] > 0) {
      item['revenuePercents'] = item['revenueEur'] / item['price'];
    }
  }

  /**
   * Finds exchange rates data for a specific date.
   * @param exchangesData The exchange rates data.
   * @returns The exchange rates data for the given date.
   */
  public findExchanges(
    exchangesData: any[],
    currency: string = 'EUR - Euro',
  ): any {
    return (
      exchangesData?.find((d: any) => d.name === currency) || {
        einheit: 1,
        mdl: 0,
        mdl2eur: 0,
      }
    );
  }

  /**
   * Gets dimension values for the item based on dimensions definitions.
   * @param item The item to get dimension values for.
   * @param dimensions The dimensions definitions.
   * @returns An array of dimension values.
   */
  private async getDimensionValues(item: any, dims: any[]): Promise<any[]> {
    const dimensions = dims
      .filter(dimension => dimension.field)
      .map(dimension => [
        dimension.name,
        function (expression: string) {
          try {
            return eval(expression);
          } catch (e) {
            return null;
          }
        }.call(item, dimension.field),
        dimension.field,
        dimension.type,
      ]);
    const result = dimensions.filter(dim => dim[1] !== undefined);
    return result;
  }

  /**
   * Finds a price for the item based on the tariff and dimensions.
   * @param tarif The tariff to search in.
   * @param item The item to find price for.
   * @param isSupplier Indicates if the price is for the supplier.
   * @param isCustomerTarif Indicates if the price is from the customer tariff.
   * @returns The found price.
   */
  private async findPrice(
    tarif: any,
    item: any,
    isSupplier?: boolean,
    isCustomerTarif?: boolean,
  ): Promise<number> {
    let price = 0;
    if (tarif) {
      const quotes = await this.findQuoteInPricelists(tarif, item);

      if (quotes && quotes.length > 0) {
        const quotesWithPrices = quotes.map(q => ({
          pricelist: q.pricelist,
          prices: q.prices,
          pricelistDimensions: q.pricelistDimensions,
          pricelistItem: Object.fromEntries(
            q.pricelistDimensions.map(el => [el[0], el[1]]),
          ),
          price: this.getPrice(q, item, isSupplier, isCustomerTarif),
        }));

        const quote = quotesWithPrices.reduce((longest, current) =>
          current.prices &&
          current.prices.length > (longest.prices ? longest.prices.length : 0)
            ? current
            : longest,
        );

        price = quote.price;

        const priceLog = {
          tarif: `${tarif.name} ${tarif.refId} (${
            isSupplier ? 'supplier' : isCustomerTarif ? 'customer' : 'standard'
          })`,
          price,
          quote: quote,
          quotes: quotesWithPrices,
        };

        console.table(priceLog);
      }
    } else {
      console.log('tarif not found');
    }
    return price;
  }

  /**
   * Finds matching quotes in the tariff's pricelists based on dimensions.
   * @param tarif The tariff to search in.
   * @param dimensions The dimensions values.
   * @returns An array of matching quotes.
   */
  private async findQuoteInPricelists(tarif: any, item: any): Promise<any[]> {
    if (!tarif?.pricelists) return [];

    const activePricelists = tarif.pricelists.filter(
      pricelist => !pricelist.deactivated && pricelist.dimensions,
    );

    const tarifPrices = await Promise.all(
      activePricelists.map(async pricelist => {
        const tarifDimensions = this.createTarifDimensions(
          pricelist.dimensions,
        );
        const pricelistDimensions = await this.getDimensionValues(
          item,
          pricelist.dimensions,
        );

        if (
          !pricelistDimensions.length ||
          !pricelist.prices ||
          Object.keys(tarifDimensions)?.length !== pricelistDimensions?.length
        )
          return null;

        const foundPrices = pricelist.prices.filter(price =>
          this.isPriceMatchingDimensions(
            price,
            pricelistDimensions,
            tarifDimensions,
          ),
        );

        if (foundPrices.length === 0) {
          return null;
        }
        return {
          pricelist: pricelist.name,
          prices: foundPrices[0],
          pricelistDimensions,
        };
      }),
    );
    return tarifPrices.filter(Boolean);
  }

  private createTarifDimensions(dimensions: any[]): Record<string, number> {
    const offset = 5; // columns offset for quote fields (fixPrice, minPrice, pricePerUnit, unit, percents)
    return Object.fromEntries(
      dimensions.map((dimension, idx) => [dimension.name, idx + offset]),
    );
  }

  private isPriceMatchingDimensions(
    price: any,
    pricelistDimensions: any[],
    tarifDimensions: Record<string, number>,
  ): boolean {
    return pricelistDimensions.every(([dimensionName, dimensionValue]) => {
      const dimensionIdx = tarifDimensions[dimensionName];
      const priceDimensionValue = price[dimensionIdx];

      if (this.isRange(price, dimensionIdx)) {
        const [min, max] = priceDimensionValue.split(' - ').map(parseFloat);
        return dimensionValue > min && dimensionValue <= max;
      }

      const result =
        dimensionValue?.toString() === priceDimensionValue?.toString();
      if (!result) {
        if (dimensionValue === null && priceDimensionValue === '') {
          return true;
        }
      }
      return result;
    });
  }

  /**
   * Determines if a price field represents a range.
   * @param price The price entry.
   * @param idxDimension The index of the dimension in the price entry.
   * @returns True if the field is a range, false otherwise.
   */
  private isRange(price: any, idxDimension: number): boolean {
    if (price[idxDimension] && price[idxDimension].indexOf(' - ') > 0) {
      const range = price[idxDimension].split(' - ');
      if (parseFloat(range[0]) >= 0 && parseFloat(range[1]) >= 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  /**
   * Calculates the price based on the quote and item.
   * @param quote The price quote.
   * @param item The item being priced.
   * @param isSupplier Indicates if the price is for the supplier.
   * @param isCustomerTarif Indicates if the price is from the customer tariff.
   * @returns The calculated price.
   */
  private getPrice(
    { pricelist, prices: quote },
    item: any,
    isSupplier?: boolean,
    isCustomerTarif?: boolean,
  ): number {
    let fixPrice = this.parseValue(quote[0]);
    const minPrice = this.parseValue(quote[1]);
    const pricePerUnit = this.parseValue(quote[2]);
    const unit = this.parseValue(quote[3]);
    let percents = this.parseValue(quote[4]);
    if (percents < 1) {
      percents = 100 * percents;
    }

    if (isCustomerTarif) {
      item['dealCurrency'] = '';
    }
    if (percents >= 0) {
      if (isCustomerTarif) {
        item['dealCurrency'] = 'EUR';
      }
      return (1.0 - percents / 100) * item['price'];
    }
    if (isNaN(fixPrice)) {
      fixPrice = 0;
    }
    let result = fixPrice;
    if (pricePerUnit && pricePerUnit > 0 && unit && unit > 0) {
      let weight = isSupplier
        ? item['weightOrig'] || item['weight']
        : item['weight'];

      const range = quote.find((val: any, index: number) =>
        this.isRange(quote, index),
      );
      if (range) {
        weight -= parseFloat(range.split(' - ')[0]);
      }
      const quantity =
        item['product'] !== 'Топливная надбавка' &&
        item['product'] !== 'Надбавка за безопасность'
          ? Math.ceil(weight / unit)
          : weight / unit;
      result = fixPrice + quantity * pricePerUnit;
    }
    if (!isNaN(minPrice)) {
      return Math.max(minPrice, result);
    }
    return result;
  }

  /**
   * Parses a string value into a number, handling commas as decimal points.
   * @param value The string value to parse.
   * @returns The parsed number.
   */
  private parseValue(value: string): number {
    value = value?.replace(',', '.');
    return Number(parseFloat(value));
  }

  /**
   * Rounds a number to two decimal places.
   * @param value The number to round.
   * @returns The rounded number.
   */
  private roundValue(value: number): number {
    return Number(parseFloat(value?.toString() || '0').toFixed(2));
  }

  /**
   * Finds an existing service by product name or creates a new one.
   * @param item The item containing services.
   * @param productName The product name of the service.
   * @returns The found or created service.
   */
  private findOrCreateService(item: any, productName: string) {
    let result = item['services'].find(
      service => service['product'] === productName,
    );

    if (!result) {
      result = {
        product: productName,
        weight: item['weight'],
      };
      item['services'].push(result);
    }

    result['mdl2eur'] = item['mdl2eur'];
    return result;
  }

  /**
   * Resets prices for the item and its services.
   * @param item The item to reset prices for.
   */
  private resetPrices(item: any) {
    if (!item['manualPrice']) {
      this.resetFields(item, this.manualPriceFields);
    }

    this.resetFields(item, this.fields);
    if (item['services']) {
      item['services'].forEach(service => {
        if (!service['manualPrice']) {
          this.resetFields(service, this.manualPriceFields);
        }
        this.resetFields(service, this.fields);
      });
    }
  }

  /**
   * Resets specified fields on the item to zero.
   * @param item The item to reset fields for.
   * @param fields The list of fields to reset.
   */
  private resetFields(item: any, fields: string[]) {
    fields.forEach(field => (item[field] = 0));
  }

  /**
   * Rounds prices for the item and its services.
   * @param item The item to round prices for.
   */
  private roundPrices(item: any) {
    if (item['services']) {
      item['services'].forEach(service => {
        this.manualPriceFields.forEach(
          field => (service[field] = this.roundValue(service[field])),
        );
        this.fields.forEach(
          field => (service[field] = this.roundValue(service[field])),
        );
      });
    }
    this.manualPriceFields.forEach(
      field => (item[field] = this.roundValue(item[field])),
    );
    this.fields.forEach(field => (item[field] = this.roundValue(item[field])));
  }

  /**
   * Calculates the ISO week number for a given date.
   * @param date The date to calculate the week number for.
   * @returns The ISO week number.
   */
  private getWeekNumber(date: Date): number {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
    );
    return weekNo;
  }
}
