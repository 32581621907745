// TODO: Customer in issues and contracts

export const filterFieldsDef = {
  tariffs: [
    { label: 'Name', value: 'name' },
    { label: 'Description', value: 'description' },
  ],
  issues: [
    { label: 'Status', value: 'status' },
    {
      label: 'Customer',
      value: 'account',
      isArray: false,
      joinTable: 'accounts',
      joinCondition: 'accounts.id = maintable.account',
    },
  ],
  contracts: [
    { label: 'Status', value: 'status' },
    {
      label: 'Customer',
      value: 'account',
      isArray: false,
      joinTable: 'accounts',
      joinCondition: 'accounts.id = maintable.accountId',
      description: 'Filter by customer',
    },
  ],

  accounts: [
    { label: 'Account Status', value: 'accountStatus' },
    { label: 'Specialities', value: 'specialities', isArray: true },
    { label: 'Employee Count Range', value: 'employeeCountRange' },
    { label: 'Founded On', value: 'foundedOn' },
    { label: 'Category Current Q', value: 'categoryCurrentQ' },
    { label: 'Category Last Q', value: 'categoryLastQ' },
    { label: 'Category Difference', value: 'categoryDiffToLastQ' },
  ],
  contacts: [
    { label: 'Role', value: 'role', isArray: false },
    { label: 'Target Region', value: 'targetRegion', isArray: true },
    { label: 'Tags', value: 'cluster', isArray: true },
    { label: 'Skills', value: 'skills', isArray: true },
    { label: 'Languages', value: 'languageSkills', isArray: true },
    { label: 'Industry Knowledge', value: 'industryKnowledge', isArray: true },
    { label: 'Department', value: 'department' },
    { label: 'Personal Impression', value: 'personalImpression' },
    { label: 'Professional Impression', value: 'professionalImpression' },
    { label: 'Salutation', value: 'salutation' },

    { label: 'Title', value: 'title' },
    {
      label: 'Customer',
      value: 'customer',
      isArray: false,
      joinTable: 'accounts',
      joinCondition: 'accounts.id = maintable.customer',
    },
  ],
  deals: [
    {
      label: 'Customer',
      value: 'customer',
      isArray: false,
      joinTable: 'accounts',
      joinCondition: 'accounts.id = maintable.customer',
    },
    { label: 'State', value: 'state' },
    { label: 'Form', value: 'form' },
    { label: 'Role', value: 'role' },
  ],
  invoices: [
    {
      label: 'Status',
      type: 'keyword',
      value: 'status',
      isArray: false,
      description: 'Filter by invoice status',
    },
    {
      label: 'Customer',
      value: 'customer',
      isArray: false,
      joinTable: 'accounts',
      joinCondition: 'accounts.id = maintable.customer',
      description: 'Filter by customer',
    },
    { label: 'Tags', value: 'tags', isArray: true },
    { label: 'Delivery', value: 'delivery', isArray: false },
  ],
};

/*
/**
 * PostgreSQL Operator Abbreviations and Their Meanings:
 * 
 * - eq: "=" (equals)
 * - gt: ">" (greater than)
 * - gte: ">=" (greater than or equal)
 * - lt: "<" (less than)
 * - lte: "<=" (less than or equal)
 * - neq: "<>" or "!=" (not equal)
 * - like: "LIKE" (LIKE operator, use "*" as an alias for "%")
 * - ilike: "ILIKE" (ILIKE operator, use "*" as an alias for "%")
 * - match: "~" (~ operator for pattern matching)
 * - imatch: "~*" (~* operator for case-insensitive pattern matching)
 * - in: "IN" (one of a list of values, e.g., '?a=in.(1,2,3)')
 * - is: "IS" (checking for exact equality, supports null, true, false, unknown)
 * - isdistinct: "IS DISTINCT FROM" (not equal, treating NULL as a comparable value)
 * - fts: "@@" (Full-Text Search using to_tsquery)
 * - plfts: "@@" (Full-Text Search using plainto_tsquery)
 * - phfts: "@@" (Full-Text Search using phraseto_tsquery)
 * - wfts: "@@" (Full-Text Search using websearch_to_tsquery)
 * - cs: "@>" (contains, e.g., '?tags=cs.{example,new}')
 * - cd: "<@" (contained in, e.g., '?values=cd.{1,2,3}')
 * - ov: "&&" (overlap, supports array types with curly braces, e.g., '?arr=ov.{1,3}')
 * - sl: "<<" (strictly left of)
 * - sr: ">>" (strictly right of)
 * - nxr: "&<" (does not extend to the right of)
 * - nxl: "&>" (does not extend to the left of)
 * - adj: "-|-" (is adjacent to)
 * - not: "NOT" (negates another operator)
 * - or: "OR" (logical OR)
 * - and: "AND" (logical AND)
 * - all: "ALL" (comparison matches all values in a list)
 * - any: "ANY" (comparison matches any value in a list)
 */

export const operators = [
  { label: 'Equals', value: 'eq' },
  { label: 'Not equals', value: 'neq' },
  { label: 'Greater than', value: 'gt' },
  { label: 'Greater or equal than', value: 'gte' },
  { label: 'Less than', value: 'lt' },
  { label: 'Less or equal than', value: 'lte' },
  { label: 'Matches', value: 'ilike' },

  { label: 'is', value: 'is' },
  /* Match only rows where column is included in the values array. */

  { label: 'in', value: 'in' },
  /*Only relevant for jsonb, array, and range columns*/
  { label: 'contains', value: 'cs' },
  /* Contained by value */
  { label: 'contained by', value: 'containedBy' },
  /* Greater than a range */
  { label: 'range >', value: 'rangeGt' },
  /* Greater than or equal to a range     */
  { label: 'range >=', value: 'rangeGte' },
  /* Less than a range */
  { label: 'range <', value: 'rangeLt' },
  /* Less than or equal to a range */
  { label: 'range <=', value: 'rangeLte' },
  /* Mutually exclusive to a range */
  { label: 'range <>', value: 'rangeAdjacent' },
  /* With a common element */
  { label: 'overlaps', value: 'ov' },
  /* Match a string */
  { label: 'match', value: 'textSearch' },
  /* Match at least one filter */
  { label: 'or', value: 'or' },
];
