<script lang="ts">
  import { slide } from 'svelte/transition';
  import * as Form from '$lib/components/ui/form';
  import {
    type Account,
    accountsSchemaWithSales,
    type AccountWithSales,
  } from '$db/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveAccount } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import { Button } from '$lib/components/ui/button';
  import HantaInputAddress from '../input/hanta-input-address.svelte';
  import HantaInputText from '../input/hanta-input-text.svelte';
  import HantaInputNumber from '../input/hanta-input-number.svelte';
  import HantaInputTextarea from '../input/hanta-input-textarea.svelte';
  import HantaInputCommunication from '../input/hanta-input-communication.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';

  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import {
    Building2,
    AtSign,
    Globe,
    Briefcase,
    Users,
    Banknote,
    FileText,
    Linkedin,
    Link,
    MapPin,
    Phone,
    Mail,
    Calendar,
    AlertCircle,
    ThumbsUp,
    UserX,
    MessageSquare,
    Users as UsersIcon,
    Map,
    Compass,
    Package,
    Clock,
    BarChart3,
    Tag,
    CreditCard,
    Wallet,
    PiggyBank,
    Scale,
    AlertTriangle,
  } from 'lucide-svelte';
  import CrmObjectSelector from '../crm-object-selector.svelte';
  import HantaInputCheckbox from '../input/hanta-input-checkbox.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import HantaInputDate from '../input/hanta-input-date.svelte';

  export let crmAccount;
  export let type = 'about';
  export let onUpdateCrmAccount = newAccount => {};

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Account> =>
      Promise.resolve(saveAccount(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['accounts'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['accounts', crmAccount.id] });
      const previousItems = client.getQueryData<Account[]>([
        'accounts',
        crmAccount.id,
      ]);

      client.setQueryData(['accounts', crmAccount.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['accounts'], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['accounts', crmAccount.id] });
    },
  });

  let initAccount = {
    ...crmAccount,
    address: crmAccount.address ?? {},
    communication: crmAccount.communication ?? {},
  };

  const form = superForm<AccountWithSales>(initAccount, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(accountsSchemaWithSales),

    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.log('Form data', $formData);
      $addMutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
        },
        onSuccess: account => {
          onUpdateCrmAccount(account);
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;
</script>

<form class="space-y-6 w-full" method="POST" use:enhance>
  {#if type === 'about'}
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Building2 class="mr-2" /> Company Details</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-4">
        <HantaInputText
          {form}
          name="refId"
          label="Id"
          readOnly={true}
          icon={FileText}
        />
        <HantaInputText {form} name="name" label="Name" icon={Building2} />

        <HantaInputLovSelect
          {form}
          bind:value={$formData.accountStatus}
          label="Status"
          type="Account > Status"
          multiple={false}
        />

        <HantaInputNumber
          {form}
          name="foundedOn"
          label="Founded Date"
          icon={Calendar}
        />
        <HantaInputText {form} name="tagline" label="Tagline" icon={FileText} />
        <HantaInputTextarea
          {form}
          name="philosophy"
          label="Philosophy"
          icon={FileText}
        />
        <HantaInputText {form} name="websiteUrl" label="Website" icon={Globe} />
        <HantaInputText
          {form}
          name="officialName"
          label="Official name"
          icon={Building2}
        />
        <HantaInputNumber
          {form}
          name="revenue"
          label="Revenue"
          icon={Banknote}
        />
        <HantaInputNumber
          {form}
          name="employeeCount"
          label="Employees Count"
          icon={Users}
        />
        <HantaInputNumber
          {form}
          name="employees"
          label="Employees"
          icon={Users}
        />
        <HantaInputText
          {form}
          name="employeeCountRange"
          label="Employees Range"
          icon={Users}
        />
        <HantaInputText
          {form}
          name="industry"
          label="Industry"
          icon={Briefcase}
        />
        <HantaInputTextarea
          {form}
          name="description"
          label="Description"
          icon={FileText}
        />
        <HantaInputText
          {form}
          name="linkedin"
          label="LinkedIn"
          icon={Linkedin}
        />
        <HantaInputText {form} name="url" label="URL" icon={Link} />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Phone class="mr-2" /> Contact Information</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputCommunication
          {form}
          name="communication"
          label="Contact"
          types={['phone', 'email']}
        />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><MapPin class="mr-2" /> Address</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputAddress {form} name="address" label="Address" />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><MapPin class="mr-2" /> Official Address</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputAddress
          {form}
          name="officialAddress"
          label="Official address"
        />
      </CardContent>
    </Card>
  {:else if type === 'analysis'}
    <div class="grid grid-cols-2 gap-4">
      <Card>
        <CardHeader>
          <CardTitle class="flex items-center"
            ><FileText class="mr-2" /> Analysis</CardTitle
          >
        </CardHeader>
        <CardContent class="space-y-4">
          <UsersSelector
            bind:value={$formData.sales}
            label="Sales"
            multiple={false}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.region}
            name="region"
            type="Account > Region"
            label="Region"
            multiple={false}
            icon={Briefcase}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.district}
            name="district"
            type="Account > District"
            label="District"
            multiple={false}
            icon={Briefcase}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.customerType}
            name="customerType"
            type="Account > Customer Type"
            label="Customer Type"
            multiple={false}
            icon={Briefcase}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.industry}
            type="Account > Industry"
            label="Industry"
            multiple={false}
            icon={Briefcase}
          />

          <HantaInputCheckbox
            {form}
            name="potentialCustomer"
            label="Potential Customer"
          />

          <HantaInputText
            {form}
            name="statusComment"
            label="Status Comment"
            icon={MessageSquare}
          />
          <HantaInputText
            {form}
            name="reasonOfUnsatisfaction"
            label="Reason of unsatisfaction"
            icon={AlertCircle}
          />
          <HantaInputText
            {form}
            name="satisfaction"
            label="Satisfaction"
            icon={ThumbsUp}
          />
          <!--
          <HantaInputText
            {form}
            name="lostToCompetitor"
            label="Lost to competitor"
            icon={UserX}
          />
          -->
          <HantaInputText
            {form}
            name="lostReason"
            label="Lost reason"
            icon={AlertCircle}
          />
          <HantaInputText
            {form}
            name="commentToDecision"
            label="Comment to decision"
            icon={MessageSquare}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.competitors}
            type="Account > Competitors"
            label="Competitors"
            multiple={true}
            icon={UsersIcon}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.phase}
            type="Account > Phase"
            label="Phase"
            multiple={false}
            icon={BarChart3}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle class="flex items-center"
            ><BarChart3 class="mr-2" /> Potential</CardTitle
          >
        </CardHeader>
        <CardContent class="space-y-4">
          <HantaInputLovSelect
            {form}
            bind:value={$formData.interestZone}
            type="Countries"
            label="Interest Zone"
            multiple={true}
            icon={Map}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.direction}
            type="Account > Direction"
            label="Direction"
            multiple={true}
            icon={Compass}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.products}
            type="Account > Product"
            label="Products"
            multiple={true}
            icon={Package}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.serviceUsageFrequency}
            type="Account > Service Usage Frequency"
            label="Service Usage Frequency"
            multiple={false}
            icon={Clock}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.expectedVolume}
            type="Account > Expected Volume"
            label="Expected Volume"
            multiple={false}
            icon={BarChart3}
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.expectedCategory}
            type="Account > Category"
            label="Expected Category"
            multiple={false}
            icon={Tag}
          />
        </CardContent>
      </Card>
    </div>
  {:else if type === 'financial'}
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Wallet class="mr-2" /> Financial Information</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-4">
        <HantaInputText
          {form}
          name="fedExICPCAccount"
          label="FedEx ICPC Account"
          icon={CreditCard}
        />
        <HantaInputText
          {form}
          name="tntICPCAccount"
          label="TNT ICPC Account"
          icon={CreditCard}
        />
        <HantaInputNumber
          {form}
          name="paymentDelayInDays"
          label="Payment Delay (days)"
          icon={Clock}
        />

        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.baseTariffObj}
          label="Base tariff"
        />
        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.customerTariffObj}
          label="Customer tariff"
        />
        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.supplierTariffObj}
          label="Supplier tariff"
        />

        <HantaInputNumber
          {form}
          name="maxDebt"
          label="Max Debt"
          icon={AlertTriangle}
        />
        <HantaInputNumber
          {form}
          name="currentCreditLimit"
          label="Current Credit Limit"
          readonly={true}
          icon={CreditCard}
        />
        <HantaInputNumber
          {form}
          name="currentBalance"
          label="Current Balance"
          readonly={true}
          icon={PiggyBank}
        />
        <HantaInputDate
          {form}
          name="currentBalanceDate"
          label="Balance Updated On"
          readOnly={true}
        />
        <HantaInputLovSelect
          {form}
          bind:value={$formData.creditRating}
          type="Account > Credit Rating"
          label="Credit Rating"
          multiple={false}
          icon={Scale}
        />
        <HantaInputLovSelect
          {form}
          bind:value={$formData.debtReason}
          type="Account > Debt Reason"
          label="Debt Reason"
          multiple={false}
          icon={AlertTriangle}
        />
      </CardContent>
    </Card>
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Building2 class="mr-2" /> Bank Details</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-2">
        <HantaInputText
          {form}
          name="companyName"
          label="Company Name"
          icon={Building2}
        />
        <HantaInputText {form} name="iban" label="IBAN" icon={CreditCard} />
        <HantaInputText {form} name="bic" label="BIC" icon={CreditCard} />
        <HantaInputText {form} name="bank" label="Bank" icon={Building2} />
        <HantaInputText {form} name="tva" label="TVA" icon={FileText} />
      </CardContent>
    </Card>
  {/if}

  <FormActions {form} />
</form>
