<script lang="ts">
  import { saveOrder } from '$lib/api/mutations';
  import AddOrderModal from './invoice-positions-add-order-modal.svelte';
  import { Button } from '$lib/components/ui/button/index';
  import { dndzone, type DndEvent, SOURCES, TRIGGERS } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import { generateUUID } from '$lib/utils/browser-utils';
  import { Copy, Trash, PlusIcon, Pencil } from 'lucide-svelte';

  import { X } from 'lucide-svelte';

  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputCurrency from '$lib/widgets/input/hanta-input-currency.svelte';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import {
    calculatePosition,
    calculateSum,
  } from '$lib/pages/invoices/invoice-utils';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { currencyFormatter } from '$lib/app/utils';
  import { popupStore } from '$lib/app-store';

  export let positions = [];
  export let orders = [];
  export let isLocked = false;
  export let form;
  export let formData;
  export let errors;
  export let flipDurationMs = 300;
  export let isDragging = false;

  let selectedPositionIdx;

  let showOrderModal = false;
  function openOrderModal() {
    showOrderModal = true;
  }

  function closeOrderModal() {
    showOrderModal = false;
  }

  async function removeOrder(order) {
    const orderToSave = JSON.parse(JSON.stringify(order));
    orderToSave.status = 'PROCESSING';
    orderToSave.invoiceId = null;
    await saveOrder(orderToSave);
    form.submit();
  }

  const format: (value: number) => string = currencyFormatter().format;
  let total = tweened(0, { duration: 500, easing: cubicOut });

  function transformDraggedElement(el: HTMLElement, index) {
    const idx = el?.querySelector('#hanta-index');
    if (idx) {
      idx.innerHTML = index + 1 + '.';
    }
  }

  function handleConsider(e) {
    const {
      items: newItems,
      info: { trigger, source },
    } = e.detail;

    if (source === SOURCES.KEYBOARD && trigger === TRIGGERS.DRAG_STOPPED) {
      isDragging = false;
    }

    positions = newItems;
    $formData.positions = positions;
  }

  async function handleFinalize(e: CustomEvent<DndEvent<RowType>>) {
    let { items: newItems } = e.detail;
    isDragging = false;

    positions = newItems;
    $formData.positions = newItems;
  }

  function handleMaybeSelect(id, e) {
    isDragging = true;
  }

  const addPosition = async () => {
    formData.update((data: any) => {
      if (!data.positions) data.positions = [];
      data.positions.push({
        id: generateUUID(),
        text: '',
        unit: 'Stunden',
        quantity: 1,
        price: 0,
        tax: 19,
        sum: 0,
      });
      return data;
    });

    isDragging = false;
    positions = $formData.positions;
    selectedPositionIdx = positions.length - 1;
    showOrderModal = true;
  };

  const removePositionAtIdx = idx => {
    positions = positions.filter((_, i) => i !== idx);
    $formData.positions = positions;
  };

  const editPositionAtIdx = idx => {
    selectedPositionIdx = idx;
    showOrderModal = true;
  };

  const clonePositionFromIdx = async idx => {
    const clone = JSON.parse(JSON.stringify(positions[idx]));
    clone.id = generateUUID();

    positions = [
      ...positions.slice(0, idx + 1),
      clone,
      ...positions.slice(idx + 1),
    ];

    $formData.positions = positions;
    selectedPositionIdx = idx;
    showOrderModal = true;
  };

  $: sum = calculateSum($formData);
  $: total.set(sum.sum);
</script>

<div class="flex overflow-scroll flex-col gap-2 text-left">
  {#if orders.length > 0}
    <div class="my-6">
      <h2 class="px-3 py-2 w-full font-bold">Orders</h2>
      <div class="flex flex-row gap-2 px-3">
        {#each orders || [] as order, idx (order.id || order.text)}
          <Button
            variant="outline"
            class="py-2 space-x-2 rounded-none cursor-pointer"
            on:click={() => {
              popupStore.openPopup('crmObject', {
                module: 'orders',
                id: order.id,
              });
            }}
          >
            <div>
              {order.name}
              {format(order.shipment?.totalEUR || 0)}
            </div>
          </Button>
          <Button
            variant="outline"
            size="icon"
            disabled={isLocked}
            on:click={() => !isLocked && removeOrder(order)}
          >
            <X class="size-4" />
          </Button>
        {/each}
      </div>
    </div>
  {/if}
  <div class="flex flex-row gap-2 px-3 py-2 w-full font-bold">
    <div class="border-r border-solid min-w-4"></div>
    <div class="w-full border-r">Description</div>
    <div class="flex flex-row gap-2">
      <div class="border-r border-solid min-w-28 shrink-0 grow-0">Unit</div>
      <div class="border-r border-solid min-w-12 shrink-0 grow-0">Qty.</div>
      <div class="border-r border-solid min-w-16 shrink-0 grow-0">
        Unit price
      </div>
      <div class="border-r border-solid min-w-20 shrink-0 grow-0">Tax</div>
      <div class="font-bold text-right border-r min-w-20 shrink-0 grow-0">
        Amount
      </div>
    </div>
  </div>

  <section
    class="flex flex-col gap-1.5 w-full section"
    on:consider={handleConsider}
    on:finalize={handleFinalize}
    use:dndzone={{
      dragDisabled: positions?.length === 0 || isLocked,
      items: positions,
      flipDurationMs,
      transformDraggedElement,
    }}
  >
    {#if positions?.length === 0}
      <div
        class="flex flex-row w-full p-4 gap-2 bg-primary-800 rounded border border-solid border-primary/20 shadow content-stretch !leading-normal"
      >
        <div class="w-full text-center">
          {#if $errors?.positions?._errors?.length > 0}
            <span class="text-red-500"
              >{$errors?.positions?._errors?.join(', ')}</span
            >
          {:else}
            No positions added yet
          {/if}
        </div>
      </div>
    {/if}
    {#each positions || [] as position, idx (position.id || position.text)}
      <div
        role="button"
        tabindex="0"
        on:mousedown={e => handleMaybeSelect(position.id, e)}
        on:keydown={e => handleMaybeSelect(position.id, e)}
        animate:flip={{ duration: flipDurationMs }}
        class="hover:bg-muted group w-full p-0 bg-primary-800 rounded border border-solid border-primary/20 shadow !leading-normal"
      >
        <div
          class="flex relative flex-row gap-2 px-3 py-2 w-full content-stretch"
        >
          {#key idx}
            <div id="hanta-index" class="font-bold min-w-4">
              {idx + 1}.

              <div
                class="absolute top-7 left-0 flex flex-col items-center w-4 gap-4 p-4 font-bold transition-opacity bg-primary-800 shadow opacity-0 shrink-0 grow-0 {!isLocked
                  ? 'group-hover:opacity-100'
                  : ''} bg-opacity-90"
              >
                <button
                  on:click={e => {
                    e.preventDefault();
                    clonePositionFromIdx(idx);
                  }}
                  class="text-gray-400 hover:text-muted-foreground"
                >
                  <Copy class="size-4" />
                </button>
                <button
                  on:click={e => {
                    e.preventDefault();
                    editPositionAtIdx(idx);
                  }}
                  class="text-blue-400 hover:text-muted-foreground"
                >
                  <Pencil class="size-4" />
                </button>
                <button
                  on:click={e => {
                    e.preventDefault();
                    removePositionAtIdx(idx);
                  }}
                  class="text-red-400 hover:text-muted-foreground"
                >
                  <Trash class="size-4" />
                </button>
              </div>
            </div>
            <div class="w-full !leading-1">
              <HantaInputTextArea
                readOnly={true}
                {form}
                rows={6}
                name="positions[{idx}].text"
                placeholder="Enter position description..."
              />
              {#if $errors?.positions && $errors?.positions[idx]?.text}
                <span class="text-red-500"
                  >{$errors?.positions && $errors?.positions[idx]?.text}</span
                >
              {/if}
            </div>
            <div class="flex flex-row gap-2 items-baseline self-start">
              <div class="w-28 shrink-0 grow-0">
                <HantaSelect
                  readOnly={true}
                  searchable={false}
                  bind:value={$formData.positions[idx].unit}
                  options={[
                    { label: 'Stunden', value: 'Stunden' },
                    { label: 'pauschal', value: 'pauschal' },
                    { label: 'Stück', value: 'Stück' },
                  ]}
                />
              </div>
              <div class="w-12 shrink-0 grow-0">
                {#if $formData.positions[idx].unit !== 'pauschal'}
                  <HantaInputNumber
                    readOnly={true}
                    asFloat={true}
                    {form}
                    name="positions[{idx}].quantity"
                  />
                {/if}
              </div>
              <div class="w-16 shrink-0 grow-0">
                <HantaInputCurrency
                  {form}
                  name="positions[{idx}].price"
                  readOnly={true}
                />
              </div>
              <div class="w-20 shrink-0 grow-0">
                <HantaInputSelect
                  readOnly={true}
                  asInt={true}
                  {form}
                  name="positions[{idx}].tax"
                  options={[
                    { label: '0%', value: '0' },
                    { label: '7%', value: '7' },
                    { label: '19%', value: '19' },
                  ]}
                />
              </div>
              <div class="w-20 text-sm font-medium text-right shrink-0 grow-0">
                {format(calculatePosition($formData.positions[idx]).net)}
              </div>
            </div>
          {/key}
        </div>
      </div>
    {/each}
  </section>
  {#if !isLocked}
    <div class="flex flex-row mt-2">
      <Button on:click={() => addPosition()}>
        <PlusIcon class="mr-2 size-4" />
        Add
      </Button>

      <div class="flex flex-col justify-center items-end w-full">
        <div>Net {format(sum.sum - sum.tax)}</div>
        <span class="font-normal text-nowrap">VAT {format(sum.tax)}</span>
        <div class="text-lg font-bold underline">{format(sum.sum)}</div>
      </div>
    </div>
  {/if}
</div>

{#if showOrderModal}
  <AddOrderModal
    {form}
    formData={$formData}
    positionIdx={selectedPositionIdx}
    orderType={'one time'}
    on:close={closeOrderModal}
    on:save={() => alert('save')}
  />
{/if}
