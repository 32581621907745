<script lang="ts">
  import Item from './item.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import { type Deal, insertDealsSchema } from '$db/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveDeal } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import { Button } from '$lib/components/ui/button';
  import PipelineSteps from './pipeline-steps.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  export let item: any;
  let phases = [
    { id: self.crypto.randomUUID(), name: 'In Vorbereitung' },
    { id: self.crypto.randomUUID(), name: 'Kontaktiert' },
    { id: self.crypto.randomUUID(), name: 'TI/VC in Vorbereitung' },
    { id: self.crypto.randomUUID(), name: 'Telefon Interview 1' },
    { id: self.crypto.randomUUID(), name: 'VideoCall 1' },
    { id: self.crypto.randomUUID(), name: 'Im Austausch' },
    { id: self.crypto.randomUUID(), name: 'Persönliches Interview FULL & BYE' },
    { id: self.crypto.randomUUID(), name: 'Präsentation in Vorbereitung' },
    { id: self.crypto.randomUUID(), name: 'Präsentiert' },
    { id: self.crypto.randomUUID(), name: 'Telefoninterview Kunde' },
    { id: self.crypto.randomUUID(), name: 'VideoCall Kunde' },
    { id: self.crypto.randomUUID(), name: 'Persönliches Interview 1 Kunde' },
    { id: self.crypto.randomUUID(), name: 'Persönliches Interview 2 Kunde' },
    { id: self.crypto.randomUUID(), name: 'Finale Interviews Kunde' },
    { id: self.crypto.randomUUID(), name: 'Vertragsverhandlung' },
    { id: self.crypto.randomUUID(), name: 'Vertrag' },
    { id: self.crypto.randomUUID(), name: 'Absage' },
  ];

  type Deals = {
    data: readonly Deal[];
  };

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Deal> =>
      Promise.resolve(saveDeal(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['deals'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['deals', item.id] });
      const previousItems = client.getQueryData<Deals>(['deals', item.id]);

      client.setQueryData(['deals', item.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['deals'], context.previousItems);
      }
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['deals', item.id] });
    },
  });

  const formSchema = insertDealsSchema
    .pick({ id: true, name: true, accounts: true })
    .partial();

  function createNewForm(newDeal) {
    return superForm<Deal>(newDeal, {
      resetForm: false,
      SPA: true,
      dataType: 'json',
      validators: zodClient(formSchema),
      onSubmit: async () => {
        if (Object.keys($errors).length > 0) {
          console.error('Validation errors', $errors, { form: $formData });
          $errors = {};
          return;
        }

        console.log('Form data', $formData);

        let parse = insertDealsSchema.parse($formData);
        $addMutation.mutate(parse, {
          onError(error) {
            console.error('Error', error);
          },
          onSuccess: () => {
            //Object.assign(item, newDeal);
            // form.reset();
          },
        });
      },
    });
  }
  let form = createNewForm(item);

  let { form: formData, enhance, errors } = form;

  $: if (item) {
    form = createNewForm(item);

    formData = form.form;
    enhance = form.enhance;
    errors = form.errors;
  }

  function onDrop(newItems) {
    phases = newItems;
  }
</script>

<form class="overflow-scroll relative pb-64 w-full" method="POST" use:enhance>
  <div>
    <div class="flex flex-col px-4 py-12 m-2 rounded">
      <HantaInputText {form} name="name" label="Name" />

      <PipelineSteps itemsData={phases} itemComponent={Item} {onDrop} />
      <Button
        class="w-48"
        variant="default"
        on:click={() => {
          phases = [
            ...phases,
            { id: self.crypto.randomUUID(), name: 'New phase' },
          ];
        }}>Add phase</Button
      >
    </div>

    <FormActions {form} />
  </div>
</form>
