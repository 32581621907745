<script lang="ts">
  import { Button, buttonVariants } from '$lib/components/ui/button';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Label } from '$lib/components/ui/label';
  import * as RadioGroup from '$lib/components/ui/radio-group';
  import { Dialog as DialogPrimitive } from 'bits-ui';
  import { updateInvoiceStatus } from '$lib/api/invoice-api';
  import { Badge } from '$lib/components/ui/badge';

  export let id;
  let status = undefined;
  export let sum = 0;
</script>

<Dialog.Root>
  <Dialog.Trigger class={buttonVariants({ variant: 'outline' })}
    >Change status</Dialog.Trigger
  >
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>Change invoice status</Dialog.Title>
      <Dialog.Description>Mark invoice as…</Dialog.Description>
    </Dialog.Header>
    <div class="grid gap-4 py-4">
      <RadioGroup.Root bind:value={status}>
        <div class="flex items-center mt-2 space-x-2">
          <RadioGroup.Item value="Paid" id="r1" />
          <Label for="r1" class="text-base text-foreground"
            >Paid

            <Badge variant="outline" class="rounded-none">
              <div>
                {new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(sum)}
              </div>
            </Badge>
          </Label>
        </div>
        <div class="ml-6 text-sm text-muted-foreground">
          Payment was collected.
        </div>
        <div class="flex items-center mt-2 space-x-2">
          <RadioGroup.Item value="Cancelled" id="r2" />
          <Label for="r2" class="text-base text-foreground">Cancelled</Label>
        </div>
        <div class="ml-6 text-sm text-muted-foreground">
          This invoice was accidentally finalized or contains a mistake.
        </div>
        <div class="flex items-center mt-2 space-x-2">
          <RadioGroup.Item value="Uncollectable" id="r3" />
          <Label for="r3" class="text-base text-foreground">Uncollectable</Label
          >
        </div>
        <div class="ml-6 text-sm text-muted-foreground">
          Payment of this invoice is not expected
        </div>
        <RadioGroup.Input name="spacing" />
      </RadioGroup.Root>
      <!--div class="space-y-2">
        <Label for="name" class="text-right">Note</Label>
        <Textarea id="name" value="" class="col-span-3" />
      </div-->
    </div>
    <Dialog.Footer>
      <DialogPrimitive.Close>
        <Button variant="ghost">Cancel</Button>
      </DialogPrimitive.Close>
      <DialogPrimitive.Close>
        <Button
          disabled={!status || !id}
          on:click={async () => {
            await updateInvoiceStatus(id, status);
          }}>Update status</Button
        >
      </DialogPrimitive.Close>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
