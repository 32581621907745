<script lang="ts">
  import { popupStore } from '$lib/app-store';

  import { findAccountOrders } from '$lib/api/queries';
  import Button from '$lib/components/ui/button/button.svelte';
  export let accountId: string;

  let orders = [];

  $: if (accountId) {
    findAccountOrders(accountId).then(result => {
      orders = result;
    });
  }

  function openInPopup(order: any) {
    popupStore.openPopup('crmObject', { module: 'orders', id: order.id });
  }
</script>

{accountId}
<div class="flex flex-col gap-2">
  {#each orders || [] as order}
    <div class="flex flex-col gap-2">
      <div class="flex flex-row gap-2">
        <span>{order.name}</span>
        <span>{order.status}</span>
      </div>
      <Button variant="link" on:click={() => openInPopup(order)}>View</Button>
    </div>
  {/each}
</div>
