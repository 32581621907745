<script>
  import { Button } from '$lib/components/ui/button';
  import { onMount } from 'svelte';
  import { getItems } from '$lib/api/queries';

  import Spreadsheet from '$lib/components/spreadsheet.svelte';

  export let data = [];
  const lovType = 'countries';
  let options;

  onMount(async () => {
    const result = await getItems({
      collection: 'generic',
      from: 0,
      to: 1000,
      search: '',
      select: '*',
      filters: [
        {
          field: 'type',
          operator: 'eq',
          value: lovType,
          active: true,
        },
      ],
    });

    if (result.data) {
      options = result.data.map(el => el.name);
    }
  });

  $: columnHeaders = [
    { title: 'Country', type: 'dropdown', width: 300, source: options },
    { title: 'Zone TNT', width: 70 },
    { title: 'Zone TNT Eco', width: 70 },
    { title: 'Zone FedEx', width: 70 },
  ];
</script>

{#if options?.length}
  <Button
    variant="outline"
    class="mb-2"
    on:click={() => {
      data = [['Germany', -1, -1, -1], ...data];
    }}>Add Exception</Button
  >
  {#key data?.length}
    <Spreadsheet {columnHeaders} {data} />
  {/key}
{/if}
