<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import { Globe, Phone } from 'lucide-svelte';

  import { type ActivityWithRefs, type AccountWithRefs } from '$db/schema';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import { cn } from '$lib/utils';
  import { appStore } from '$lib/app-store';

  export let crmAccount: AccountWithRefs;
  export let activity: ActivityWithRefs;
</script>

<Avatar.Root>
  <Avatar.Image
    src={crmAccount?.logo}
    alt="Company Logo"
    class="object-scale-down rounded-none"
  />
  <Avatar.Fallback>{crmAccount?.name ?? ''}</Avatar.Fallback>
</Avatar.Root>

<div class="flex flex-col space-y-1">
  <div class="text-sm font-bold">
    {crmAccount?.name ?? ''}
  </div>
  {#if crmAccount?.phone}
    <div class="flex items-center space-x-2 text-xs">
      <Phone size="12" />
      <div>
        {crmAccount?.phone}
      </div>
    </div>
  {/if}
  {#if crmAccount?.websiteUrl}
    <div class="flex items-center space-x-2 text-xs">
      <Globe size="12" />
      <div>
        <a
          class="underline text-primary/80 hover:text-primary/80 visited:text-primary/80"
          href={crmAccount?.websiteUrl}
          target="_blank"
        >
          {crmAccount?.websiteUrl}
        </a>
      </div>
    </div>
  {/if}

  <div class={cn($appStore.isMobile ? 'hidden' : 'absolute right-2')}>
    <ActivityActionBar {activity} mode="collapsed" />
  </div>
</div>
