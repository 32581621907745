<script lang="ts">
  import { onMount } from 'svelte';
  import * as Command from '$lib/components/ui/command';
  import { cn } from '$lib/utils';
  import * as Icons from '$lib/app/icons';
  import { Plus } from 'lucide-svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import { appStore } from '$lib/app-store';
  import { createItem, createShipment } from '$lib/api/mutations';
  import { primaryRoutes } from '$lib/app/config';
  import NewItemForm from './new-item-form.svelte';

  let open = false;
  let insertMode = false;
  let module = '';

  onMount(() => {
    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        open = !open;
      }
    }

    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  });

  $: if (!open) {
    insertMode = false;
    module = '';
  }

  function create(moduleName: string) {
    insertMode = true;
    module = moduleName;
  }

  async function createNewItem(event) {
    const item = event.detail;
    try {
      const crmModule = module === 'candidates' ? 'contacts' : module;
      const data = await createItem(crmModule, item);

      if (module === 'leads' || module === 'orders') {
        await createShipment(data[0].id, module);
      }

      appStore.select({ id: data[0].id, module: crmModule });
      appStore.openPopup(true);
      open = false;
    } catch (error) {
      console.error(error);
    }
  }

  function flattenRoutes(routes) {
    return routes.reduce((acc, route) => {
      acc.push(route);
      if (route.children) {
        acc.push(...flattenRoutes(route.children));
      }
      return acc;
    }, []);
  }

  $: allRoutes = flattenRoutes(primaryRoutes);
  $: currentModuleRoutes = allRoutes.filter(
    route => route.module === $appStore.currentModule,
  );
</script>

<!--
class="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2
          md:bg-primary-100 md:rounded-b md:border md:border-solid md:border-primary/20 z-10 md:shadow-border md:shadow-md"
-->
<div
  class={cn(
    'group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2',
    $appStore.isCollapsed
      ? 'md:bg-primary-100 md:rounded-b md:border md:border-solid md:border-primary/20 z-10 md:shadow-border md:shadow-md'
      : '-mb-2 -ml-1',
  )}
>
  <nav
    class="tn-grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2"
  >
    <Tooltip.Root openDelay={0}>
      <Tooltip.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant={'ghost'}
          size="icon"
          class={cn(
            $appStore.isCollapsed
              ? 'md:size-9'
              : 'w-full flex justify-start space-x-4 px-2',
          )}
          on:click={() => (open = !open)}
        >
          <Plus class="size-5" />
          <span class:hidden={$appStore.isCollapsed}>Create new</span>
        </Button>
      </Tooltip.Trigger>

      <Tooltip.Content side="right">
        <div class="flex items-center ml-auto space-x-2">
          <span>Create item</span>
          <kbd
            class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100"
          >
            <span class="text-xs">⌘</span>K
          </kbd>
        </div>
      </Tooltip.Content>
    </Tooltip.Root>

    <Command.Dialog bind:open>
      {#if insertMode}
        <NewItemForm
          {module}
          on:submit={createNewItem}
          on:cancel={() => (open = false)}
        />
      {:else}
        <Command.Input
          placeholder="Select from list or type here for search..."
        />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>
          <Command.Group heading="Suggestions">
            {#if $appStore.currentModule && currentModuleRoutes.length > 0}
              {#each currentModuleRoutes as route}
                <Command.Item onSelect={() => create(route.module)}>
                  <svelte:component
                    this={route.icon}
                    class="mr-2 w-4 size-4"
                    aria-hidden="true"
                  />
                  <span>New record in <b>{route.title}</b></span>
                </Command.Item>
              {/each}
            {/if}
          </Command.Group>
          <Command.Group heading="CRM Objects">
            <Command.Item onSelect={() => create('accounts')}>
              <Icons.Building class="mr-2 w-4 h-4" />
              <span>Account</span>
            </Command.Item>
            <Command.Item onSelect={() => create('invoices')}>
              <Icons.Euro class="mr-2 w-4 h-4" />
              <span>Invoice</span>
            </Command.Item>
            <Command.Item onSelect={() => create('contracts')}>
              <Icons.Section class="mr-2 w-4 h-4" />
              <span>Contract</span>
            </Command.Item>
            <Command.Item onSelect={() => create('contacts')}>
              <Icons.Users class="mr-2 w-4 h-4" />
              <span>Contact</span>
            </Command.Item>
            <Command.Item onSelect={() => create('candidates')}>
              <Icons.Users class="mr-2 w-4 h-4" />
              <span>Candidate</span>
            </Command.Item>
            <Command.Item onSelect={() => create('deals')}>
              <Icons.Rocket class="mr-2 w-4 h-4" />
              <span>Deal</span>
            </Command.Item>
            <Command.Item onSelect={() => create('leads')}>
              <Icons.Package class="mr-2 w-4 h-4" />
              <span>Lead</span>
            </Command.Item>
            <Command.Item onSelect={() => create('orders')}>
              <Icons.Package class="mr-2 w-4 h-4" />
              <span>Order</span>
            </Command.Item>
            <Command.Item onSelect={() => create('issues')}>
              <Icons.Scale class="mr-2 w-4 h-4" />
              <span>Issue</span>
            </Command.Item>
            <Command.Separator />
            <Command.Item onSelect={() => create('assignments')}>
              <Icons.Send class="mr-2 w-4 h-4" />
              <span>Assignment</span>
            </Command.Item>
            <Command.Item onSelect={() => create('activities')}>
              <Icons.Send class="mr-2 w-4 h-4" />
              <span>Activity</span>
            </Command.Item>
          </Command.Group>
          <Command.Separator />
        </Command.List>
      {/if}
    </Command.Dialog>
  </nav>
</div>
