<script lang="ts">
  import Button from '$lib/components/ui/button/button.svelte';
  import { appStore } from '$lib/app-store';
  import { createItem } from '$lib/api/mutations';
  import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { Badge } from '$lib/components/ui/badge';
  import { CalendarDays, FileText, Tag, Clock } from 'lucide-svelte';

  export let customerId: string | undefined;
  export let contracts;

  function openContract(contract) {
    appStore.select({
      module: 'contracts',
      id: contract.id,
    });
    appStore.openPopup(true);
  }

  async function createContract() {
    // TODO: first name and last name should be required
    // TODO: add company relationship
    // TODO: add a loading state
    // TODO: add error handling
    const data = await createItem('contracts', {
      name: ' ',
      issuedOn: new Date(),
      accountId: customerId,
      status: 'In development',
    });
    openContract(data[0]);
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  function isExpired(contract) {
    if (!contract.validTo) return false;
    return new Date(contract.validTo) < new Date();
  }
</script>

<div class="text-left shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:p-6">
    <div class="flex justify-between">
      <h3 class="text-base font-semibold leading-6 text-primary">
        Contracts
        <span class="text-muted-foreground">({contracts?.length || 0})</span>
      </h3>
      <div class="flex flex-col space-y-2 md:flex-row md:space-x-2">
        <Button class="" variant="secondary" on:click={createContract}
          >Add new contract</Button
        >
      </div>
    </div>
    {#if contracts?.length === 0}
      <p class="mt-2 text-sm text-gray-500">No contracts found</p>
    {:else}
      <div class="mt-4 space-y-4">
        {#each (contracts || []).sort((a, b) => new Date(b.issuedOn) - new Date(a.issuedOn)) as contract (contract.id)}
          <Card class={isExpired(contract) ? 'opacity-50' : ''}>
            <CardHeader>
              <CardTitle class="flex justify-between items-center">
                <Button
                  variant="link"
                  class="flex flex-col items-start pl-0"
                  on:click={() => openContract(contract)}
                >
                  <div>
                    {contract.refId ?? ''}
                    {contract.name ?? ''}
                  </div>
                  <div class="text-xs text-muted-foreground">{contract.id}</div>
                </Button>
                <Badge variant={isExpired(contract) ? 'secondary' : 'default'}
                  >{contract.status}</Badge
                >
              </CardTitle>
              <CardDescription></CardDescription>
            </CardHeader>
            <CardContent>
              <div class="space-y-2">
                <div class="flex items-center space-x-2">
                  <CalendarDays class="w-4 h-4" />
                  <span
                    >Valid: {(contract.validFrom &&
                      formatDate(contract.validFrom)) ??
                      'n/a'} - {(contract.validTo &&
                      formatDate(contract.validTo)) ??
                      'open end'}</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <FileText class="w-4 h-4" />
                  <span>Issued on: {formatDate(contract.issuedOn)}</span>
                </div>
                <!--div class="flex items-center space-x-2">
                  <User class="w-4 h-4" />
                  <span>User ID: </span>
                  <pre>
                    {JSON.stringify(contract, null, 2)}
                  </pre>
                </div-->
                <div class="flex items-center space-x-2">
                  <Clock class="w-4 h-4" />
                  <span
                    >Payment Delay (days): {contract.paymentDelayInDays}</span
                  >
                </div>

                {#if contract.tags}
                  <div class="flex items-center space-x-2">
                    <Tag class="w-4 h-4" />
                    <span>Tags: {contract.tags}</span>
                  </div>
                {/if}
              </div>
            </CardContent>
            <CardFooter>
              <div class="w-full">
                <h4 class="mb-2 text-sm font-semibold">Custom Fields:</h4>
                <div class="grid grid-cols-2 gap-2 text-sm">
                  {#each Object.entries(contract.customFields || {}) as [key, value]}
                    <div>
                      <span class="font-medium">{key}:</span>
                      {#if Array.isArray(value)}
                        {value.join(', ')}
                      {:else}
                        {value || 'N/A'}
                      {/if}
                    </div>
                  {/each}
                </div>
              </div>
            </CardFooter>
          </Card>
        {/each}
      </div>
    {/if}
  </div>
</div>
