<script lang="ts">
  import HantaInputTextareaRichtext from './../../widgets/input/hanta-input-textarea-richtext.svelte';
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    ActivityType,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';

  import { Phone } from 'lucide-svelte';
  import { appStore } from '$lib/app-store';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import ActivityRefsPopover from './activity-refs-popover.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';

  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { getItems } from '$lib/api/queries';
  import { formatRoleName, isCandidate } from '$lib/app/utils';
  import { onMount, tick } from 'svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { Badge } from '$lib/components/ui/badge';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  const loadOptionsContacts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,photo,role',
    });

    return result.data;
  };

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name',
    });

    return result.data;
  };

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      let parse = activityWithRefsInsertSchema.parse($formData);

      saveActivity(parse);
      appStore.closeActivity();
    },
  });

  let { form: formData, errors } = form;

  const onContactsChanged = value => {
    const candidates = [];
    const contacts = [];
    value.forEach(v => {
      if (isCandidate(v.role)) {
        candidates.push(v);
      } else {
        contacts.push(v);
      }
    });

    formData.update(data => ({
      ...data,
      candidates: candidates,
      contacts: contacts,
    }));
  };

  onMount(() => {
    formData.update(data => ({
      ...data,
      allContacts: [...data.contacts, ...data.candidates],
    }));
  });

  const refsChanged = async () => {
    const allContacts = [
      ...new Map(
        [...$formData.contacts, ...$formData.candidates].map(item => [
          item.id,
          item,
        ]),
      ).values(),
    ];

    await tick();

    formData.update(data => ({
      ...data,
      allContacts,
    }));
  };
</script>

<Popup {form}>
  <svelte:fragment slot="header">
    <Phone class="mr-4 w-5 h-5" />
    Call
  </svelte:fragment>
  <div class="flex overflow-scroll flex-col py-0 mt-4 h-full" slot="content">
    <div class="flex px-6 min-w-0 max-w-full">
      <HantaInputMultiSelect
        class="w-full min-w-0"
        {form}
        label="Contacted {$formData['contacts']?.length > 0
          ? 'contacts'
          : 'candidates'}"
        onSelectChanged={onContactsChanged}
        loadOptions={loadOptionsContacts}
        multiple={true}
        name="allContacts"
      >
        <div
          class="flex gap-1.5 items-center w-full h-full content-stretch"
          let:item
          slot="item"
        >
          <div class="w-full">
            <CrmAvatar id={item.id} module="contacts" name={item.name} />
          </div>
          <Badge class="text-xs font-light" variant="secondary"
            >{formatRoleName(item.role)}</Badge
          >
        </div>
        <div
          class="flex overflow-hidden gap-1.5 items-center w-full h-full"
          let:selection
          slot="selection"
        >
          <CrmAvatar
            id={selection.id}
            module="contacts"
            name={selection.name}
          />
          <span class="text-xs font-light text-muted-foreground"
            >{formatRoleName(selection.role)}</span
          >
        </div>
      </HantaInputMultiSelect>
    </div>
    <div class="flex flex-col px-6 w-full md:flex-row md:gap-6">
      <HantaInputDate {form} label="Due date" name="dueDate" withTime={true} />
      <div class="w-full">
        <HantaInputMultiSelect
          class="w-full"
          {form}
          label="Consultant"
          {loadOptions}
          multiple={false}
          asArray={false}
          name="consultantObj"
          placeholder="Choose consultant..."
        >
          <div class="flex items-center w-full h-full" let:item slot="item">
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={item?.photo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 text-sm truncate">{item?.name}</div>
          </div>
          <div
            class="flex items-center h-full overflow-hidden max-w-[250px]"
            let:selection
            slot="selection"
          >
            <Avatar.Root class="size-7">
              <Avatar.Image
                class="object-scale-down rounded-none"
                src={selection?.photo}
              />
              <Avatar.Fallback>-</Avatar.Fallback>
            </Avatar.Root>
            <div class="ml-2 w-full text-sm truncate">{selection?.name}</div>
          </div>
        </HantaInputMultiSelect>
      </div>
    </div>
    <hr class="mx-6 my-1 border-t border-solid border-t-primary-200/20" />

    <HantaInputTextareaRichtext
      {form}
      name="description"
      placeholder="Notes..."
      resizable={false}
      variant="ghost"
    />
    <div
      class="flex flex-row items-center mx-6 space-x-4 border-t border-solid border-t-primary-200/20"
    >
      <ActivityRefsPopover {activity} {form} onSelectChanged={refsChanged} />
    </div>
  </div>
</Popup>
