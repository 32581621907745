<script lang="ts">
  import Button from '../../components/ui/button/button.svelte';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index';
  import { appStore } from '$lib/app-store';
  import {
    EnvelopeClosed,
    ListBullet,
    ChatBubble,
    Timer,
  } from 'radix-icons-svelte';
  import { ActivityType, type ActivityWithRefs } from '$db/schema';
  import { NotepadText, Phone } from 'lucide-svelte';

  type $$Props = {
    mode?: 'collapsed' | 'expanded';
    activity?: ActivityWithRefs;
  };

  export let activity: ActivityWithRefs = {
    contacts: [],
    deals: [],
    accounts: [],
    candidates: [],
    issues: [],
  };

  export let mode = 'expanded';

  let ActivityEnums = ActivityType.enum;
  const actions = [
    { type: ActivityEnums.Email, label: 'E-Mail', icon: EnvelopeClosed },
    { type: ActivityEnums.Note, label: 'Note', icon: NotepadText },
    { type: ActivityEnums.Todo, label: 'To-Do', icon: ListBullet },
    { type: ActivityEnums.Phone, label: 'Call', icon: Phone },
    { type: ActivityEnums.LinkedIn, label: 'Message', icon: ChatBubble },
  ];

  const openActivity = (type: keyof typeof ActivityType.enum) => {
    appStore.openActivity({
      ...activity,
      startDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      type,
    });
  };
</script>

{#if mode === 'expanded'}
  <div class="flex space-x-3">
    {#each actions as { type, label, icon }}
      <button
        on:click={() => openActivity(type)}
        class="flex flex-col items-center h-auto border-none outline-none group hover:bg-none"
      >
        <span
          class="p-3 bg-transparent rounded-full border border-solid transition-colors group-hover:bg-muted border-muted-foreground"
        >
          <svelte:component
            this={icon}
            class="w-4 h-4 text-muted-foreground group-hover:text-accent-foreground"
          />
        </span>
        <span
          class="mt-1.5 text-xs text-muted-foreground group-hover:text-accent-foreground"
          >{label}</span
        >
      </button>
    {/each}
  </div>
{:else}
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button variant="outline" builders={[builder]}>
        <Timer class="mr-2 w-4 h-4" />
        Add Activity
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="z-20 w-40" align="end">
      <DropdownMenu.Separator />
      {#each actions as { type, label, icon }}
        {#if type === ActivityEnums.Assignment && activity?.candidates?.length === 0}
          <!-- do nothing -->
        {:else}
          <DropdownMenu.Item on:click={() => openActivity(type)}>
            <svelte:component this={icon} class="mr-4 w-4 h-4" />
            {label}
          </DropdownMenu.Item>
        {/if}
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
{/if}
