<script lang="ts">
  import { Clerk } from '@clerk/clerk-js';
  import { onMount } from 'svelte';
  import { authStore } from '$lib/auth-store';

  const clerkPublishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
  const clerk = new Clerk(clerkPublishableKey);

  let user;
  onMount(async () => {
    await clerk.load({
      appearance: {
        variables: {
          fontSize: '1.2rem',
          /*colorAlphaShade: 'black',
          colorPrimary: 'black',
          colorText: 'black',*/
        },
      },
    });

    if (clerk.user) {
      console.log('User is logged in');
      const access_token = await clerk.session.getToken({
        template: import.meta.env.VITE_CLERK_SUPABASE_TEMPLATE,
      });

      const organization = {}; //await user.getOrganizationMemberships();
      authStore.set({ clerk, user, token: access_token, organization });

      user = clerk.user;
    } else {
      console.log('User is not logged in');
      authStore.set({ clerk, user });
    }
    if (!clerk.user) {
      clerk.openSignIn({});
    }
  });
</script>

<div id="sign-in" />
{#if !!user}
  <slot />
{/if}
<pre class="hidden mt-24 ml-24">{JSON.stringify(user, null, 2)}</pre>
