<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    activityWithRefsSchema,
    activityWithRefsInsertSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import { appStore } from '$lib/app-store';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import ActivityRefsPopover from './activity-refs-popover.svelte';
  import { ListBullet } from 'radix-icons-svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import { getItems } from '$lib/api/queries';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import HantaInputTextareaRichtext from '$lib/widgets/input/hanta-input-textarea-richtext.svelte';

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.omit({ startDate: true }).partial();

  const loadOptions = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'users',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name',
    });

    return result.data;
  };

  const form = superForm<ActivityWithRefs>(activity, {
    SPA: true,
    validators: zodClient(formSchema),
    dataType: 'json',
    onSubmit: async ({ formData }) => {
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      let parse = activityWithRefsInsertSchema.parse($formData);

      saveActivity(parse);
      appStore.closeActivity();
    },
  });

  let { form: formData, enhance, errors } = form;

  $: console.log('form', $formData);
</script>

<Popup {form} height={500} width={800}>
  <svelte:fragment slot="header">
    <ListBullet class="mr-4 w-5 h-5" />
    Todo
  </svelte:fragment>
  <div class="flex overflow-scroll flex-col py-0 mt-4" slot="content">
    <HantaInputText
      class="mb-4 w-full"
      {form}
      name="name"
      placeholder="Enter your task"
      variant="ghost"
    />

    <div class="flex flex-col px-6 w-full md:gap-6 md:flex-row">
      <HantaInputDate
        {form}
        label="Start Date"
        name="startDate"
        withTime={true}
      />
      <HantaInputDate {form} label="Due date" name="dueDate" withTime={true} />
    </div>
    <hr class="mx-6 my-1 border-t border-solid border-t-primary-200/20" />

    <HantaInputTextareaRichtext
      {form}
      name="description"
      placeholder="Notes..."
      resizable={false}
      variant="ghost"
    />

    <div
      class="flex flex-col items-start mx-6 mb-6 border-t border-solid md:gap-6 md:flex-row border-t-primary-200/20"
    >
      <div class="flex items-center h-full">
        <ActivityRefsPopover {activity} {form} />
      </div>
      <div class="flex gap-2 items-center py-2 w-full">
        <span class="text-sm font-bold text-muted-foreground text-nowrap"
          >Assigned to:</span
        >
        <HantaInputMultiSelect
          class="w-64"
          {form}
          {loadOptions}
          multiple={false}
          asArray={false}
          name="consultantObj"
          placeholder="Choose consultant..."
          variant="light"
        >
          <div
            class="flex overflow-hidden items-center w-full h-full"
            let:index
            let:item
            slot="item"
          >
            <CrmAvatar id={item.id} module="users" size="size-7" />
          </div>
          <div
            class="flex overflow-hidden items-center w-full h-full"
            let:selection
            slot="selection"
          >
            <CrmAvatar id={selection.id} module="users" size="size-6" />
          </div>
        </HantaInputMultiSelect>
      </div>
    </div>
  </div>
</Popup>
