<script lang="ts">
  import PaymentList from './payment-list.svelte';
  import EventsSimpleTimeline from './../../ui/events-simple-timeline.svelte';
  import PageWithTitle from '$lib/ui/page-with-title.svelte';

  import { objectDiff } from '$lib/utils';

  import { insertInvoiceSchema, type Invoice } from '$db/schema';
  import { tick } from 'svelte';
  import { Copy, Lock } from 'lucide-svelte';
  import * as Card from '$lib/components/ui/card';
  import { Badge } from '$lib/components/ui/badge/index';
  import { Button } from '$lib/components/ui/button/index';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { createItem } from '$lib/api/mutations';
  import { finalizeInvoice, saveInvoice } from '$lib/api/invoice-api';
  import { appStore } from '$lib/app-store';
  import { currencyFormatter } from '$lib/app/utils';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import * as Avatar from '$lib/components/ui/avatar/index.js';
  import { getItems } from '$lib/api/queries';
  import * as Form from '$lib/components/ui/form';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import Files from '$lib/components/hanta/files/files.svelte';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';
  import * as AlertDialog from '$lib/components/ui/alert-dialog/index.js';
  import { get } from 'svelte/store';
  import InvoiceRecipientPopover from './invoice-recipient-popover.svelte';
  import {
    calculateSum,
    createPrintDataFroInvoice,
    updateValuesAndPositions,
  } from '$lib/pages/invoices/invoice-utils';
  import InvoiceChangeStatus from './invoice-change-status.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import AdobeSupabasePreview from '$lib/components/hanta/files/adobe-supabase-preview.svelte';
  import { cn } from '$lib/utils';
  import { authStore } from '$lib/auth-store';
  import { docx2pdf, generateDocx } from '$lib/api/docx-api';
  import PdfIcon from '$lib/components/icons/pdf-icon.svelte';
  import InvoicePositions from './invoice-positions.svelte';
  import { toast } from 'svelte-sonner';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  const format: (value: number) => string = currencyFormatter().format;
  const invoiceSchema = insertInvoiceSchema.partial();

  export let flipDurationMs = 300;
  export let item: Invoice = {};

  let formId = 0;
  let form = createNewForm(item);
  let { form: formData, enhance, errors } = form;
  let total = tweened(0, { duration: 500, easing: cubicOut });

  function createNewForm(newItem) {
    return superForm<Invoice>(newItem, {
      resetForm: false,
      SPA: true,
      validators: zodClient(invoiceSchema),
      validationMethod: 'oninput',
      dataType: 'json',
      onSubmit: async () => {
        updateValuesAndPositions($formData);
        try {
          const newInvoice = await saveInvoice($formData);
          console.log('Saved invoice', newInvoice);
        } catch (e) {
          console.error('Error saving invoice', e);
          toast.error(e.message);
        }
      },
    });
  }

  $: if (item) {
    form = createNewForm(JSON.parse(JSON.stringify(item)));
    formData = form.form;
    enhance = form.enhance;
    errors = form.errors;
    formId = (formId + 1) % 10;

    tick().then(() => {
      form.reset({ data: item, keepMessage: false });
      positions = get(formData).positions || [];
    });
  }

  const loadOptionsAccounts = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'accounts',
      from: 0,
      to: 5,
      search: keyword,
      select: 'id,name,logo,address',
    });

    return result.data;
  };

  let positions = [];

  $: hasChanges = $formData && form.isTainted() && $formData.status === 'Draft';
  $: isLocked = $formData.status !== 'Draft';
  $: printData = createPrintDataFroInvoice(item);
  $: sum = calculateSum($formData);
  $: total.set(sum.sum);

  $: console.log({
    hasChanges,
    form: $formData,
    item,
    diff: objectDiff($formData, item),
  });

  const duplicateInvoice = async () => {
    const {
      currency,
      billingMethod,
      memo,
      language,
      paymentCollection,
      dueInDays,
      taxId,
      taxIdName,
      template,
      delivery,
      customer,
      recipient,
      vat,
      gross,
      debit,
      positions,
      introduction,
      conclusion,
      withVat,
    } = $formData;
    const duplicatedInvoice = {
      status: 'Draft',
      name: 'Draft',
      currency,
      billingMethod,
      memo,
      language,
      paymentCollection,
      dueInDays,
      taxId,
      taxIdName,
      template,
      delivery,
      customer,
      recipient,
      vat,
      gross,
      debit,
      positions,
      introduction,
      conclusion,
      withVat,
    };

    const data = await createItem('invoices', duplicatedInvoice);
    appStore.select({ id: data[0].id, module: 'invoices' });
    appStore.openPopup(true);
  };

  const templateName = 'invoice.docx';

  const onFinalize = async () => {
    const id = $formData?.id;
    const finalInvoice = await finalizeInvoice(id);
    try {
      const finalInvoicePrintData = createPrintDataFroInvoice(finalInvoice);

      const orgId = $authStore?.clerk?.session?.lastActiveOrganizationId;
      const filename = `orgs/${orgId}/invoices/${id}/invoice/invoice`;

      const renderedDoc = {
        ...finalInvoicePrintData,
        meta: {
          orgId,
          templateName,
          templateUrl: '/templates/',
          filename: finalInvoicePrintData?.name,
          destination: filename + '.docx',
        },
      };

      try {
        const { signedUrl } = await generateDocx(renderedDoc);

        console.log({ signedUrl });
        const result = await docx2pdf(signedUrl, filename + '.pdf');
      } catch (error) {
        console.error(error);
        // toast.error('Failed to generate document');
        return;
      }
    } catch (e) {
      console.error(e);
    }
  };

  let openPdf = false;
</script>

{#key formId}
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <form
    class="overflow-hidden w-full h-full"
    method="POST"
    on:change={() => {
      positions = JSON.parse(JSON.stringify($formData.positions));
    }}
    use:enhance
    on:keydown={e => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }}
  >
    <PageWithTitle>
      <div
        class="flex flex-row gap-4 items-center w-full text-lg"
        slot="short-card"
      >
        <div class="flex items-center w-full">
          <span class="mr-1 font-normal">Invoice #</span>
          {$formData.invoiceId}
          <Badge
            variant={$formData.status === 'Cancelled'
              ? 'destructive'
              : 'default'}
            class="ml-2 {$formData.status === 'Paid' ? 'bg-green-600' : ''}"
            >{$formData.status}</Badge
          >
        </div>

        <div class="flex flex-row text-xs">
          <div class="flex flex-col w-full text-right">
            <span class="text-lg font-bold">{format($total)}</span>
            <div class="flex flex-row gap-1 text-nowrap text-muted-foreground">
              Net {format(sum.sum - sum.tax)} / VAT {format(sum.tax)}
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 w-full" slot="card">
        <div
          class="flex flex-col gap-4 items-start p-2 w-full md:p-0 md:items-center md:flex-row"
        >
          <div class="flex items-center w-full">
            <span class="mr-1 font-normal">Invoice #</span>
            {$formData.invoiceId}

            <Badge
              variant="outline"
              class={cn(
                'ml-2',
                $formData.status === 'Overdue' && 'bg-yellow-600 text-white',
                $formData.status === 'Paid' && 'bg-green-600 text-white',
                $formData.status === 'Cancellation invoice' &&
                  'bg-red-600 text-white',
                $formData.status === 'Cancelled' && 'bg-red-600 text-white',
              )}
            >
              {$formData.status ?? ''}</Badge
            >

            {#if $formData.status === 'Cancellation invoice'}
              <Badge variant="destructive" class="ml-2"
                >Cancelled invoice #{$formData.invoiceCancellation}</Badge
              >
            {/if}
          </div>

          <div class="flex flex-row gap-4">
            {#if isLocked && !hasChanges}
              {#if $formData.status === 'Open' || $formData.status === 'Overdue'}
                <InvoiceChangeStatus id={$formData.id} sum={$formData.gross} />
              {/if}

              <HantaInputDate
                {form}
                label="Fixed On"
                name="fixedOn"
                readOnly={isLocked}
              >
                <svelte:fragment slot="icon">
                  <Lock class="size-3" />
                </svelte:fragment>
              </HantaInputDate>
            {/if}

            {#if !isLocked && !hasChanges && $formData.status === 'Draft'}
              <div>
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild let:builder>
                    <Button builders={[builder]}>
                      <Lock class="mr-2 size-4" />
                      Finalize invoice
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Header>
                      <AlertDialog.Title
                        >Finalize this invoice?
                      </AlertDialog.Title>
                      <AlertDialog.Description>
                        This will finalize the invoice, ensuring no
                        modifications are possible afterward except for
                        cancellation, thus maintaining the integrity of the
                        invoicing process.
                      </AlertDialog.Description>
                    </AlertDialog.Header>
                    <AlertDialog.Footer>
                      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
                      <AlertDialog.Action on:click={onFinalize}
                        >Finalize now
                      </AlertDialog.Action>
                    </AlertDialog.Footer>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </div>
            {/if}

            {#if hasChanges}
              <div class="flex gap-2 justify-start w-full">
                <Form.Button variant="default">Save</Form.Button>
                <Button
                  variant="outline"
                  on:click={() => {
                    form.reset();
                    positions = $formData.positions;
                  }}
                  >Cancel
                </Button>
              </div>
            {/if}

            {#if !hasChanges}
              <div>
                <AlertDialog.Root>
                  <AlertDialog.Trigger asChild let:builder>
                    <Button
                      builders={[builder]}
                      variant="outline"
                      tooltip="Duplicate invoice"
                    >
                      <Copy class="size-4" />
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Header>
                      <AlertDialog.Title class="flex gap-2 items-center">
                        <Copy class="size-4" />
                        Duplicate invoice?
                      </AlertDialog.Title>
                      <AlertDialog.Description>
                        This will create a new invoice based on the current one,
                        allowing you to make modifications to the new invoice
                        without affecting the original.
                      </AlertDialog.Description>
                    </AlertDialog.Header>
                    <AlertDialog.Footer>
                      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
                      <AlertDialog.Action on:click={() => duplicateInvoice()}
                        >Continue
                      </AlertDialog.Action>
                    </AlertDialog.Footer>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </div>
            {/if}
          </div>
        </div>
        <div class="-mt-5 text-base">
          <span class="font-thin"> Billed to </span>
          <Button
            variant="link"
            on:click={() => {
              appStore.select({
                module: 'accounts',
                id: $formData?.customerObj?.id,
              });
              appStore.openPopup(true);
            }}
          >
            {$formData?.customerObj?.name ?? ''}
          </Button>

          <span class="ml-1">
            {new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format($formData?.gross)}</span
          >
        </div>
      </div>

      <div class="flex flex-col gap-4 mt-8 w-full" slot="content">
        {#if isLocked}
          <PaymentList payments={item.payments} />
        {/if}
        <div class="flex flex-col gap-4 md:flex-row">
          <Card.Root class="w-full">
            <Card.Header>
              <Card.Title class="relative">
                <div class="w-full">Billed to</div>
                {#if !isLocked}
                  <div class="absolute -top-[50%] -right-2 min-w-0">
                    <InvoiceRecipientPopover {form} />
                  </div>
                {/if}
              </Card.Title>
            </Card.Header>
            <Card.Content class="flex flex-row gap-4 items-center">
              {#if $formData.customerObj}
                <div class="w-full">
                  <div class="text-sm font-bold">
                    {$formData?.customerObj?.name}
                  </div>
                  {#if $formData.recipientObj}
                    <div>{$formData?.recipientObj?.name}</div>
                  {/if}
                  <div>{$formData?.customerObj?.address?.street}</div>
                  <div>
                    {$formData?.customerObj?.address?.postalCode}
                    {$formData?.customerObj?.address?.city}
                  </div>
                  <div>{$formData?.customerObj?.address?.country}</div>
                </div>
                <Avatar.Root class="rounded-none size-16">
                  <Avatar.Image
                    class="object-scale-down"
                    src={$formData?.customerObj?.logo}
                  />
                  <Avatar.Fallback>-</Avatar.Fallback>
                </Avatar.Root>
              {:else}
                <HantaInputMultiSelect
                  {form}
                  label="Account"
                  loadOptions={loadOptionsAccounts}
                  multiple={false}
                  name="customerObj"
                  placeholder="Select account..."
                  readOnly={isLocked}
                >
                  <div
                    class="flex items-center w-full h-full"
                    let:item
                    slot="item"
                  >
                    <Avatar.Root class="size-7">
                      <Avatar.Image
                        class="object-scale-down rounded-none"
                        src={item?.logo}
                      />
                      <Avatar.Fallback>SK</Avatar.Fallback>
                    </Avatar.Root>
                    <div class="ml-2 text-sm truncate">{item?.name}</div>
                  </div>
                  <div
                    class="flex overflow-hidden items-center w-full"
                    let:selection
                    slot="selection"
                  >
                    <Avatar.Root class="size-7">
                      <Avatar.Image
                        class="object-scale-down rounded-none"
                        src={selection?.logo}
                      />
                      <Avatar.Fallback>-</Avatar.Fallback>
                    </Avatar.Root>
                    <div class="ml-2 w-full text-sm truncate">
                      {selection?.name}
                    </div>
                  </div>
                </HantaInputMultiSelect>
              {/if}
            </Card.Content>
          </Card.Root>
          <Card.Root class="w-full">
            <Card.Header>
              <Card.Title class="flex flex-row">
                <div class="w-full">
                  Positions
                  <Badge class="ml-2">{$formData.positions?.length}</Badge>
                </div>
                <div>{format($total)}</div>
              </Card.Title>
            </Card.Header>
            <Card.Content class="flex flex-row gap-4 content-baseline">
              <div>
                <HantaInputDate
                  {form}
                  label={'Invoice date'}
                  name="invoiceDate"
                  readOnly={isLocked}
                />

                <HantaInputDate
                  {form}
                  label="Due date"
                  name="paymentDeadline"
                  withOffset={true}
                  readOnly={isLocked}
                  offsets={[
                    { label: '1 day', value: 1 },
                    { label: '7 days', value: 7 },
                    { label: '30 days', value: 30 },
                    { label: '90 days', value: 90 },
                  ]}
                />
              </div>
              <div class="w-full text-right">
                <div>Net {format(sum.sum - sum.tax)}</div>
                <span class="font-normal text-nowrap"
                  >VAT {format(sum.tax)}</span
                >
              </div>
            </Card.Content>
          </Card.Root>
        </div>

        <div
          class="flex flex-col gap-4 p-6 mb-6 text-left bg-opacity-50 rounded border border-solid shadow-xs bg-primary-800"
        >
          <div class="flex flex-row gap-4 items-baseline">
            {#if $formData.delivery === 'E-Mail'}
              <div class="flex flex-row gap-2 items-baseline w-full">
                <span class="text-muted-foreground">Subject: </span>
                <HantaInputText
                  class="p-0 w-full"
                  {form}
                  name="subject"
                  placeholder="Enter subject..."
                  variant="hanta"
                />
              </div>
            {:else}
              <div
                class="w-full text-lg font-semibold tracking-tight leading-none"
              >
                Introduction
              </div>
            {/if}

            <HantaInputSelect
              {form}
              readOnly={isLocked}
              name="delivery"
              options={[
                { label: 'Letter', value: 'Letter' },
                { label: 'E-Mail', value: 'E-Mail' },
              ]}
            />
          </div>
          <HantaInputTextArea
            {form}
            name="introduction"
            placeholder="Please write the cover letter here..."
            readOnly={isLocked}
          />
        </div>

        <InvoicePositions
          {positions}
          orders={item.ordersRefs}
          {isLocked}
          {form}
          {formData}
          {errors}
          {flipDurationMs}
        />

        <div
          class="p-6 mt-6 text-left bg-opacity-50 rounded border border-solid shadow-xs bg-primary-800"
        >
          <HantaInputTextArea
            {form}
            name="conclusion"
            placeholder="Enter conclusion..."
            readOnly={isLocked}
          />
        </div>
      </div>

      <svelte:fragment slot="sidebar">
        <div class="flex flex-col gap-4 p-6 mb-20 w-full">
          <EventsSimpleTimeline
            activities={[
              {
                description: 'Invoice was paid',
                timestamp: item.modifiedAt,
                highlight: true,
              },
              {
                description: 'Invoice was finalized',
                timestamp: item.fixedOn,
                highlight: true,
              },
              {
                description: 'Invoice was created',
                timestamp: item.createdAt,
                highlight: true,
              },
            ]}
          />
          {#if isLocked}
            {#if $formData.status !== 'Draft' && isLocked}
              <div>
                <Button
                  variant="outline"
                  class="px-6 py-8"
                  on:click={() => (openPdf = true)}
                >
                  <PdfIcon />
                  <div class="ml-2">
                    {$formData.name}
                  </div>
                </Button>
                {#if openPdf}
                  <div class="absolute">
                    <AdobeSupabasePreview
                      folder={`invoices/${item?.id}/invoice`}
                      filename={'invoice.pdf'}
                      outputfilename={`${$formData.name}.pdf`}
                      mode={'LIGHT_BOX'}
                      onClose={() => (openPdf = false)}
                    />
                  </div>
                {/if}
              </div>
            {/if}
          {/if}

          <DocxTemplates
            class="px-4 py-2"
            qrCodeData={printData.qrCode}
            data={printData}
            label="Invoice"
            {templateName}
            customFilename={printData.name}
          />

          <HantaInputLovSelect
            readOnly={isLocked}
            {form}
            placeholder="Select tags..."
            bind:value={$formData.tags}
            type="Rechnung - Tags"
            label="Tags"
            addable={true}
            multiple={true}
          />

          <Files compact={true} folder={`invoices/${item?.id}`} />
          <!--
          <pre>{JSON.stringify($formData, null, 4)}</pre>
          <pre>{JSON.stringify($errors, null, 4)}</pre>
          -->
        </div>

        <FormActions {form} />
      </svelte:fragment>
    </PageWithTitle>
  </form>
{/key}
