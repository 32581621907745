import { subDays, format } from 'date-fns';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import { authStore } from '$lib/auth-store';
import { insertInvoiceSchema, InvoiceWithRefs, type Invoice } from '$db/schema';
import { toast } from 'svelte-sonner';
import { getItemById } from './queries';

export interface InvoiceStatus {
  status: string;
  count: number;
  amount: number;
}

const DAY_IN_MS = 24 * 60 * 60 * 1000;
const SIXTY_DAYS = 60 * DAY_IN_MS;
const THIRTY_DAYS = 30 * DAY_IN_MS;

interface InvoiceQuery {
  label: string;
  startDate?: Date;
  endDate?: Date;
}

export async function invoiceStatistics(): Promise<InvoiceStatus[]> {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('invoices')
    .select('status, count:id.count(), amount:debit.sum()');

  if (error) console.error(error);
  return data;
}

export async function cancelInvoice(id: string) {
  const { data, error } = await supabase(
    get(authStore)?.token,
  ).functions.invoke('cancel-invoice', {
    body: { id: id },
  });

  if (error) console.error(error);
  return data;
}

const createInvoiceQuery = (query: InvoiceQuery) => {
  const baseQuery = supabase(get(authStore)?.token)
    .from('invoices')
    .select('status, count:id.count(), amount:debit.sum()')
    .filter('status', 'in', '(Open,Overdue)');

  if (query.startDate) {
    baseQuery.filter(
      'paymentDeadline',
      'gte',
      format(query.startDate, 'yyyy-MM-dd'),
    );
  }
  if (query.endDate) {
    baseQuery.filter(
      'paymentDeadline',
      'lt',
      format(query.endDate, 'yyyy-MM-dd'),
    );
  }

  return baseQuery;
};

export async function outstandingInvoicesStatistics(): Promise<
  InvoiceStatus[]
> {
  const currentDate = new Date();
  const queries: InvoiceQuery[] = [
    { label: 'More than 60 days past due', endDate: subDays(currentDate, 60) },
    {
      label: '30–60 days past due',
      startDate: subDays(currentDate, 60),
      endDate: subDays(currentDate, 30),
    },
    {
      label: '0-30 days past due',
      startDate: subDays(currentDate, 30),
      endDate: currentDate,
    },
    { label: 'Not yet past due', startDate: currentDate },
  ];

  try {
    const results = await Promise.all(queries.map(q => createInvoiceQuery(q)));
    return queries.map((q, index) => ({
      status: q.label,
      count: results[index].data[0]?.count ?? 0,
      amount: results[index].data[0]?.amount ?? 0,
    }));
  } catch (error) {
    console.error('Error fetching outstanding invoices statistics:', error);
    throw error;
  }
}

export const finalizeInvoice = async (id: string) => {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'finalize_invoice',
    {
      p_id: id,
    },
  );

  if (error || data.error) {
    const e = error || data.error;
    toast.error('Error: ' + e);
    throw Error(e);
  }
  return data;
};

export const updateInvoiceStatus = async (id: string, status: string) => {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'update_invoice_status',
    {
      p_id: id,
      p_status: status,
    },
  );

  if (error) {
    toast.error('Error: ' + error.message);
    throw Error(error.message);
  }
};

export const saveInvoice = async (
  invoice: InvoiceWithRefs,
): Promise<InvoiceWithRefs> => {
  const invoiceToSave = insertInvoiceSchema.parse(invoice);

  let response = await supabase(get(authStore)?.token)
    .from('invoices')
    .upsert(invoiceToSave)
    .select('id');
  const { data, error } = response;

  if (error) {
    throw Error(error.message);
  } else {
    return data[0] as InvoiceWithRefs;
  }
};
