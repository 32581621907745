<script lang="ts">
  import FiltersWithDrawer from './filters-with-drawer.svelte';
  import { createEventDispatcher } from 'svelte';
  import VirtualScroll from 'svelte-virtual-scroll-list';
  import { cn } from '$lib/utils';
  import { FileSearch, Search } from 'lucide-svelte';
  import { createInfiniteQuery } from '@tanstack/svelte-query';
  import { getItems } from '$lib/api/queries';
  import { derived, writable } from 'svelte/store';
  import Sorts from './sorts.svelte';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import { debounce } from '$lib/utils/hanta-utils';
  import { Input } from '$lib/components/ui/input';
  import { Label } from '$lib/components/ui/label';
  import ListItem from './list-item.svelte';

  const dispatch = createEventDispatcher();

  export let selected = [];
  export let current = undefined;
  export let module = 'accounts';
  export let sort = undefined;
  export let filters = undefined;
  export let count = 0;
  export let fulltextsearch = false;
  export let withFilters = true;
  export let avatarAsCheckbox = false;
  export let searchTerm = '';
  export let disabledIds = [];

  const debounceSearch = debounce(() => {
    search();
  }, 500);

  $: debounceSearch(searchTerm);

  function search() {
    dispatch('search', searchTerm);
  }

  const moduleStore = writable(module);
  const searchStore = writable(searchTerm);
  const fulltextsearchStore = writable(fulltextsearch);
  const sortStore = writable(sort);
  const filtersStore = writable(filters);

  $: searchStore.set(searchTerm);
  $: moduleStore.set(module);
  $: sortStore.set(sort);
  $: filtersStore.set(filters);
  $: fulltextsearchStore.set(fulltextsearch);

  $: count = $query?.data?.pages[0]?.count || 0;

  const itemsPerPage = 10;
  const query = createInfiniteQuery(
    derived(
      [moduleStore, searchStore, fulltextsearchStore, sortStore, filtersStore],
      ([
        $module,
        $searchTerm,
        $fulltextsearchStore,
        $sortStore,
        $filtersStore,
      ]) => ({
        enabled: !!$module,
        initialPageParam: 0,
        getNextPageParam: lastPage => {
          const { page, count } = lastPage;
          if ((page + 1) * itemsPerPage < count) {
            return page + 1;
          } else {
            return undefined;
          }
        },
        queryKey: [
          $module,
          $searchTerm,
          $fulltextsearchStore,
          $sortStore,
          $filtersStore,
        ],
        queryFn: ({ pageParam, signal }) => {
          return getItems(
            {
              collection: $module,
              from: pageParam === 0 ? 0 : pageParam * itemsPerPage + 1,
              to:
                pageParam === 0
                  ? itemsPerPage - 1
                  : (pageParam + 1) * itemsPerPage,
              search: $searchTerm,
              fulltextsearch: $fulltextsearchStore,
              sort: $sortStore,
              filters: $filtersStore,
              select: getSelectFields($module),
            },
            signal,
          );
        },
      }),
    ),
  );

  function getSelectFields(module) {
    switch (module) {
      case 'global':
        return 'id, _name';
      case 'accounts':
        return 'id, name, logo, address, linkedin';
      case 'tariffs':
        return 'id, name, refId';
      case 'invoices':
        return 'id, name, status,invoiceDate,invoiceId,gross,customer:accounts(id, name)';
      case 'leads':
      case 'orders':
        return 'id, name, status, customer:accounts(id, name)';
      case 'deals':
        return 'id, name, accounts:accounts(id, name)';
      case 'issues':
        return 'refId, id, name, status, account:accounts(id, name)';
      case 'contracts':
        return 'refId, id, name, status, issuedOn, account:accounts!contracts_accountId_accounts_id_fk(id, name)';
      case 'contacts':
        return 'id, name, photo, firstname, lastname, position, linkedin';
      default:
        return 'id, name';
    }
  }
</script>

<div
  class={cn(
    withFilters &&
      'px-2 supports-[backdrop-filter]:bg-background/60 shadow-md pb-2',
  )}
>
  <div class="relative space-y-2">
    <Search class="absolute left-2 top-3 w-4 h-4 text-muted-foreground" />
    <Input placeholder="Search" class="pl-8" bind:value={searchTerm} />
    {#if module === 'contacts'}
      <div class="flex items-center">
        <Checkbox
          id="fts"
          class="bg-primary-800"
          bind:checked={fulltextsearch}
        />
        <Label
          for="fts"
          class="ml-4 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Full text search
        </Label>
      </div>
    {/if}
    {#if withFilters}
      <div
        class="flex w-full bg-transparent bottom-14 md:justify-between z-[9999]"
      >
        <FiltersWithDrawer
          collection={module}
          {filters}
          on:filters={event => dispatch('filters', event.detail)}
        />
        <Sorts
          collection={module}
          on:sort={event => dispatch('sort', event.detail)}
        />
      </div>
    {/if}
  </div>
</div>

<div class={cn(module === 'contacts' ? 'vs-contacts' : 'vs')}>
  {#if $query.isError}
    <p>Error: {$query.error.message}</p>
  {:else if $query.isSuccess}
    {#if $query.data?.pages[0]?.count === 0}
      <div class="flex flex-col justify-center items-center">
        <p class="mt-2 text-xl font-thin text-muted-foreground">
          Nothing found
        </p>
        <FileSearch size="40" class="mt-8 opacity-50 text-muted-foreground" />
      </div>
    {:else}
      {@const items = $query.data.pages?.map(el => el.data).flat()}
      <VirtualScroll
        data={items}
        key="id"
        on:bottom={() => {
          if ($query.hasNextPage) {
            $query.fetchNextPage();
          }
        }}
        let:data={item}
        let:index
      >
        <button
          data-testid={`list-item-${index}`}
          class={cn(
            'w-full overflow-hidden flex flex-col items-start gap-2 rounded-lg border-solid border p-3 text-left text-sm transition-all hover:bg-accent  mb-1 group',
            'border-l-4',
            current?.id === item.id
              ? 'bg-muted border-l-teal-500/70'
              : 'border-primary/10',
          )}
          on:click={() => {
            dispatch('current', { module, id: item.id });
          }}
        >
          <div class="flex flex-col gap-1 w-full">
            <div class="flex items-center text-primary/90">
              <ListItem
                {item}
                {module}
                withSelection={avatarAsCheckbox}
                {disabledIds}
                {selected}
                on:select={e => {
                  dispatch('select', e.detail);
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        </button>
      </VirtualScroll>
    {/if}
  {/if}

  {#if $query.isFetching}
    <div
      class="absolute inset-0 z-[999999] w-full h-full bg-primary/5 opacity-95"
    ></div>
  {/if}
</div>

<style>
  .vs {
    height: calc(100vh - 160px);
    overflow: hidden;
    @apply shadow-2xl;
  }
  .vs-contacts {
    height: calc(100vh - 220px);
    overflow: hidden;
  }

  @media (max-width: 600px) {
    .vs {
      height: calc(100svh - 160px);
    }
    .vs-contacts {
      height: calc(100svh - 180px);
    }
  }

  .border-top {
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
</style>
