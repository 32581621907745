<script lang="ts">
  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';
  import InvoicesStatisticsOverall from './invoices-statistics-overall.svelte';
  import InvoicesStatisticsOverdue from './invoices-statistics-overdue.svelte';
  import InvoicesToIssue from './invoices-to-issue.svelte';
</script>

<div class="p-6 bg-primary-foreground">
  <div class="flex justify-between items-center mb-4">
    <h1 class="text-2xl font-bold">Invoices</h1>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <InvoicesStatisticsOverdue />
    <div class="space-y-4">
      <HantaDateRange />
      <InvoicesStatisticsOverall />
    </div>
    <div class="col-span-2">
      <InvoicesToIssue />
    </div>
  </div>
</div>
