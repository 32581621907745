import { writable } from 'svelte/store';
import { getCurrentUser } from '$lib/api/queries';
import { User } from 'src/types';

function createCurrentUserStore() {
  const { subscribe, set } = writable<User | null>(null);

  return {
    subscribe,
    loadUser: async () => {
      try {
        const user = await getCurrentUser();
        set(user);
      } catch (error) {
        console.error('Failed to load user:', error);
        set(null);
      }
    },
    logout: () => set(null),
  };
}

export const currentUserStore = createCurrentUserStore();
