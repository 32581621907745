<script lang="ts">
  import { Truck } from 'lucide-svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputCheckbox from '$lib/widgets/input/hanta-input-checkbox.svelte';

  export let form;
  export let readOnly = false;
  let { form: formData, enhance, errors } = form;
</script>

<div class="p-6 rounded-lg shadow-md bg-primary-foreground">
  <h2 class="flex items-center mb-4 text-2xl font-semibold text-gray-800">
    <Truck class="mr-2" /> Shipping Details
  </h2>

  {#if $formData.shipment}
    <div class="space-y-4">
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.country}
        label="Country"
        type="Countries"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.operator}
        label="Operator"
        type="Deals > Operator"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.direction}
        label="Direction"
        type="Deals > Direction"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.product}
        label="Product"
        type="Deals > Products"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.packageType}
        label="Package Type"
        type="Deals > Packages"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.paymentSide}
        label="Payment Side"
        type="Deals > PaidSide"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.paymentMethod}
        name="paymentMethod"
        label="Payment Method"
        type="Deals > Payment"
        multiple={false}
        {readOnly}
      />
      <HantaInputCheckbox
        {form}
        label="Security Fee"
        bind:value={$formData.shipment.securityFee}
        {readOnly}
      />
      <HantaInputCheckbox
        {form}
        label="Fuel Fee"
        bind:value={$formData.shipment.fuelFee}
        {readOnly}
      />
    </div>
  {/if}
</div>
