<script lang="ts">
  import * as Carousel from '$lib/components/ui/carousel/index.js';
  import {
    Calendar,
    Users,
    TrendingUp,
    CreditCard,
    FileText,
    DollarSign,
    Euro,
    ArrowUpRight,
    ArrowDownRight,
    BarChart,
  } from 'lucide-svelte';

  export let account: AccountWithRefs;
  export let stats = [
    { label: 'Is customer', value: 'No', icon: Users },
    { label: 'Customer since', value: '24.06.2024', icon: Users },
    { label: 'Last contact', value: '09.09.2024', icon: Calendar },
    { label: 'Last sale', value: '20.09.2024', icon: TrendingUp },
    { label: 'Last invoice', value: '20.09.2024', icon: FileText },
    { label: 'Last payment', value: '20.09.2024', icon: Euro },
  ];

  export let volumeStats = {
    'Current Q': account.volumeCurrentQ,
    'Last Q': account.volumeLastQ,
    Difference: account.volumeDiff,
    'Current Q Category': account.categoryCurrentQ,
    'Last Q Category': account.categoryLastQ,
    'Category Trend': account.categoryDiffToLastQ,
  };

  const formatNumber = (num: number) => {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const isPositive = (value: number | string) => {
    if (typeof value === 'number') {
      return value > 0;
    }
    return value === 'Повышение';
  };

  const getVolumeIcon = (key: string) => {
    switch (key) {
      case 'Current Q':
      case 'Last Q':
        return Euro;
      case 'Difference':
        return TrendingUp;
      case 'Category current Q':
      case 'Category last Q':
        return FileText;
      case 'Category trend':
        return BarChart;
      default:
        return BarChart;
    }
  };
</script>

<div class="grid grid-cols-1 gap-4 my-12 lg:grid-cols-2">
  <div class="p-6 space-y-6 w-full rounded-lg shadow-md bg-primary-foreground">
    <h2 class="text-xl font-bold text-gray-800">Customer Statistics</h2>
    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
      {#each stats as stat}
        <div
          class="p-4 bg-gray-50 rounded-md transition-all duration-300 hover:bg-blue-50 hover:shadow-md"
        >
          <div class="flex flex-col space-y-4">
            <div class="flex justify-between items-center">
              <span class="text-sm font-medium text-gray-600">{stat.label}</span
              >
              <svelte:component
                this={stat.icon}
                class="w-5 h-5 text-blue-500"
              />
            </div>
            <span class="text-lg font-semibold text-gray-800">{stat.value}</span
            >
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div class="p-6 space-y-6 w-full rounded-lg shadow-md bg-primary-foreground">
    <h2 class="text-xl font-bold text-gray-800">Volume Statistics</h2>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {#each Object.entries(volumeStats) as [key, value]}
        <div
          class="p-4 bg-gray-50 rounded-md transition-all duration-300 hover:bg-blue-50 hover:shadow-md"
        >
          <div class="flex flex-col space-y-4">
            <div class="flex justify-between items-center">
              <span class="text-sm font-medium text-gray-600">{key}</span>
              <svelte:component
                this={getVolumeIcon(key)}
                class="w-5 h-5 text-blue-500"
              />
            </div>
            <div class="flex justify-between items-center">
              {#if typeof value === 'number'}
                <span class="text-lg font-semibold text-gray-900"
                  >{formatNumber(value)}</span
                >
              {:else}
                <span class="text-lg font-semibold text-gray-900">{value}</span>
              {/if}
              {#if key === 'Difference' || key === 'Category trend'}
                <span class="ml-2">
                  {#if isPositive(value)}
                    <ArrowUpRight class="w-5 h-5 text-green-500" />
                  {:else}
                    <ArrowDownRight class="w-5 h-5 text-red-500" />
                  {/if}
                </span>
              {/if}
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
