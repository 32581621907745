<script lang="ts">
  import * as Resizable from '$lib/components/ui/resizable';
  import { PencilIcon } from 'lucide-svelte';
  import { Button } from '$lib/components/ui/button';

  export let dimensions: any;
</script>

{#each dimensions || [] as dimension}
  <div class="flex space-x-2">
    <div>{dimension.name}</div>
    <Button variant="ghost" size="sm">
      <PencilIcon />
    </Button>
  </div>
{/each}

<Resizable.PaneGroup
  direction="vertical"
  class="min-h-[200px] max-w-md rounded-lg border"
>
  <Resizable.Pane defaultSize={25}>
    <div class="flex justify-center items-center p-6 h-full">
      <span class="font-semibold">Header</span>
    </div>
  </Resizable.Pane>
  <Resizable.Handle withHandle />
  <Resizable.Pane defaultSize={75}>
    <div class="flex justify-center items-center p-6 h-full">
      <span class="font-semibold">Content</span>
    </div>
  </Resizable.Pane>
</Resizable.PaneGroup>

<Resizable.PaneGroup direction="vertical">
  <Resizable.Pane>One</Resizable.Pane>
  <Resizable.Handle withHandle />
  <Resizable.Pane>Two</Resizable.Pane>
</Resizable.PaneGroup>
