<script lang="ts">
  import PriceServiceComponent from '$lib/pages/leads_orders/price-service.svelte';

  export let refDate: Date | undefined = undefined;
  export let type: string = 'EXPORT';
  export let caption: string;
  export let weights: number[];
  export let zones: (number | string)[];
  export let operator: string;
  export let product: string;
  export let baseTariff: any | undefined = undefined;
  export let customerTariff: any | undefined = undefined;
</script>

<table class="mb-5 w-full border-collapse">
  <caption
    class={`bg-[#4b0082] dark:bg-[#6b238e] text-white p-2.5 font-bold uppercase
    ${operator === 'TNT' ? '!bg-[#f67828] dark:!bg-[#d85a28]' : ''}`}
  >
    {caption}
  </caption>
  <thead>
    <tr>
      <th
        class="bg-[#f2f2f2] dark:bg-[#2a2a2a] border border-[#ddd] dark:border-[#444] p-2 text-center dark:text-white"
      >
        Weight (kg)
      </th>
      {#each zones as zone}
        <th
          class="bg-[#f2f2f2] dark:bg-[#2a2a2a] border border-[#ddd] dark:border-[#444] p-2 text-center dark:text-white"
        >
          Zone {zone}
        </th>
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each weights as weight, index}
      {@const step =
        +weight === +520 || +weight === +120
          ? 10
          : +weight === +11
            ? 1
            : +weight === +5.5
              ? 0.5
              : 0}
      <tr
        class={index % 2 === 0
          ? 'bg-white dark:bg-[#1a1a1a]'
          : 'bg-[#f9f9f9] dark:bg-[#2a2a2a]'}
      >
        <td
          class="border border-[#ddd] dark:border-[#444] p-2 text-center dark:text-white"
          class:font-bold={step > 0}
        >
          {#if step > 0}
            +{step} kg
          {:else}
            {weight}
          {/if}
        </td>
        {#each zones as zone}
          <td
            class="border border-[#ddd] dark:border-[#444] p-2 text-center dark:text-white"
          >
            <PriceServiceComponent
              showPrice={true}
              lead={{
                type,
                country: 'Zone ' + zone,
                product,
                operator,
                weight,
                paymentSide: 'SENDER',
                extraService: '',
                packageType: 'PO - Other packaging material',
              }}
              supplierTarifId={undefined}
              customerTarifId={customerTariff}
              baseTarifId={baseTariff}
              {refDate}
              {weight}
              diff={step}
              withMdl={false}
            />
          </td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
