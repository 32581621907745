<script>
  import { onDestroy, onMount } from 'svelte';
  import { authStore } from '$lib/auth-store';
  import { appStore } from '$lib/app-store';
  import { dark, light } from '@clerk/themes';

  let organisationComponent;
  let organizationListComponent;

  let isLightTheme = true;

  onMount(async () => {
    $authStore?.clerk?.mountOrganizationSwitcher(organisationComponent, {
      afterSelectOrganizationUrl: '/',
      appearance: {
        baseTheme: isLightTheme ? light : dark,
      },
    });
    // $authStore?.clerk?.mountOrganizationList(organizationListComponent, {});
  });

  onDestroy(() => {
    if (window.clerk) {
      clerk?.unmountOrganizationSwitcher(organisationComponent);
      clerk?.unmountOrganizationList(organizationListComponent);
    }
  });

  $: if (organisationComponent) {
    const style = document.createElement('style');
    style.textContent = $appStore.isCollapsed
      ? '.cl-organizationPreviewTextContainer__organizationSwitcherTrigger { display: none; }'
      : '.cl-organizationPreviewTextContainer__organizationSwitcherTrigger { display: block; }';
    organisationComponent.appendChild(style);
  }
</script>

<div
  class="md:fixed md:-top-2 md:left-0 md:bg-background z-[99999] pl-1 pt-1 md:dark:!text-white"
>
  <div class="org-menu">
    <div id="organisations" bind:this={organisationComponent} />

    <div
      id="organisationList"
      bind:this={organizationListComponent}
      class="hidden"
    />
  </div>
</div>

<style>
  .org-menu {
    z-index: 99999;
  }

  :global(#global-nav) {
    z-index: 10;
  }
</style>
