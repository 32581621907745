<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { PriceService } from './PriceService';
  import { getCountryZones } from '$lib/api/queries';
  export let lead;
  export let supplierTarif;
  export let customerTarif;
  export let baseTarif;
  export let exchangesData;
  export let weight;
  export let diff;
  export let withMdl = true;
  export let showPrice = false;
  let loading = false;
  let error = false;
  let price;
  let discount = 0;
  $: if (lead || supplierTarif || customerTarif || baseTarif || weight) {
    calculatePrices();
  }
  const priceService = new PriceService();
  async function calculatePrices() {
    const request = JSON.parse(JSON.stringify(lead));
    const country = (request.country ?? '').split(' / ')[0];
    const countryWithZones = await getCountryZones(country);
    if (!countryWithZones) {
      return;
    }
    request.weight = weight;
    request.direction = request.type;
    request.country = countryWithZones;
    loading = true;
    error = false;
    const tariffs = {
      supplierTarif,
      customerTarif,
      baseTarif,
    };
    setTimeout(
      () => {
        priceService
          .calculatePrices(request, tariffs, exchangesData, withMdl)
          .then(async result => {
            console.log({ price: result.totalEUR });

            if (diff > 0) {
              const previousStepPrice = await priceService.calculatePrices(
                { ...request, weight: request.weight - diff, diff: 0 },
                tariffs,
                exchangesData,
                withMdl,
              );
              price = result.totalEUR - previousStepPrice.totalEUR;
              discount =
                result.discountTotalEur - previousStepPrice.discountTotalEur;
            } else {
              price = result.totalEUR;
              discount = result.discountTotalEur;
              if (
                Math.abs(lead.totalEUR - result.totalEUR) > 0.1 ||
                Math.abs(lead.totalNational - result.totalMDL) > 1
              ) {
                console.log({ request: result });
                lead.totalEUR = result.totalEUR;
                lead.exchangeRate = result.exchangeRate;
                lead.totalNational = result.totalMDL;
                lead.rate = result;
              }
            }
          })
          .catch(e => {
            console.error(e);
            error = true;
          })
          .finally(() => {
            loading = false;
          });
      },
      showPrice ? Math.random() * 50 : 0,
    );
  }
</script>

{#if showPrice}
  <div
    class="w-full dark:text-white"
    class:bg-orange-200={discount > 0}
    class:dark:bg-orange-900={discount > 0}
    class:font-bold={diff > 0}
  >
    {#if loading}
      <span class="flex relative mx-auto w-3 h-3">
        <span
          class="inline-flex absolute w-full h-full bg-sky-400 rounded-full opacity-75 animate-ping dark:bg-sky-600"
        ></span>
        <span
          class="inline-flex relative w-3 h-3 bg-sky-500 rounded-full dark:bg-sky-400"
        ></span>
      </span>
    {:else}
      <span class:text-red-500={error} class:dark:text-red-400={error}>
        {error ? 'ERROR' : (price?.toFixed(2) ?? '0.00')}
      </span>
    {/if}
  </div>
{/if}
<Button class="hidden" on:click={calculatePrices}>Calculate Prices</Button>
