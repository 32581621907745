<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { getBucketsForQuery } from '$lib/api/aggregations';
  import { onMount } from 'svelte';
  import { VisXYContainer, VisGroupedBar, VisAxis } from '@unovis/svelte';
  import { Switch } from '$lib/components/ui/switch';

  import Badge from '$lib/components/ui/badge/badge.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import { cn } from '$lib/utils';
  import Input from '$lib/components/ui/input/input.svelte';

  const dispatch = createEventDispatcher();

  export let fieldDefinition;
  export let filters;
  export let search = '';
  export let fulltextsearch = false;
  export let collection;
  export let fieldLabel = undefined;
  export let field;
  export let withGraphs = false;

  let findBy = '';

  $: filteredData = (data || []).filter(
    a =>
      findBy === '' ||
      (a.field || '').toLowerCase().includes(findBy.toLowerCase()),
  );

  let checked = false;

  let data = [];
  async function loadData(
    collection,
    field,
    filters,
    search,
    fulltextsearch,
    fieldDefinition,
  ) {
    if (filters?.find(f => f.field === field)) {
      checked = true;
    }
    if (!checked) return;
    console.log(
      'loadData',
      collection,
      field,
      filters,
      search,
      fulltextsearch,
      fieldDefinition,
    );

    data = await getBucketsForQuery({
      collection,
      field,
      search,
      fulltextsearch,
      filters: (filters || []).filter(f => f.field !== field),
      is_array: fieldDefinition?.isArray,
      join_table: fieldDefinition?.joinTable || null,
      join_condition: fieldDefinition?.joinCondition || null,
    });
  }

  $: loadData(
    collection,
    field,
    filters,
    search,
    fulltextsearch,
    fieldDefinition,
  );

  onMount(async () => {
    await loadData(
      collection,
      field,
      filters,
      search,
      fulltextsearch,
      fieldDefinition,
    );
  });

  function toggleFilterCriteria(item) {
    dispatch('toggleFilterCriteria', item);
  }

  $: dispatch('toggleCheck', checked);

  const tickFormat = (tick: number) => data[tick].field;
  const x = (d: { count: number; field: string }, i: number) => i;
  const y = (d: { count: number; field: string }) => d.count;
</script>

<div class="relative mt-3">
  <div class="flex items-center space-x-2">
    <Switch includeInput bind:checked />
    <h2 class="text-xl capitalize thin">{fieldLabel ?? field}</h2>
  </div>
  <!--div
    class="top-10 w-[calc(100%-20px)] absolute h-[30px] opacity-60 bg-primary-800"
  ></div-->
  {#if checked}
    {#if withGraphs}
      <div class="h-[100px]">
        <VisXYContainer {data} height={100}>
          <VisGroupedBar {x} {y} color={'hsl(var(--primary) / 0.7)'} />
          <VisAxis
            type="x"
            label={field}
            {tickFormat}
            numTicks={data.length > 10 ? 5 : data.length}
          />
          <VisAxis type="y" label="Count" />
        </VisXYContainer>
      </div>
    {:else}
      <div class="relative mt-4 p-4 overflow-scroll max-h-[200px]">
        {#if data?.length > 9}
          <div class="sticky left-2 -top-5 p-1 w-full bg-transparent">
            <Input bind:value={findBy} placeholder="Filter..." />
          </div>
        {/if}
        {#each filteredData as item}
          <Button
            size="sm"
            variant="outline"
            class={cn(
              'truncate ml-2 mt-1 max-w-[calc(100%-10px)]',
              filters?.find(
                f =>
                  f.field === field &&
                  f.value?.filter(i => i.key === item.key)?.length > 0,
              ) && 'hover:bg-foreground/30 bg-primary text-secondary',
            )}
            on:click={() => toggleFilterCriteria(item)}
          >
            <span class="truncate">
              {item.field === '' ? '-' : item.field}
            </span>

            <Badge variant="secondary" class="ml-2">{item.count}</Badge>
          </Button>
        {/each}
      </div>
    {/if}
  {/if}
</div>
