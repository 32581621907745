<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { Card } from '$lib/components/ui/card';
  import { AlertCircle } from 'lucide-svelte'; // Add this import

  import { outstandingInvoicesStatistics } from '$lib/api/invoice-api';
  import InvoicesChart from './invoices-chart.svelte';
  import InvoicesResults from './invoices-results.svelte';
  const OUTSTANDING_INVOICE_COLORS = {
    'More than 60 days past due': 'hsl(0, 87%, 67%)', // Bright red, similar to red-500
    '30–60 days past due': 'hsl(27, 96%, 61%)', // Bright orange, similar to orange-500
    '0-30 days past due': 'hsl(48, 96%, 53%)', // Bright yellow, similar to yellow-500
    'Not yet past due': 'hsl(217, 91%, 60%)', // Bright blue, similar to blue-500
  };

  const outstandingInvoicesQuery = createQuery({
    queryKey: ['invoices', 'outstanding'],
    queryFn: async () => {
      const data = await outstandingInvoicesStatistics();
      return processOutstandingInvoicesData(data);
    },
  });

  $: ({ data, isLoading, isError, error } = $outstandingInvoicesQuery);

  function processOutstandingInvoicesData(data) {
    return data?.map(item => ({
      ...item,
      color: OUTSTANDING_INVOICE_COLORS[item.status],
    }));
  }

  function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }).format(amount);
  }

  $: total = data?.reduce((acc, curr) => acc + curr.amount, 0);
</script>

<Card class="mb-4 max-w-lg">
  <div class="flex justify-between items-center p-4">
    <div class="flex gap-2 items-center">
      <AlertCircle class="text-blue-500" />
      <div>
        <h2 class="text-base">Outstanding invoices</h2>
        <p class="font-mono text-lg text-muted-foreground">
          {formatCurrency(total)}
        </p>
      </div>
    </div>
  </div>
  <div class="flex p-2">
    <div>
      <h2 class="hidden mb-2 text-lg font-semibold">Outstanding invoices</h2>
      {#if isLoading}
        <p>Loading outstanding invoice data...</p>
      {:else if isError}
        <p>Error loading outstanding invoice data: {error.message}</p>
      {:else if data}
        <InvoicesChart {data} statusColors={OUTSTANDING_INVOICE_COLORS} />
        <InvoicesResults label="Invoice age" {data} />
      {/if}
    </div>
  </div>
</Card>
