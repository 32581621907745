<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import { Button } from '$lib/components/ui/button';
  import { getInvoicesToIssueGroupedByCustomer } from '$lib/api/queries'; // Adjust this import path as needed
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '$lib/components/ui/table';
  import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from '$lib/components/ui/accordion';

  // Create query
  const invoicesToIssueQuery = createQuery({
    queryKey: ['orders', 'invoicesToIssue'],
    queryFn: () => getInvoicesToIssueGroupedByCustomer(),
  });
</script>

<div class="container py-10 mx-auto">
  <h1 class="mb-4 text-2xl font-bold">Invoices to Issue by Customer</h1>

  {#if $invoicesToIssueQuery.isLoading}
    <p>Loading...</p>
  {:else if $invoicesToIssueQuery.isError}
    <p class="text-red-500">Error: {$invoicesToIssueQuery.error.message}</p>
  {:else if $invoicesToIssueQuery.data.length === 0}
    <p>No invoices to issue found.</p>
  {:else}
    <Accordion type="single" collapsible class="w-full">
      {#each $invoicesToIssueQuery.data as group}
        <AccordionItem value={group.customer.id}>
          <AccordionTrigger>{group.customer.name}</AccordionTrigger>
          <AccordionContent>
            <Button>Issue Invoice</Button>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Invoice ID</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {#each group.orders || [] as order}
                  <TableRow>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.date}</TableCell>
                    <TableCell>${order.amount?.toFixed(2)}</TableCell>
                    <TableCell>{order.status}</TableCell>
                  </TableRow>
                {/each}
              </TableBody>
            </Table>
          </AccordionContent>
        </AccordionItem>
      {/each}
    </Accordion>
  {/if}
</div>
