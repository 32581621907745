<script lang="ts">
  import * as Form from '$lib/components/ui/form/index.js';
  import Select from 'svelte-select';
  import { cn } from '$lib/utils';
  import { tick } from 'svelte';
  import { Badge } from '$lib/components/ui/badge';
  import HantaIcon from '$lib/components/hanta/hanta-icon.svelte';

  export let form = undefined;
  export let value = undefined;
  export let name;
  export let label = undefined;
  export let description = '';
  export let placeholder = '';
  export let simpleMode = false; // if true, the value is a simple array of strings
  export let multiple = true; // if true, the value is an array
  export let options = []; // if provided, the options are static
  export let disabled = false;
  export let asArray = false;
  export let readOnly = false;
  export let addable = false;
  export let icon: any | undefined;
  export let variant: 'default' | 'light' = 'default';
  export let loadOptions: ((keyword: string) => any) | undefined = () => [];
  export let onItemAdded: ((item: any) => any) | undefined = () => [];

  let items: any[];

  type $$Props = {
    form: any;
    value: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    simpleMode?: boolean;
    disabled?: boolean;
    icon: any | undefined;
    multiple?: boolean;
    options?: any[];
    class?: string;
    asArray?: boolean;
    readOnly?: boolean;
    loadOptions?: ((keyword: string) => any) | undefined;
    onSelectChanged?: (v: any) => void;
    onItemAdded?: (item: any) => void;
    variant?: 'default' | 'light';
    addable?: boolean;
  };

  let className: $$Props['class'] = 'w-full';
  export { className as class };
  export let onSelectChanged: (v: any) => void;

  let { form: formData } = form || {};

  $: if (form) {
    formData = form.form;
  }

  const selectionChanged = async e => {
    setValue({});
    setValue(
      multiple
        ? e.detail.map(item => {
            if (simpleMode && item.value.startsWith('Add: ')) {
              let newValue = item.value.replace('Add: ', '');
              tick().then(_ => onItemAdded && onItemAdded(newValue));
              return newValue;
            } else {
              return item.value;
            }
          })
        : asArray
          ? [e.detail.value]
          : e.detail.value,
    );

    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    onSelectChanged && onSelectChanged(getValue());

    console.log('onSelectChanged', e, getValue());
  };

  const handleClear = async e => {
    const vals = e.detail;
    if (Array.isArray(vals)) {
      setValue(getValue?.()?.filter(a => !vals.find(v => v.id === a.id)));
    } else {
      const value = vals.id || vals.value;
      setValue(
        multiple || asArray
          ? getValue()?.filter(a => (a.id && a.id !== value) || a !== value)
          : null,
      );
    }

    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

    onSelectChanged && onSelectChanged(getValue());

    console.log('onSelectChanged accounts removed', getValue());
  };

  function getValue() {
    return value;
  }

  function setValue(pvalue: any) {
    value = pvalue;
  }

  function isSelected(item) {
    return multiple || asArray
      ? !getValue()?.find(a => a.id === item.id)
      : getValue() !== item.id;
  }

  $: loadOptionsInternal = async query => {
    if (options && options.length > 0) {
      return options;
    }

    if (!loadOptions) return [];

    let result = await loadOptions(query);
    return result?.map(item => ({
      ...item,
      value: item,
      selectable: isSelected(item),
    }));
  };

  $: value = multiple
    ? getValue()?.map(a => (simpleMode ? a : { value: a, ...a }))
    : asArray
      ? (getValue() && getValue()[0]) || undefined
      : getValue();

  let svelteSelect: Select;

  $: if (value)
    svelteSelect?.getFilteredItems()?.forEach(item => {
      item.selectable = isSelected(item);
    });

  let filterText = '';
  let filterChanged = false;

  const handleFilter = async e => {
    if (!addable || !(simpleMode && multiple)) {
      return;
    }

    if (filterChanged) {
      filterChanged = false;
      return;
    }

    let prevOptions = options.filter((v: string) => !v.startsWith('Add:'));
    if (
      e.detail.findIndex(
        v => v.value?.toLowerCase() === filterText.toLowerCase(),
      ) > -1
    ) {
      options = prevOptions;
    } else {
      options =
        filterText.length > 0
          ? ['Add: ' + filterText, ...prevOptions]
          : prevOptions;
    }

    filterChanged = true;
  };

  const handleBlur = async e => {
    if (simpleMode && multiple) {
      filterText = '';
      options = options.filter((v: string) => !v.startsWith('Add: '));
    }
  };
</script>

<Form.Field
  class={cn('grid-cols-1 grid-rows-1 gap-y-2 grid', className)}
  {form}
  {name}
>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label class="flex flex-row gap-2 items-center">
        <slot name="icon" />
        {#if icon}
          <HantaIcon {icon} />
        {/if}
        {label}
      </Form.Label>
    {/if}
    {#if !readOnly}
      <Select
        bind:this={svelteSelect}
        bind:filterText
        on:change={selectionChanged}
        on:clear={handleClear}
        on:filter={handleFilter}
        on:blur={handleBlur}
        {disabled}
        {multiple}
        items={options && options.length > 0 ? options : items}
        itemId="value"
        loadOptions={simpleMode ? undefined : loadOptionsInternal}
        placeholder={placeholder || label}
        {value}
      >
        <svelte:fragment let:index let:item slot="item">
          {#if $$slots.item}
            <slot {index} {item} name="item" />
          {:else}
            <div class="flex items-center h-full">
              <div class="text-sm">{item?.label}</div>
            </div>
          {/if}
        </svelte:fragment>

        <svelte:fragment let:selection slot="selection">
          {#if $$slots.selection}
            <slot name="selection" {selection} />
          {:else}
            <div class="flex items-center h-full">
              <div class="ml-2 text-sm">{selection?.label}</div>
            </div>
          {/if}
        </svelte:fragment>
      </Select>
    {:else if $$slots.selection}
      <slot name="selection" selection={value} />
    {:else}
      <div class="flex items-center h-full">
        {#each value || [] as item, index}
          <Badge class="ml-2 text-xs truncate">{item}</Badge>
        {/each}
      </div>
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  {#if variant !== 'light'}
    <Form.FieldErrors />
  {/if}
</Form.Field>

<style lang="postcss">
  :global(.svelte-select .value-container) {
    flex-shrink: 0;
  }

  :global(.svelte-select-list) {
    @apply !bg-primary-foreground;
  }

  :global(.svelte-select) {
    --item-is-active-bg: theme(colors.primary.DEFAULT);
    --item-is-active-color: theme(colors.secondary.DEFAULT);
    border: solid 1px theme(colors.gray.200) !important;
    @apply !border-primary/20 hover:!bg-muted transition-all !px-2 shrink-0 border border-muted hover:border-primary/20;
    --multi-select-padding: 0;
    --multi-select-border: solid 1px theme(colors.gray.200);
    --border-hover: solid 1px theme(colors.gray.200);
    --border: solid 1px theme(colors.gray.200);
    --border-radius: 3px;
    --multi-item-border-radius: 3px;
    --multi-item-bg: theme(colors.muted.DEFAULT);
    --item-hover-bg: theme(colors.muted.DEFAULT);
    --multi-item-clear-icon-color: theme(colors.primary.DEFAULT);
  }

  :global(.svelte-select input::placeholder) {
    @apply px-2;
  }

  :global(.svelte-select input:focus, .svelte-select input:hover) {
    box-shadow: unset;
  }

  :global(.svelte-select input) {
    --tw-ring-shadow: 0 0 #000 !important;
    --placeholder-color: theme(colors.primary.DEFAULT);
    @apply !text-sm font-medium !text-primary;
    box-shadow: unset;
  }

  :global(.svelte-select:hover) {
    border: none;
  }
</style>
