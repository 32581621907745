import { authStore } from '$lib/auth-store';
import { writable } from 'svelte/store';
import { supabase } from './supabase';
import { get } from 'svelte/store';
import { ActivityType, ContactRoleType, PriorityType } from '$db/schema';
import { isValidUUID } from '$lib/app/utils';

export type FilterType = {
  field: string;
  operator: string;
  value?: any;
  active: boolean;
};

export type QueryStore = {
  collection: string;
  from?: number;
  to?: number;
  items?: any[];
  count?: number;
  filters?: FilterType[];
  search?: string;
  select?: string;
  sort?: { column: string; order: 'asc' | 'desc' };
  status?: 'preparing' | 'ready';
  fulltextsearch?: boolean;
};

export const getCountryZones = async (country: string) => {
  if (country.startsWith('Zone ')) {
    const zone = country.split('Zone ')[1];
    return {
      id: country,
      name: country,
      zone: zone,
      zoneEco: zone,
      zoneFedEx: zone,
    };
  }

  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .select('id, name, values')
    .eq('name', 'Countries');

  if (error) {
    throw Error(error.message);
  } else if (data.length > 0) {
    return data[0].values.find(item => item.name === country);
  } else {
    return null;
  }
};

export const findAccountOrders = async (accountId: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('orders')
    .select('*')
    .eq('customer', accountId);
  return data;
};

export const findOpenInvoices = async (customerId: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('invoices')
    .select('*')
    .eq('customer', customerId)
    .eq('status', 'Draft');
  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
};

export const getOrCreateItem = async (collection: string, id: any) => {
  if (id) {
    return getItemById(collection, id);
  } else {
    const initValues = {};
    if (collection === 'accounts') {
      initValues['communication'] = {};
    } else if (collection === 'deals') {
      initValues['communicationPrivate'] = {};
    }
    return Promise.resolve({
      ...initValues,
      id: self.crypto.randomUUID(),
    });
  }
};

export const getExchangeRates = async (refDate: string) => {
  const date = new Date(refDate)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\./g, '.');

  const { data, error } = await supabase(get(authStore)?.token)
    .from('generic')
    .select('*')
    .eq('type', 'day')
    .eq('name', date);
  if (error) {
    throw Error(error.message);
  } else {
    if (data && data.length > 0 && data[0].payload) {
      return data[0].payload?.exchanges;
    }
    return null;
  }
};

export const getInvoicesToIssueGroupedByCustomer = async () => {
  const invoices = await getInvoicesToIssue();
  const groupedInvoices = invoices.reduce((acc, invoice) => {
    const customerId = invoice.account.id;
    if (!acc[customerId]) {
      acc[customerId] = {
        customer: invoice.account,
        orders: [],
      };
    }
    acc[customerId].orders.push(invoice);
    return acc;
  }, {});

  return Object.values(groupedInvoices);
};

export const getInvoicesToIssue = async (customerId?: string) => {
  let query = supabase(get(authStore)?.token)
    .from('orders')
    .select('*, account:accounts(id, name, logo)')
    .is('invoiceDate', null);
  //TODO: .eq('status', 'Archived')
  if (customerId) {
    query.eq('customer', customerId);
  }
  const { data, error } = await query;
  return data;
};

export const getCurrentUser = async () => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('users')
    .select('*')
    .eq('name', get(authStore)?.clerk?.user?.fullName);
  return data[0];
};

export const getTariff = async (id: string) => {
  const result = await getItemById('tariffs', id);
  if (!result) {
    return {};
  }
  return result;
};

export const getItemById = async (
  collection: string,
  id: string,
  withRelated: boolean = true,
) => {
  let select = '*';
  if (withRelated) {
    if (collection === 'orders') {
      select = `*,
        account:accounts(id, name,
        logo,
        creditRating,
        debtReason,
        currentBalance,
        maxDebt,
        currentCreditLimit,
        baseTariff:tariffs!baseTariff(id, name),
        customerTariff:tariffs!customerTariff(id, name),
        supplierTariff:tariffs!supplierTariff(id, name)
        ),
        user:users(id, name, photo),
        invoice:invoices(id, name),
        contactObj:contacts(id, name, photo),
        shipment:shipments(*,rate:rates(*))`;
    } else if (collection === 'leads') {
      select = `*,
        account:accounts(id, name,
        logo,
        creditRating,
        debtReason,
        currentBalance,
        maxDebt,
        currentCreditLimit,
        baseTariff:tariffs!baseTariff(id, name),
        customerTariff:tariffs!customerTariff(id, name),
        supplierTariff:tariffs!supplierTariff(id, name)
        ),
        user:users(id, name, photo),
        contactObj:contacts(id, name, photo),
        shipment:shipments(*,rate:rates(*))`;
    } else if (collection === 'accounts') {
      //  contacts:contacts!accountsToContacts(id, name),
      // contracts:contracts!accounts_contractId_contracts_id_fk(id, name),
      select = `*,
        deals:deals(id, name, position, start, consultant, state, totalFee),
        sales:users(id, name, photo),
        baseTariffObj:tariffs!baseTariff(id, name),
        customerTariffObj:tariffs!customerTariff(id, name),
        supplierTariffObj:tariffs!supplierTariff(id, name),
        contracts:contracts!contracts_accountId_accounts_id_fk(*),
        contacts:contacts(id, salutation, title, name, firstname, lastname, photo, position, addressWork, communicationWork, linkedin)`;
    } else if (collection === 'deals') {
      select = `*,
        contacts:contacts(id, salutation, title, name, photo, position, addressWork, communicationWork, linkedin),        
        customerObj:accounts(id, name, logo )`;
    } else if (collection === 'contacts' || collection === 'candidates') {
      select = `*,
          accounts:accounts(id, name, logo ),
          customer:accounts(id, name, logo, address ),
          deals:deals(id, name, position, start, consultant, state, totalFee)          
        `;
    } else if (collection === 'invoices') {
      select = `*,
          customerObj:accounts(id, name, logo, address, logo),
          recipientObj:contacts(id, name, photo ),
          ordersRefs:orders(*, shipment:shipments(*,rate:rates(*))),
          paymentsRefs:payments(*)
        `;
    } else if (collection === 'issues') {
      select = `*,
        account:accounts(id, name, logo),        
        responsible:users!issues_responsibleId_users_id_fk(id, name, photo),
        reporter:users!issues_reporterId_users_id_fk(id, name, photo),
        invoice:invoices(id, name),
        order:orders(id, name),
        comments:comments(*)          
        `;
    } else if (collection === 'contracts') {
      select = `*,
        account:accounts!contracts_accountId_accounts_id_fk(id, name, logo, officialName, fiscalCode),
        contact:contacts(id, name),
        baseTariffObj:tariffs!contracts_baseTariffId_tariffs_id_fk(*),
        customerTariffObj:tariffs!contracts_customerTariffId_tariffs_id_fk(*),
        sales:users(id, name, photo)
        `;
    }
  }
  const { data, error } = await supabase(get(authStore)?.token)
    .from(collection === 'candidates' ? 'contacts' : collection)
    .select(select)
    .eq('id', id);
  if (error) {
    throw Error(error.message);
  } else {
    return data?.[0];
  }
};

export const getAvatar = async (module, id) => {
  if (module && id) {
    let collection = module === 'candidates' ? 'contacts' : module;
    let selection = `id,name,${collection === 'contacts' || collection === 'users' ? 'photo' : 'logo'}`;
    if (module === 'deals') {
      selection = 'id,name,customerObj:accounts(id, name, logo)';
    }
    const { data, error } = await supabase(get(authStore)?.token)
      .from(collection)
      .select(selection)
      .eq('id', id);
    if (error) {
      throw Error(error.message);
    } else {
      return data[0];
    }
  }
};

export const createFilters = (
  filters: FilterType[],
  rpcCall: boolean = false,
) => {
  /*const orgId = get(authStore)?.clerk.session.lastActiveOrganizationId;
  [
    ...,
    { field: 'orgId', operator: 'eq', value: orgId, active: !rpcCall },
  ]
  */
  const result = [];

  filters
    .filter(el => el?.active)
    .forEach(filter => {
      let value;
      const valueStr = Array.isArray(filter.value)
        ? filter.value.map(item =>
            rpcCall && typeof item?.key === 'string' && filter.operator !== 'ov'
              ? `'${item?.key}'${isValidUUID(item?.key) ? '::uuid' : ''}`
              : `${item.key ? item.key : item}`,
          )
        : filter.value;

      if (valueStr.length === 0) {
        return;
      }
      if (filter.operator === 'in') {
        value = `(${valueStr.join(',')})`;
      } else if (filter.operator === 'ov') {
        // value = filter.value.map(item => item.field);
        value = `{${valueStr.join(',')}}`;
      } else if (['cs', 'cd'].indexOf(filter.operator) >= 0) {
        value = `{${valueStr.join(',')}}`;
      } else {
        value = filter.value?.key ? filter.value.key : filter.value;
      }
      result.push({ field: filter.field, operator: filter.operator, value });
    });
  return result;
};

const getSearchString = (search: string) => {
  return (
    search
      .split(' ')
      .filter(e => e && e !== ' ' && e !== '')
      .join(':* & ') + ':*'
  );
};

export const globalSearch = async (search: string, signal: any) => {
  const textSearch = getSearchString(search);

  const { data, error } = await supabase(get(authStore)?.token)
    .from('search')
    .select('id,name,module')
    .textSearch('suggest', textSearch)
    .range(0, 24)
    .abortSignal(signal);
  if (error) {
    if (error.message === 'AbortError: signal is aborted without reason') {
      // Handle abort, possibly by doing nothing or updating the UI state
      console.log('Fetch aborted');
      return null;
    } else {
      throw Error(error.message);
    }
  } else {
    return data;
  }
};

export const getTodos = async (
  filter: {
    search?: string;
    consultantId?: string;
    flagged?: boolean;
    done?: boolean;
    priority?: (typeof PriorityType)[number];
  },
  from = 0,
  to = 10,
) => {
  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select('*', { count: 'estimated' })
    .eq('type', ActivityType.enum.Todo);

  const now = new Date().toISOString();

  if (!filter || filter.done === undefined) {
    query.or(`and(done.eq.true,dueDate.gt.${now}),done.eq.false`);
  } else if (filter.done) {
    query.eq('done', true);
  }

  if (filter.search) {
    query.ilike('name', `%${filter.search}%`);
  }

  if (filter.consultantId) {
    query.eq('consultant', filter.consultantId);
  }

  if (filter.flagged) {
    query.eq('flagged', true);
  }

  if (filter.priority) {
    query.eq('priority', filter.priority);
  }

  query = query
    .order('done', { ascending: true })
    .order('modifiedAt', { ascending: false });

  const result = await query.range(from, to);

  if (result.error) {
    throw Error(result.error.message);
  } else {
    return result;
  }
};

export const getItems = async (
  {
    collection,
    from,
    to,
    search,
    select,
    sort,
    filters,
    fulltextsearch,
  }: QueryStore,
  signal = undefined,
) => {
  let collectionName = collection;
  let type = '';

  if (collection.startsWith('generic-')) {
    collectionName = 'generic';
    type = collection.substring('generic-'.length);
  }

  let query = supabase(get(authStore)?.token)
    .from(collectionName)
    .select(select ?? '*', { count: 'estimated' });

  if (type) {
    query.eq('type', type);
  }

  if (search) {
    /*query.textSearch('name', `'${search}':*`, {
        config: 'english', // You can adjust the text search configuration
        type: 'websearch', // Use 'websearch' to mimic web search behavior
      });*/
    /*
      query.textSearch('name', search, {
        type: 'websearch',
        config: 'english',
      }); */
    if (fulltextsearch) {
      const searchTerm = getSearchString(search);
      console.log(searchTerm);
      query.textSearch('fts', searchTerm);
    } else {
      query.ilike('name', `%${search}%`);
    }
  }

  if (filters) {
    const supabaseFilters = createFilters(filters);
    supabaseFilters.forEach(filter => {
      query.filter(filter.field, filter.operator, filter.value);
    });
  }

  if (sort?.column) {
    query.order(sort.column, { ascending: sort.order === 'asc' });
  } else {
    query.order('modifiedAt', { ascending: false });
    query.order('createdAt', { ascending: false });
  }

  const result = await query.range(from, to).abortSignal(signal);

  console.log(result);
  if (result.error) {
    throw Error(result.error.message);
  } else {
    const page = from === 0 ? 0 : (from - 1) / (to + 1 - from);
    return {
      data: result.data,
      count: result.count,
      page,
    };
  }
};

export async function getLov(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('lovs')
    .select('*')
    .eq('name', name)
    .single();

  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function findActivities({
  module,
  id,
  type,
  search = '',
  page,
  itemsPerPage = 10,
}) {
  let query = supabase(get(authStore)?.token).rpc(
    'search_activities',
    {
      p_id: id,
      p_module: module,
      p_search: search,
      p_type: type,
    },
    { count: 'exact' },
  );

  if (type && type !== 'all') {
    // query.filter('type', 'eq', type);
  }

  if (module && id) {
    // query.filter(`${module}.id`, 'eq', id);
    // query.filter('accounts', 'cs', JSON.stringify([{ id }]));
    // query.containedBy(`refIds`, ['5e85a5b2e96ab1001faab415']);
  }

  const limit = itemsPerPage || 10;
  // query.order('startDate', { ascending: false });
  query.range((page - 1) * limit, page * limit);
  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    return { data, count, page };
  }
}

export async function findAccountsByLinkedInLink(linkedin: string): Account[] {
  let response = await supabase(get(authStore)?.token)
    .from('accounts')
    .select()
    .eq('linkedin', linkedin);
  const { data, error } = response;

  if (error) {
    throw Error(error.toString());
  } else {
    return data;
  }
}

export async function findContactsByLinkedInLink(linkedin: string): Contact[] {
  let response = await supabase(get(authStore)?.token)
    .from('contacts')
    .select()
    .eq('linkedin', linkedin);
  const { data, error } = response;

  if (error) {
    throw Error(error);
  } else {
    return data;
  }
}

export async function executeQuery<T>(
  collection: string,
  from = 0,
  to = 100,
): Promise<T[] | any> {
  return await supabase(get(authStore)?.token)
    .from(collection)
    .select('*', { count: 'estimated' })
    .order('createdAt', { ascending: false })
    .range(from, to);
}

export function queryCollection({
  collection,
  from = 0,
  to = 50,
  filter,
}: {
  collection: string;
  from: number;
  to: number;
  filter?: string;
}) {
  const { set, update, subscribe } = writable<QueryStore>({
    items: [],
    count: 0,
    from,
    to,
    collection,
    filter,
    status: 'preparing',
  });

  let subscribers = 0;

  const getItems = async (collection, from, to) => {
    const result = await supabase(get(authStore)?.token)
      .from(collection)
      .select('*', { count: 'estimated' })
      .range(from, to);
    set({ items: result.data, collection, status: 'ready' });
  };

  getItems(collection, from, to);

  const subscription = supabase(get(authStore)?.token)
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: collection },
      () => getItems(collection, from, to),
    )
    .subscribe();

  return {
    subscribe(run, invalidate) {
      const unsubscribe = subscribe(run, invalidate);
      subscribers++;

      return () => {
        unsubscribe();
        subscribers--;
        if (subscribers === 0) {
          supabase(get(authStore)?.token).removeChannel(subscription);
        }
      };
    },
  };
}

export async function getActivity(id) {
  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select(
      `
  *,    
  dealObj:deals(id, name),    
  accountObj:accounts(id, name, logo, linkedin),    
  candidateObj:contacts(id, name, photo, role)
`,
      { count: 'exact' },
    );

  query.eq('id', id);

  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    const result = data.length > 0 ? data[0] : {};

    // FIX data migration: go over properties: contacts, accounts, deals array inside the result, and remove empty elemnts with no properties
    ['contacts', 'accounts', 'deals', 'candidates'].forEach(prop => {
      if (result[prop] && Array.isArray(result[prop])) {
        result[prop] = result[prop].filter(item => {
          return item && Object.keys(item).length > 1;
        });
      }

      if ((prop === 'contacts' || prop === 'candidates') && result[prop]) {
        result[prop] = result[prop].map(item => {
          if (item.role) {
            return item;
          }
          return {
            ...item,
            role:
              prop === 'contacts'
                ? ContactRoleType.enum.Contact
                : ContactRoleType.enum.Candidate,
          };
        });
      }
    });

    // get all deals and getItem(id) for each deal and set the customer
    if (result.deals) {
      result.deals = await Promise.all(
        result.deals.map(async deal => {
          if (deal.id) {
            const dealObj = await getItemById('deals', deal.id);
            return { ...deal, customer: dealObj.customer };
          } else {
            return deal;
          }
        }),
      );
    }

    return result;
  }
}

export async function findAssignments({
  id,
  module = 'deals',
  search = '',
  page,
  itemsPerPage = 10,
  filters = [],
}) {
  let query = supabase(get(authStore)?.token)
    .from('activities')
    .select(
      `
  *,    
  dealObj:deals${module === 'deals' ? '!inner' : ''}(id, name),    
  accountObj:accounts${module === 'accounts' ? '!inner' : ''}(id, name, logo, linkedin),    
  candidateObj:contacts${module === 'contacts' ? '!inner' : ''}(id, name, photo)
`,
      { count: 'exact' },
    );

  query.filter('type', 'eq', ActivityType.enum.Assignment);
  if (module === 'deals') {
    query.filter('dealObj.id', 'eq', id);
  } else if (module === 'contacts') {
    query.filter('candidateObj.id', 'eq', id);
  } else if (module === 'accounts') {
    query.filter('accountObj.id', 'eq', id);
  }

  if (filters) {
    const supabaseFilters = createFilters(filters, true);
    supabaseFilters.forEach(filter => {
      query.filter(filter.field, filter.operator, filter.value);
    });
  }

  const limit = itemsPerPage || 10;
  query.order('startDate', { ascending: false });
  query.range((page - 1) * limit, page * limit);
  const { data, error, count } = await query;
  if (error) {
    throw Error(error.message);
  } else {
    return { data, count, page };
  }
}

export async function findMessageByMessageId(messageId: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('activities')
    .select()
    .eq('messageId', messageId);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function findConsultantByName(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('users')
    .select()
    .eq('name', name);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data;
}

export async function findLiContactByName(name: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('contacts')
    .select()
    .neq('linkedin', null)
    .eq('name', name);

  if (error) {
    console.log('error', error);
    throw new Error(error.message);
  }
  return data;
}
