<script>
  import { onDestroy, onMount } from 'svelte';
  import { authStore } from '$lib/auth-store';
  import { dark, light } from '@clerk/themes';

  let userButtonComponent;
  let isLightTheme = true;

  onMount(async () => {
    $authStore?.clerk?.mountUserButton(userButtonComponent, {
      appearance: {
        baseTheme: isLightTheme ? light : dark,
      },
    });
  });

  onDestroy(() => {
    if (window.clerk) {
      clerk?.unmountUserButton(userButtonComponent);
    }
  });
</script>

<div id="org-id" class="hidden text-yellow-50 bg-black">
  {$authStore?.clerk?.session?.lastActiveOrganizationId}
</div>
<div class="flex items-center space-x-2">
  <div id="user-button" bind:this={userButtonComponent} />
  <div class="text-base md:hidden text-muted-foreground">
    {$authStore?.clerk?.user?.firstName}
    {$authStore?.clerk?.user?.lastName}
  </div>
</div>

<style>
  :global(#global-nav) {
    z-index: 10;
  }
</style>
