import * as Icons from './icons';

import type { ComponentType } from 'svelte';
import type { Icon } from 'lucide-svelte';

export type Route = {
  title: string;
  label: string;
  icon: ComponentType<Icon>;
  module?: string;
  variant: 'default' | 'ghost';

  children?: Route[];
};

export const primaryRoutes: Route[] = [
  {
    title: 'Dashboard',
    label: '',
    icon: Icons.LayoutDashboard,
    variant: 'ghost',
    module: 'dashboard',
  },

  /*
  {
    title: 'Accounts',
    label: '',
    icon: Icons.Building,
    variant: 'ghost',
    module: 'accounts',
  },
  {
    title: 'Contacts',
    label: '',
    icon: Icons.Users,
    variant: 'default',
    module: 'contacts',
  },
  {
    title: 'Deals',
    label: '',
    icon: Icons.Rocket,
    variant: 'default',
    module: 'deals',
  },
  {
    title: 'Orders',
    label: '',
    icon: Icons.Package,
    variant: 'default',
    module: 'orders',
  },
  {
    title: 'Leads',
    label: '',
    icon: Icons.ShoppingCart,
    variant: 'default',
    module: 'leads',
  },
  */
  {
    title: 'CRM',
    label: '',
    icon: Icons.Section,
    variant: 'default',
    module: 'crm',
    children: [
      {
        title: 'Accounts',
        label: '',
        icon: Icons.Building,
        variant: 'ghost',
        module: 'accounts',
      },
      {
        title: 'Contacts',
        label: '',
        icon: Icons.Users,
        variant: 'default',
        module: 'contacts',
      },

      {
        title: 'Orders',
        label: '',
        icon: Icons.Package,
        variant: 'default',
        module: 'orders',
      },
      {
        title: 'Leads',
        label: '',
        icon: Icons.ShoppingCart,
        variant: 'default',
        module: 'leads',
      },
      {
        title: 'Issues',
        label: '',
        icon: Icons.Scale,
        variant: 'default',
        module: 'issues',
      },
    ],
  },
  {
    title: 'Finance',
    label: '',
    icon: Icons.Euro,
    variant: 'default',
    module: 'finance',
    children: [
      {
        title: 'Invoices',
        label: '',
        icon: Icons.ReceiptEuro,
        variant: 'default',
        module: 'invoices',
      },
      {
        title: 'Contracts',
        label: '',
        icon: Icons.Handshake,
        variant: 'default',
        module: 'contracts',
      },
      {
        module: 'tariffs',
        title: 'Tariffs',
        icon: Icons.ArchiveX,
        label: 'Tarif',
        variant: 'default',
      },
      {
        title: 'Payments',
        label: '',
        icon: Icons.AlertCircle,
        variant: 'default',
        module: 'payments',
      },
      {
        title: 'Expenses',
        label: '',
        icon: Icons.AlertCircle,
        variant: 'default',
        module: 'expenses',
      },
      {
        title: 'Balance',
        label: '',
        icon: Icons.AlertCircle,
        variant: 'default',
        module: 'balance',
      },
    ],
  },

  {
    title: 'Deals',
    label: '',
    icon: Icons.Rocket,
    variant: 'default',
    module: 'deals',
  },
  {
    title: 'Settings',
    label: '',
    icon: Icons.Settings,
    variant: 'default',
    module: 'settings',
  },

  {
    title: 'Timelogs',
    label: '',
    icon: Icons.Clock,
    variant: 'default',
    module: 'timelogs',
  },

  {
    title: 'Analytics',
    label: '',
    icon: Icons.ChartNoAxesCombined,
    variant: 'default',
    module: 'analytics',
    children: [
      {
        title: 'Revenue',
        label: '',
        icon: Icons.DollarSign,
        variant: 'ghost',
        module: 'revenue',
      },
      {
        title: 'Costs',
        label: '',
        icon: Icons.DollarSign,
        variant: 'ghost',
        module: 'costs',
      },
      {
        title: 'Profit',
        label: '',
        icon: Icons.DollarSign,
        variant: 'ghost',
        module: 'profit',
      },
      {
        title: 'Cashflow',
        label: '',
        icon: Icons.DollarSign,
        variant: 'ghost',
        module: 'cashflow',
      },
      {
        title: 'Sales Report',
        label: '',
        icon: Icons.DollarSign,
        variant: 'ghost',
        module: 'sales-report',
      },
    ],
  },

  /*
  {
    title: 'Activities',
    label: '',
    icon: Icons.Send,
    variant: 'ghost',
    module: 'activities',
  },

  {
    title: 'Trash',
    label: '',
    icon: Icons.Trash2,
    variant: 'ghost',
    module: 'trash',
  },
  {
    title: 'Archive',
    label: '',
    icon: Icons.Archive,
    variant: 'ghost',
    module: 'archive',
  },
  {
    title: 'Pipelines',
    label: '',
    icon: Icons.Columns3,
    variant: 'ghost',
    module: 'pipelines',
  },*/
];

export const secondaryRoutes: Route[] = [
  {
    title: 'Social',
    label: '972',
    icon: Icons.Users,
    variant: 'ghost',
  },
  {
    title: 'Updates',
    label: '342',
    icon: Icons.AlertCircle,
    variant: 'ghost',
  },
  {
    title: 'Forums',
    label: '128',
    icon: Icons.MessagesSquare,
    variant: 'ghost',
  },
  {
    title: 'Shopping',
    label: '8',
    icon: Icons.ShoppingCart,
    variant: 'ghost',
  },
  {
    title: 'Promotions',
    label: '21',
    icon: Icons.Archive,
    variant: 'ghost',
  },
];

const system = [
  { label: 'Created At', value: 'createdAt', sortable: true, type: 'string' },
  {
    label: 'Modified At',
    value: 'modifiedAt',
    sortable: true,
    type: 'string',
  },
];

export const fields = {
  tariffs: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
  ],
  accounts: [
    { label: 'ID', value: 'id', sortable: false, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    {
      label: 'Description',
      value: 'description',
      sortable: false,
      type: 'string',
    },
    { label: 'Logo', value: 'logo', sortable: false, type: 'string' },
    { label: 'LinkedIn', value: 'linkedin', sortable: false, type: 'string' },
    { label: 'URL', value: 'url', sortable: false, type: 'string' },
    { label: 'Phone', value: 'phone', sortable: false, type: 'string' },
    {
      label: 'Website URL',
      value: 'websiteUrl',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Account Status',
      value: 'accountStatus',
      sortable: true,
      type: 'string',
    },
    { label: 'Industry', value: 'industry', sortable: true, type: 'string' },
    { label: 'Employees', value: 'employees', sortable: true, type: 'string' },
    { label: 'Revenue', value: 'revenue', sortable: true, type: 'string' },
    { label: 'Address', value: 'address', sortable: false, type: 'string' },
    {
      label: 'Communication',
      value: 'communication',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Company Profile',
      value: 'companyProfile',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Philosophy',
      value: 'philosophy',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Universal Name',
      value: 'universalName',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Employee Count',
      value: 'employeeCount',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Employee Count Range',
      value: 'employeeCountRange',
      sortable: true,
      type: 'string',
    },
    {
      label: 'Official Name',
      value: 'officialName',
      sortable: true,
      type: 'string',
    },
    { label: 'Tagline', value: 'tagline', sortable: false, type: 'string' },
    { label: 'Founded On', value: 'foundedOn', sortable: true, type: 'string' },
    {
      label: 'Specialities',
      value: 'specialities',
      sortable: false,
      type: 'string',
    },
    {
      label: 'Crunchbase Funding Data',
      value: 'crunchbaseFundingData',
      sortable: false,
      type: 'string',
    },
    ...system,
  ],
  leads: [
    ...system,
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
  ],

  invoices: [
    ...system,
    { label: 'ID', value: 'invoiceId', sortable: true, type: 'string' },
    { label: 'Name', value: 'name', sortable: true, type: 'string' },
    { label: 'Status', value: 'status', sortable: true, type: 'string' },
    {
      label: 'Invoice date',
      value: 'invoiceDate',
      sortable: true,
      type: 'date',
    },
    {
      label: 'Fixed on',
      value: 'fixedOn',
      sortable: true,
      type: 'date',
    },
    { label: 'Amount', value: 'amount', sortable: true, type: 'string' },
    { label: 'Subject', value: 'subject', sortable: true, type: 'string' },
    { label: 'Due Date', value: 'dueDate', sortable: true, type: 'date' },
    { label: 'Debit', value: 'debit', sortable: true, type: 'string' },
    { label: 'Gross', value: 'gross', sortable: true, type: 'string' },
    { label: 'Net', value: 'Net', sortable: true, type: 'string' },
  ],
};

export const weights_TNT_DOX = [0.25, 0.5, 1, 1.5, 2, 2.5];
export const weights_TNT_XPS = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 10, 11,
];
export const weights_TNT_ECO = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 500, 510, 520,
];
export const zones_TNT = [0, 1, 2, 3, 4, 5, 6];
export const weights_FEDEX_ENV = [0.25];
export const weights_FEDEX_PAK = [
  0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 10, 11,
];
export const zones_FEDEX = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
];
