<script>
  import { formatDate } from '$lib/app/utils';
  import { MoreVertical, Clock, XCircle, CheckCircle } from 'lucide-svelte';
  export let payments = [];
  function getStatusStyle(status) {
    switch (status) {
      case 'Incomplete':
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200';
      case 'Cancelled':
        return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200';
      case 'Succeeded':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200';
      default:
        return 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200';
    }
  }
  function getStatusIcon(status) {
    switch (status) {
      case 'Incomplete':
        return Clock;
      case 'Cancelled':
        return XCircle;
      case 'Succeeded':
        return CheckCircle;
      default:
        return null;
    }
  }
  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };
</script>

<div class="p-6 rounded-lg shadow-sm bg-primary-foreground dark:bg-gray-800">
  <h2 class="mb-6 text-2xl font-bold text-primary dark:text-primary-dark">
    Payments
  </h2>
  <table class="w-full">
    <thead>
      <tr class="border-b border-gray-200 dark:border-gray-700">
        <th
          class="pb-2 font-semibold text-left text-primary dark:text-primary-dark"
          >Amount</th
        >
        <th
          class="pb-2 font-semibold text-left text-primary dark:text-primary-dark"
          >Description</th
        >
        <th
          class="pb-2 font-semibold text-right text-primary dark:text-primary-dark"
          >Date</th
        >
        <th class="w-8"></th>
      </tr>
    </thead>
    <tbody>
      {#each payments as payment}
        <tr class="border-b border-gray-100 dark:border-gray-700">
          <td class="py-4">
            <div class="flex items-center">
              <div>
                <span class="font-semibold text-primary">
                  {formatCurrency(payment.amount, payment.currency)}
                </span>
                {#if payment.currency !== 'EUR' && payment.amountEur !== undefined}
                  <div class="font-semibold text-muted-foreground">
                    {formatCurrency(payment.amountEur, 'EUR')}
                  </div>
                {/if}
              </div>
              <span
                class="ml-2 px-2 py-1 text-xs rounded-full flex items-center {getStatusStyle(
                  payment.status,
                )}"
              >
                <svelte:component
                  this={getStatusIcon(payment.status)}
                  size={12}
                  class="mr-1"
                />
                {payment.status}
              </span>
            </div>
          </td>
          <td class="py-4 text-primary dark:text-primary-dark"
            >{payment.name}</td
          >
          <td class="py-4 text-right text-primary dark:text-primary-dark">
            {formatDate(payment.paymentSucceededAt ?? payment.paymentStartedAt)}
          </td>
          <td class="py-4 text-right">
            <button
              class="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
            >
              <MoreVertical size={20} />
            </button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
  <p class="hidden mt-4 text-sm text-primary/60 dark:text-primary-dark/60">
    {payments.length} results
  </p>
</div>

<style>
  :global(body) {
    --color-primary: theme('colors.gray.800');
    --color-primary-dark: theme('colors.gray.200');
  }
  :global(body.dark) {
    --color-primary: theme('colors.gray.200');
    --color-primary-dark: theme('colors.gray.800');
  }
</style>
