<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import Activities from '$lib/pages/activities/activities.svelte';
  import ContactsWidget from '$lib/widgets/contacts/contacts-widget.svelte';
  import ContractsWidget from '$lib/widgets/contracts/contracts-widget.svelte';
  import PageWithTitle from '../../ui/page-with-title.svelte';
  import { type ActivityWithRefs, type AccountWithRefs } from '$db/schema';
  import DealsWidget from '$lib/widgets/deals-widget.svelte';
  import Files from '$lib/components/hanta/files/files.svelte';
  import AccountForm from '$lib/widgets/accounts/account-form.svelte';
  import AccountStatistics from './account-statistics.svelte';
  import AccountContract from './account-contract.svelte';
  import AccountCardShort from './account-card-short.svelte';
  import AccountCard from './account-card.svelte';
  import { appStore } from '$lib/app-store';
  import AccountOwnerships from './account-ownerships.svelte';
  import AccountDealsLeads from './account-deals-leads.svelte';
  type $$Props = { crmAccount: AccountWithRefs };

  export let crmAccount: AccountWithRefs;

  let activity: ActivityWithRefs;
  $: activity = {
    name: '',
    description: '',
    accounts: [crmAccount],
    deals: [],
    candidates: [],
    contacts: [],
  };
</script>

<PageWithTitle>
  <div slot="short-card" class="flex space-x-2">
    <AccountCardShort {crmAccount} {activity} />
  </div>

  <div slot="card" class="flex items-center space-x-8">
    <AccountCard {crmAccount} {activity} />
  </div>

  <div slot="content">
    <Tabs.Root
      value={$appStore.isMobile ? 'about' : 'overview'}
      class="flex flex-col mt-4"
    >
      <Tabs.List class="flex justify-start w-fit">
        {#if $appStore.isMobile}
          <Tabs.Trigger value="about">About</Tabs.Trigger>
        {/if}
        <Tabs.Trigger value="overview">Overview</Tabs.Trigger>
        <Tabs.Trigger value="timeline">Timeline</Tabs.Trigger>
        <Tabs.Trigger value="deals">Deals</Tabs.Trigger>
        <Tabs.Trigger value="financial">Financial</Tabs.Trigger>
        <Tabs.Trigger value="analysis">Analysis</Tabs.Trigger>
        <Tabs.Trigger value="ownerships">Ownerships</Tabs.Trigger>
      </Tabs.List>
      {#if $appStore.isMobile}
        <Tabs.Content value="about" class="p-2 text-left">
          <AccountForm {crmAccount} type="about" />
        </Tabs.Content>
      {/if}

      <Tabs.Content value="financial" class="overflow-scroll p-2 w-full h-full">
        <div class="grid grid-cols-2 gap-4">
          <AccountForm {crmAccount} type="financial" />
          <ContractsWidget
            contracts={crmAccount?.contracts || []}
            customerId={crmAccount?.id}
          />
        </div>
      </Tabs.Content>
      <Tabs.Content value="analysis" class="overflow-scroll p-2 w-full h-full">
        <AccountForm {crmAccount} type="analysis" />
      </Tabs.Content>

      <Tabs.Content value="overview" class="overflow-scroll p-2 w-full h-full">
        <AccountStatistics account={crmAccount} />
        {#key `${crmAccount?.id}-${crmAccount?.modifiedAt}`}
          <DealsWidget
            deals={crmAccount?.deals || []}
            customerId={crmAccount?.id}
          />
          <ContactsWidget
            contacts={crmAccount?.contacts || []}
            customerId={crmAccount?.id}
          />

          <div
            class="overflow-hidden p-4 px-4 py-5 my-8 rounded-lg shadow bg-primary-800 sm:p-6"
          >
            <Files folder={`accounts/${crmAccount?.id}`} compact={true} />
          </div>
        {/key}
      </Tabs.Content>

      <Tabs.Content value="timeline" class="overflow-scroll p-2 w-full h-full">
        <Activities id={crmAccount?.id} module={'accounts'} type="all" />
      </Tabs.Content>
      <Tabs.Content value="deals" class="overflow-scroll p-2 w-full h-full">
        <AccountDealsLeads accountId={crmAccount?.id} />
      </Tabs.Content>
      <Tabs.Content value="contracts" class="overflow-scroll p-2 w-full h-full"
      ></Tabs.Content>
      <Tabs.Content
        value="ownerships"
        class="overflow-scroll p-2 w-full h-full"
      >
        <AccountOwnerships accountId={crmAccount?.id} />

        <AccountContract {crmAccount} />
      </Tabs.Content>
    </Tabs.Root>
  </div>

  <div slot="sidebar">
    {#if !$appStore.isMobile}
      <AccountForm bind:crmAccount />
    {/if}
  </div>
</PageWithTitle>
