<script lang="ts">
  export let rate;
</script>

{#if rate}
  <div class="p-6 my-4 rounded-lg shadow-md bg-primary-foreground">
    <h3 class="mb-4 text-xl font-semibold text-gray-800">Rate Details</h3>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Total EUR:</strong>
        <span class="text-gray-800">{rate.totalEUR} €</span>
      </div>
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Total National:</strong>
        <span class="text-gray-800">{rate.totalNational} MDL</span>
      </div>
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Customer Price:</strong>
        <span class="text-gray-800">{rate.customerPrice} €</span>
      </div>
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Revenue EUR:</strong>
        <span class="text-gray-800">{rate.revenueEur} €</span>
      </div>
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Supplier EUR:</strong>
        <span class="text-gray-800">{rate.supplierEur} €</span>
      </div>
      <div class="flex items-center">
        <strong class="w-40 text-gray-600">Exchange Rate:</strong>
        <span class="text-gray-800">{rate.exchangeRate}</span>
      </div>
    </div>

    <h4 class="mt-6 mb-2 text-lg font-semibold text-gray-800">Services</h4>
    {#each rate?.services || [] as service}
      <div
        class="p-4 my-2 rounded-lg border transition-all duration-300 hover:shadow-md hover:bg-primary-foreground/10"
      >
        <h5 class="mb-2 text-xl font-semibold text-gray-700 text-md">
          {service.product}
        </h5>
        <div class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
          <div class="flex items-center">
            <strong class="w-32 text-gray-600">Price:</strong>
            <span class="text-gray-800"
              >{service.price} {service.dealCurrency}</span
            >
          </div>
          <div class="flex items-center">
            <strong class="w-32 text-gray-600">Weight:</strong>
            <span class="text-gray-800">{service.weight} kg</span>
          </div>
          <div class="flex items-center">
            <strong class="w-32 text-gray-600">Operator:</strong>
            <span class="text-gray-800">{service.operator}</span>
          </div>
          <div class="flex items-center">
            <strong class="w-32 text-gray-600">Revenue:</strong>
            <span class="text-gray-800"
              >{service.revenueEur} € ({service.revenuePercents * 100}%)</span
            >
          </div>
          <div class="flex items-center">
            <strong class="w-32 text-gray-600">Supplier:</strong>
            <span class="text-gray-800"
              >{service.supplierEur} € ({service.supplierPercents * 100}%)</span
            >
          </div>
          {#if service.week}
            <div class="flex items-center">
              <strong class="w-32 text-gray-600">Week:</strong>
              <span class="text-gray-800">{service.week}</span>
            </div>
          {/if}
        </div>
      </div>
    {/each}
  </div>
{/if}
